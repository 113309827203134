import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory, withRouter} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {downloadAPI, getAPI} from "../APIUtil";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AppNav from "./AppNav";
import {Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCaretDown,
  faCaretSquareDown,
  faCaretUp,
  faFileDownload,
  faHome,
  faSearch,
  faSortDown,
  faSortUp
} from "@fortawesome/free-solid-svg-icons";
import {
  addField,
  checkKey,
  dateformat,
  formatZip,
  getDownloadFileName,
  linkFormatter,
  nvl,
  onSearchPref,
  useDebounce
} from "../CMUtil";
import MdDatatable, {InitialLoading, sortCaret} from "../MdDataTable";
import AsyncSelect from "react-select/async";

export const ConstList = () => {

  const history = useHistory();
  const iniSearch = useRef(true);

  const [loadingCss, setLoadingCss] = useState("loading_panel_init");

  const [loading, setLoading] = useState(true);
  const [locPage, setLocPage] = useState(null);
  const [locSizePerPage, setLocSizePerPage] = useState(100);
  const [locTotalSize, setLocTotalSize] = useState(null);
  const [locSortField, setLocSortField] = useState(null);
  const [locSortOrder, setLocSortOrder] = useState(null);

  const [name, setName] = useState("");
  const debouncedName = useDebounce(name, 500, setLocPage);
  const [zip, setZip] = useState("");
  const debouncedZip = useDebounce(zip, 500, setLocPage);
  const [pref, setPref] = useState("");
  const debouncedPref = useDebounce(pref, 500, setLocPage);
  const [city, setCity] = useState("");
  const debouncedCity = useDebounce(city, 500, setLocPage);
  const [address, setAddress] = useState("");
  const debouncedAddress = useDebounce(address, 500, setLocPage);
  const [hist, setHist] = useState("");
  const debouncedHist = useDebounce(hist, 500, setLocPage);
  const [note, setNote] = useState("");
  const debouncedNote = useDebounce(note, 500, setLocPage);

  const [listData, setListData] = useState([]);

  const [prefSelected, setPrefSelected] = useState([]);

  // 項目定義
  const columns = [
    {
      dataField: 'const_id',
      text: 'ID',
      hidden: true,
      sort: false,
      headerStyle: {width: '80px'}
    },
    {
      dataField: 'name',
      text: '施工業者',
      classes: 'tbl-col link',
      sort: true,
      headerStyle: {width: '200px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <div><a onClick={() => onClickLinkToDetail(row.const_id)}>{cell}</a></div>
      )
    },
    {
      dataField: 'zip',
      text: '住所',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '300px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{nvl(row.zip) + " " + nvl(row.pref) + nvl(row.city) + nvl(row.address)}</span>
      )
    },
    {
      dataField: 'tel',
      text: '電話',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '70px'},
      align: 'center',
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'fax',
      text: 'FAX',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '70px'},
      align: 'center',
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
  ];

  // 初期化
  useEffect(() => {
    const init = async () => {
      let const_search_condition = localStorage.getItem("const_search_condition");
      if (const_search_condition) {
        let s = JSON.parse(const_search_condition);
        s.page ? setLocPage(s.page) : setLocPage(1);
        s.size_per_page ? setLocSizePerPage(s.size_per_page) : null;
        s.sort_field ? setLocSortField(s.sort_field) : null;
        s.sort_order ? setLocSortOrder(s.sort_order) : null;
        s.name ? setName(s.name) : null;
        s.zip ? setZip(s.zip) : null;
        s.pref ? setPref(s.pref) : null;
        s.pref ? setPrefSelected({name: s.pref, id: s.pref}) : null;
        s.city ? setCity(s.city) : null;
        s.address ? setAddress(s.address) : null;
        s.note1 ? setNote(s.note) : null;
      } else {
        setLocPage(1);
      }
    }
    init();
  }, []);

  // 検索処理
  useEffect(() => {
    if (iniSearch.current) {
      iniSearch.current = false;
      return;
    }
    const update = async () => {

      setLoading(true);
      let condition = {};
      addField(condition, "page", locPage);
      addField(condition, "size_per_page", locSizePerPage);
      addField(condition, "sort_field", locSortField);
      addField(condition, "sort_order", locSortOrder);
      addField(condition, "name", name);
      addField(condition, "zip", zip);
      addField(condition, "pref", pref);
      addField(condition, "city", city);
      addField(condition, "address", address);
      addField(condition, "note", note);

      localStorage.setItem("const_search_condition", JSON.stringify(condition));
      const res = await getAPI("ms_const_search_view", condition);
      setListData(res);
      setLoading(false);
      setLoadingCss("loading_panel_fadeout");
    }
    update();
  }, [
    locPage,
    locSizePerPage,
    locSortField,
    locSortOrder,
    debouncedName,
    debouncedZip,
    debouncedPref,
    debouncedCity,
    debouncedAddress,
    debouncedHist,
    debouncedNote
  ]);

  const onTableChange = (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
    if ("pagination" === type) {
      setLocPage(page);
    }
    if ("sort" === type) {
      setLocSortField(sortField);
      setLocSortOrder(sortOrder);
    }
  }

  const handleChangePref = (value) => {
    setPrefSelected(value ? value : "");
    setPrefSearch(value ? value.id : "");
  }

  const onClickLinkToDetail = (const_id) => {
    history.push("/const/" + const_id);
  }

  const onClickDownload = async () => {
    let searchCondition = {
      page: locPage,
      size_per_page: locSizePerPage,
      sort_field: locSortField,
      sort_order: locSortOrder,
      name: name,
      zip: zip,
      pref: pref,
      city: city,
      address: address,
      hist: hist,
      note: note
    }

    await downloadAPI("download/const_list", searchCondition);
  }

  const onClickClear = () => {
    setNameSearch("");
    setZipSearch("");
    setPrefSearch("");
    setPrefSelected(null);
    setCitySearch("");
    setAddressSearch("");
    setNoteSearch("");
    localStorage.removeItem("const_search_condition");
  }

  const setNameSearch = (value) => {
    setLocPage(1);
    setName(value);
  }
  const setZipSearch = (value) => {
    setLocPage(1);
    setZip(value);
  }
  const setPrefSearch = (value) => {
    setLocPage(1);
    setPref(value);
  }
  const setCitySearch = (value) => {
    setLocPage(1);
    setCity(value);
  }
  const setAddressSearch = (value) => {
    setLocPage(1);
    setAddress(value);
  }
  const setHistSearch = (value) => {
    setLocPage(1);
    setHist(value);
  }
  const setNoteSearch = (value) => {
    setLocPage(1);
    setNote(value);
  }

  return (
    <>
      <AppNav/>
      <Row className="h1">
        <Col sm={8}>
          <h1>
            施工業者
          </h1>
        </Col>
        <Col sm="4" className="text-right">
          <Button variant="secondary" onClick={onClickDownload}>
            <FontAwesomeIcon icon={faFileDownload} size="lg"/>
            施工業者
          </Button>
        </Col>
      </Row>

      <Card className="card-search">
        <Container className="container-main-search">
          <Form.Group as={Row}>
            <Form.Label column sm="1">施工業者</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={name} onChange={e => setNameSearch(e.target.value)}/>
            </Col>
            <Col sm="6"></Col>
            <Col sm="1">
              <Button variant="clear" onClick={e => onClickClear()}>条件クリア</Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">住所</Form.Label>
            <Col sm="2" className="input-group">
              <InputGroup.Text className="input-group-text-cm"
                               style={{fontWeight: "700", backgroundColor: "#ffffff"}}>〒</InputGroup.Text>
              <Form.Control id="zip" type="tel"
                            value={zip} style={{textAlign: "center"}}
                            onKeyPress={e => checkKey(e, /[0-9\-]/)}
                            onChange={e => setZipSearch(e.target.value)}
                            onBlur={e => formatZip(e.target.value, setZip)}
              />
            </Col>
            <Col sm="2">
              <AsyncSelect sm="2"
                           className="async-select"
                           cacheOptions
                           defaultOptions
                           isClearable
                           value={prefSelected}
                           getOptionLabel={e => e.name}
                           getOptionValue={e => e.id}
                           loadOptions={onSearchPref}
                           onChange={handleChangePref}
                           placeholder=""
              />
            </Col>
            <Col sm="6" className="input-group">
              <Form.Control type="text" value={city}
                            style={{borderRadius: "0"}}
                            placeholder="市区町村"
                            onChange={e => setCitySearch(e.target.value)}/>
              <Form.Control type="text" value={address}
                            style={{width: "20%", borderTopLeftRadius: "0", borderBottomLeftRadius: "0"}}
                            placeholder="住所"
                            onChange={e => setAddressSearch(e.target.value)}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">備考</Form.Label>
            <Col sm="10">
              <Form.Control type="text" value={note} onChange={e => setNoteSearch(e.target.value)}/>
            </Col>
          </Form.Group>
        </Container>
      </Card>

      <Container className="container-main-list">
        {/* *** 一覧 *** */}
        <InitialLoading loadingCss={loadingCss}/>
        <MdDatatable
          keyField="const_id"
          listData={listData}
          columns={columns}
          loading={loading}
          onTableChange={onTableChange}
          page={locPage}
          sizePerPage={locSizePerPage}
          totalSize={locTotalSize}
          sortField={locSortField}
          sortOrder={locSortOrder}
          showPagenation={true}
          linkToAdd="/const/"
        />
      </Container>
    </>
  );
}

export default withRouter(ConstList);
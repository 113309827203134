import React, {useContext, useEffect, useRef, useState} from 'react';
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import Button from "react-bootstrap/Button";
import {Col, Container, Form, Modal, Row} from "react-bootstrap";
import {
  dateformatDB,
  dateformatJPN,
  dateformatJPNByType,
  dateformatYYYYMMDD,
  lpad,
  parseDate,
  rpad,
  toNull
} from "../CMUtil";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"
import {useHistory} from "react-router-dom";
import {getAPI, postAPI} from "../APIUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleLeft, faChevronCircleRight, faPlusCircle} from "@fortawesome/free-solid-svg-icons";

export const Cal = ({match: {params: {p_month}}}) => {

  const history = useHistory();
  const iniMonth = useRef(true);
  const iniDisp = useRef(true);

  const [month, setMonth] = useState("");
  const [count, setCount] = useState(0);

  const [modalShow, setModalShow] = useState(false);
  const [modalOpnDate, setModalOpnDate] = useState(null);
  const [modalDispOpnDate, setDispModalOpnDate] = useState("");
  const [modalMemo, setModalMemo] = useState("");

  const [scheduleList, setScheduleList] = useState([]);

  const [dispSch1, setDispSch1] = useState(true);
  const [dispSch2, setDispSch2] = useState(true);
  const [dispCutOff, setDispCutOff] = useState(false);
  const [dispHist, setDispHist] = useState(false);
  const [dispMemo, setDispMemo] = useState(false);

  //INITIALIZE
  useEffect(() => {
    const update = async () => {

      let cal_search_condition = localStorage.getItem("cal_search_condition");
      if (cal_search_condition) {
        let s = JSON.parse(cal_search_condition);
        setDispSch1(s.disp_sch_1 ? s.disp_sch_1 : false);
        setDispSch2(s.disp_sch_2 ? s.disp_sch_2 : false);
        setDispHist(s.disp_hist ? s.disp_hist : false);
        setDispCutOff(s.disp_cut_off ? s.disp_cut_off : false);
      }
      let locMonth = null;
      if(p_month){
        let pYear = parseInt(p_month.substring(0,4));
        let pMonth = parseInt(p_month.substring(5,7));
        locMonth = new Date(pYear,  pMonth - 1,1);
      }else{
        locMonth = new Date();
      }
      setMonth(locMonth);
    }
    update();

  }, []);

  useEffect(() => {
    if (iniMonth.current) {
      iniMonth.current = false;
      return;
    }

    const update = async () => {


      let opnDateFrom = new Date(month);
      let opnDateTo = new Date(month.getFullYear(),month.getMonth()+1,0);

      let res = null;
      let searchCondition = null;
      let locScheduleList = [];

      searchCondition = {
        opn_date_from: dateformatDB(opnDateFrom),
        opn_date_to: dateformatDB(opnDateTo),
        sort_field: 'opn_date'
      }
      res = await getAPI("tr_cal_search_for_calendar_view", searchCondition);

      res.results.map((result) => {

        locScheduleList.push({
          title: result.book_name,
          start: result.opn_date,
          opn_id: result.opn_id,
          takei_amount: result.takei_amount,
          wire_mesh: result.wire_mesh,
          book_id: result.book_id,
          type: "schedule",
          classNames: classEvent(result),
          order: (result.opn_id === 'OPN002' || result.opn_id === 'OPN003') ? 10 : 15,
          eventType: "event",
        });
      });

      //メモ追加
      res = await getAPI("tr_cal_memo_search_view", searchCondition);
      res.results.map((result) => {
        locScheduleList.push({
          title: result.memo,
          start: result.opn_date,
          type: "memo",
          classNames: "cal-event-memo" + (dispMemo ? "" : " d-none"),
          memo: result.memo,
          order: 20,
          eventType: "memo",
        });
      });

      //請求書送付期日追加
      let invCutOffList = {};
      let monthEnd = new Date();
      monthEnd.setFullYear(month.getFullYear());
      monthEnd.setMonth(month.getMonth() + 1);
      monthEnd.setDate(0);
      res = await getAPI("ms_const_cut_off_search_for_calendar_view", searchCondition);
      res.results.map((result) => {

        let start = new Date();
        start.setFullYear(month.getFullYear());
        start.setMonth(month.getMonth());
        if (result.inv_cutoff_date === -1) {
          start.setMonth(start.getMonth() + 1);
          start.setDate(0);
        } else {
          start.setDate(result.inv_cutoff_date);
        }

        locScheduleList.push({
          title: result.name,
          start: dateformatDB(start),
          type: "cut-off",
          classNames: "cut-off" + (dispCutOff ? "" : " d-none"),
          memo: result.memo,
          order: 30,
          eventType: "cut-off"
        });
      });

      let cal_search_condition = {
        disp_sch_1: dispSch1,
        disp_sch_2: dispSch2,
        disp_hist: dispHist,
        disp_cut_off: dispCutOff
      }
      localStorage.setItem("cal_search_condition", JSON.stringify(cal_search_condition));
      setScheduleList(locScheduleList);
    }
    update();

  }, [month, count]);

  //イベントの表示／非表示切り替え
  useEffect(() => {

    if (iniDisp.current) {
      iniDisp.current = false;
      return;
    }

    const update = async () => {

      let list = null;

      list = $(".cal-event-schedule-1");
      for (let i = 0; i < list.length; i++) {
        if (dispSch1) {
          $(list[i]).removeClass("d-none")
        } else {
          $(list[i]).addClass("d-none")
        }
      }

      list = $(".cal-event-schedule-2");
      for (let i = 0; i < list.length; i++) {
        if (dispSch2) {
          $(list[i]).removeClass("d-none")
        } else {
          $(list[i]).addClass("d-none")
        }
      }

      list = $(".cut-off");
      for (let i = 0; i < list.length; i++) {
        if (dispCutOff) {
          $(list[i]).removeClass("d-none")
        } else {
          $(list[i]).addClass("d-none")
        }
      }

      if (dispHist) {
        list = $(".no-report-OPN002-non");
        for (let i = 0; i < list.length; i++) {
          $(list[i]).removeClass("no-report-OPN002-non");
          $(list[i]).addClass("no-report-OPN002");
        }
        list = $(".no-report-OPN003-non");
        for (let i = 0; i < list.length; i++) {
          $(list[i]).removeClass("no-report-OPN003-non");
          $(list[i]).addClass("no-report-OPN003");
        }
      } else {
        list = $(".no-report-OPN002");
        for (let i = 0; i < list.length; i++) {
          $(list[i]).removeClass("no-report-OPN002");
          $(list[i]).addClass("no-report-OPN002-non");
        }
        list = $(".no-report-OPN003");
        for (let i = 0; i < list.length; i++) {
          $(list[i]).removeClass("no-report-OPN003");
          $(list[i]).addClass("no-report-OPN003-non");
        }
      }

      list = $(".cal-event-memo");
      for (let i = 0; i < list.length; i++) {
        if (dispMemo) {
          $(list[i]).removeClass("d-none")
        } else {
          $(list[i]).addClass("d-none")
        }
      }

      let cal_search_condition = {
        disp_sch_1: dispSch1,
        disp_sch_2: dispSch2,
        disp_hist: dispHist,
        disp_cut_off: dispCutOff
      }
      localStorage.setItem("cal_search_condition", JSON.stringify(cal_search_condition));
    }
    update();

  }, [dispSch1, dispSch2, dispCutOff, dispHist, dispMemo]);

  const classEvent = (schedule) => {

    let cls = "";
    if (schedule.opn_id === "OPN002" || schedule.opn_id === 'OPN003') {
      cls = "cal-event-schedule-1" + (dispSch1 ? "" : " d-none");
    } else {
      cls = "cal-event-schedule-2" + (dispSch2 ? "" : " d-none");
    }

    if (schedule.opn_id === "OPN002" && !schedule.takei_amount && !schedule.skip_report) {
      cls = cls + (dispHist ? " no-report-OPN002" : " no-report-OPN002-non");
    }
    if (schedule.opn_id === "OPN003" && !schedule.wire_mesh && !schedule.skip_report) {
      cls = cls + (dispHist ? " no-report-OPN003" : " no-report-OPN003-non");
    }

    return cls;
  }

  const onClickUpdate = (dateInfo) => {
    let date = dateInfo.start;
    date.setDate(date.getDate() + 15);
    setMonth(date);
  }

  const handleDateClick = (arg) => {

    history.push("/schedule/" + dateformatYYYYMMDD(arg.date));

  }

  const handleEventClick = (eventClickInfo) => {

    if (dispHist) {
      history.push("/book/" + eventClickInfo.event.extendedProps.book_id + "/1");
    } else {
      history.push("/schedule/" + dateformatYYYYMMDD(eventClickInfo.event.start));
    }
  }

  const onClickModalClose = () => setModalShow(false);

  const onClickModalShow = () => setModalShow(true);

  const onClickModalSave = async () => {

    let obj = newData();
    obj = await postAPI("tr_cal_memo/", obj);
    success("登録完了");
    setCount(count + 1);
    setModalShow(false);

  }

  const newData = () => {
    return {
      cal_memo_id: null,
      cal_id: null,
      opn_date: dateformatDB(modalOpnDate),
      no: 0,
      title: null,
      memo: toNull(modalMemo),
      create_pg: 'Cal',
      update_pg: 'Cal'
    };
  }

  function renderEventContent(eventInfo) {

    if (eventInfo.event.extendedProps.eventType === "add-event") {
      return (
        <div style={{textAlign: "right", color: "#007bff"}}>
          <FontAwesomeIcon icon={faPlusCircle} size="lg" style={{marginBottom: "0.1rem"}}/>
          追加
        </div>
      )
    } else {
      return (
        <>
          <b>{eventInfo.timeText}</b>
          <i>{eventInfo.event.title}</i>
        </>
      )
    }
  }

  const onClickPrev = (e) => {
    let locMonth = new Date(month);
    locMonth.setMonth(locMonth.getMonth() - 1);
    window.location.href = "/cal/" + dateformatJPNByType(locMonth,"year") + "-" + lpad(dateformatJPNByType(locMonth,"month"),2,"0") + "/";
  }

  const onClickNext = (e) => {
    let locMonth = new Date(month);
    locMonth.setMonth(locMonth.getMonth() + 1);
    window.location.href = "/cal/" + dateformatJPNByType(locMonth,"year") + "-" + lpad(dateformatJPNByType(locMonth,"month"),2,"0") + "/";
  }

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <div className="h1">
          <h1 className="float-left align-items-end">
            カレンダー
          </h1>
          <div className="float-none" style={{height: "30px"}}>&nbsp;</div>
        </div>

        <Container className="container-main-detail">
          <Row>
            <Col className="text-right m-3">
              <Form.Check
                inline
                id="invCutoffDate1"
                type="checkbox"
                className="ml-3"
                label="作業"
                checked={dispSch1}
                onChange={e => {
                  setDispSch1(!dispSch1)
                }}/>
              <Form.Check
                inline
                id="invCutoffDate2"
                type="checkbox"
                className="ml-3"
                label="２次処理"
                checked={dispSch2}
                onChange={e => {
                  setDispSch2(!dispSch2)
                }}/>
              <Form.Check
                inline
                id="invCutoffDate3"
                type="checkbox"
                className="ml-3"
                label="請求"
                checked={dispCutOff}
                onChange={e => {
                  setDispCutOff(!dispCutOff)
                }}/>
              <Form.Check
                inline
                id="invCutoffDate4"
                type="checkbox"
                className="ml-3"
                label="報告"
                checked={dispHist}
                onChange={e => {
                  setDispHist(!dispHist)
                }}/>
            </Col>
          </Row>

          <Row>
            <Col className="text-right" sm={5}>
          <FontAwesomeIcon className="mt-3" icon={faChevronCircleLeft} size="lg" style={{cursor:"pointer"}} onClick={e => onClickPrev(e)}/>
            </Col>
            <Col className="text-center mt-1" sm={2} style={{fontSize:"2rem"}}>{dateformatJPNByType(month,"year")}年{dateformatJPNByType(month,"month")}月</Col>
            <Col className="text-left" sm={5}>
          <FontAwesomeIcon className="mt-3" icon={faChevronCircleRight} size="lg" style={{cursor:"pointer"}} onClick={e => onClickNext(e)}/>
            </Col>
          </Row>

          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            locale="ja"
            initialView="dayGridMonth"
            initialDate={p_month}
            datesSet={onClickUpdate}
            headerToolbar={
              {
                start: 'prev',
                center: 'title',
                end: 'next'
              }
            }
            events={scheduleList}
            dateClick={handleDateClick}
            eventContent={renderEventContent}
            eventClick={handleEventClick}
            eventOrder="order"
          />

        </Container>
      </div>

      <Modal show={modalShow} onHide={onClickModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalDispOpnDate}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control as="textarea" rows={3} value={modalMemo} onChange={e => setModalMemo(e.target.value)}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClickModalClose}>
            キャンセル
          </Button>
          <Button variant="primary" onClick={onClickModalSave}>
            登録
          </Button>
        </Modal.Footer>
      </Modal>

      <Toast/>
    </>
  );
}

export default withRouter(Cal);
import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Card, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {deleteAPI, getAPI, postAPI, putAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {nvl, toNull, toSingleByte} from "../CMUtil";

export const CodeDtl = ({match: {params: {p_id}}}) => {

  const hist = useHistory();

  const [updateType, setUpdateType] = useState();

  const [codeId, setCodeId] = useState("");
  const [codeName, setCodeName] = useState("");
  const [categId, setCategId] = useState("");
  const [categName, setCategName] = useState("");
  const [val1, setVal1] = useState("");
  const [val2, setVal2] = useState("");
  const [val3, setVal3] = useState("");

  //初期化
  useEffect(() => {

    const update = async () => {
      if (!p_id) {
        return;
      }
      setUpdateType("update");
      let obj = await getAPI("ms_code/" + p_id);
      setCodeId(nvl(obj.code_id));
      setCodeName(nvl(obj.code_name));
      setCategId(nvl(obj.categ_id));
      setCategName(nvl(obj.categ_name));
      setVal1(nvl(obj.val_1));
      setVal2(nvl(obj.val_2));
      setVal3(nvl(obj.val_3));
    }
    update();

  }, []);

  const onClickInsert = async () => {
    let obj = newData();
    obj = await postAPI("ms_code/", obj);
    setCodeId(obj.code_id);
    setUpdateType("update");
    hist.push("/code/" + obj.code_id);
    success("登録完了");
  }

  const onClickUpdate = async () => {
    let obj = newData();
    await putAPI("ms_code/" + codeId + "/", obj);
    success("更新完了");
  }

  const onClickDelete = async () => {
    setModalConfirmShow(true);
  }

  const onClickConfirm = async () => {
    let obj = newData();
    let res = await deleteAPI("ms_code/" + codeId + "/", obj);
    if (res) {
      hist.push("/code_list/");
      success("削除完了");
    }
  }

  const newData = () => {
    return {
      code_id: toNull(codeId),
      code_name: toNull(codeName),
      categ_id: toNull(categId),
      categ_name: toNull(categName),
      val_1: toNull(val1),
      val_2: toNull(val2),
      val_3: toNull(val3),
      pg: 'CodeDtl'
    };
  }

  // 削除確認ダイアログ /////////////////////////////////////////////////////////
  const [modalConfirmShow, setModalConfirmShow] = useState(false);
  const onClickModalConfirmClose = () => setModalConfirmShow(false);

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <div className="h1">
          <h1 className="float-left align-items-end">
            システム設定
          </h1>
          <div className="float-none" style={{height: "30px"}}>&nbsp;</div>
        </div>

        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                基本情報
              </h3>
            </Col>
            <Col className="text-right">
              {updateType !== 'update' && <Button variant="primary" onClick={onClickInsert}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
              {updateType === 'update' && <Button variant="primary" onClick={onClickUpdate}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="1">コード</Form.Label>
              <Col sm="4">
                {
                  updateType === "update" &&
                  <Form.Control plaintext readOnly value={codeId}/>
                }
                {
                  updateType !== "update" &&
                  <Form.Control
                    id="codeId"
                    type="tel"
                    value={codeId}
                    onChange={e => setCodeId(e.target.value)}
                    onBlur={e => setCodeId(toSingleByte(e.target.value))}
                  />
                }


              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">コード名</Form.Label>
              <Col sm="4">
                <Form.Control id="codeName" type="text" value={codeName} onChange={e => setCodeName(e.target.value)}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">種別コード</Form.Label>
              <Col sm="4">
                <Form.Control id="cateId" type="text" value={categId} onChange={e => setCategId(e.target.value)}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">種別名</Form.Label>
              <Col sm="4">
                <Form.Control id="cateName" type="text" value={categName} onChange={e => setCategName(e.target.value)}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">値１</Form.Label>
              <Col sm="4">
                <Form.Control id="val1" type="text" value={val1} onChange={e => setVal1(e.target.value)}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">値２</Form.Label>
              <Col sm="4">
                <Form.Control id="val2" type="text" value={val2} onChange={e => setVal2(e.target.value)}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">値３</Form.Label>
              <Col sm="4">
                <Form.Control id="val3" type="text" value={val3} onChange={e => setVal3(e.target.value)}/>
              </Col>
            </Form.Group>
          </Card>

          <Row>
            <Col>
              {updateType === 'update' && <Button variant="danger" onClick={onClickDelete}>削除</Button>}
            </Col>
          </Row>

        </Container>
      </div>

      {/*** 削除ダイアログ ****/}
      <Modal show={modalConfirmShow} onHide={onClickModalConfirmClose}>
        <Modal.Header closeButton>
          <Modal.Title>確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          本当に削除してよろしいですか
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClickModalConfirmClose}>
            キャンセル
          </Button>
          <Button variant="danger" onClick={onClickConfirm}>
            削除
          </Button>
        </Modal.Footer>
      </Modal>

      <Toast/>
    </>
  );
}

export default withRouter(CodeDtl);
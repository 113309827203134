import React, {useEffect} from 'react';
import {useHistory, withRouter} from "react-router-dom";

export const Top = () => {

  const hist = useHistory();

  useEffect(async () => {

    await sleep(1000);
    //工事一覧へ遷移
    hist.push("/book_list/");
  }, []);

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  return (
    <>
      <div id="loading_background" className="loading_background">
        <div className="loading_div">
          <div>ログイン中....</div>
          <img src="/static/loading_200.gif"/>
        </div>
      </div>
    </>
  );
}

export default withRouter(Top);
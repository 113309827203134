import React, {useState, useEffect} from "react";
import {getAPI, getSpAPI, inactivateRefreshToken, postAPI} from "./APIUtil";
import {AUTHORIZATION_SERVER} from "./Const";

export function parseLocationSearch(search) {
  if (!search) {
    return search;
  }

  if (search.substr(0, 1) === '?') {
    search = search.substr(1);
  }
  let params = search.split("&");
  let ret = {};
  for (let i = 0; i < params.length; i++) {
    let p = params[i].split("=");
    ret[p[0]] = p[1];
  }
  return ret;
}

export function useDebounce(value, delay, setLocPage) {
  // debounce の対象 state と setter
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // delay 後 debounce の対象 state をアップデート
    const timer = setTimeout(() => {
      setDebouncedValue(value);
      // setLocPage(1);
    }, delay);

    // 次の effect が実行される直前に timer キャンセル
    return () => {
      clearTimeout(timer);
    };

    // value、delay がアップデートするたびに effect 実行
  }, [value, delay]);

  // 最終的にアップデートされた state をリターン
  return debouncedValue;
}

export function linkFormatter(cell, row, onClickEvent) {
  return (<div><a onClick={() => onClickEvent(row.const_id)}>{cell}</a></div>);
}

export function amountFormatter(cell, row) {
  if (cell) {
    if (!isNaN(cell)) {
      return (<span>{Intl.NumberFormat().format(cell)}</span>);
    } else {
      return (<span>{cell}</span>);
    }
  } else {
    return (<span></span>);
  }
}

export function qtyFormatetr(cell, row, digit) {
  if (cell) {
    if (!isNaN(cell)) {
      let s = String(cell);
      if (s.split(".").length > 1) {
        let d = s.split(".")[1];
        while (d.substring(d.length - 1, d.length) === "0" && d.length > 1) {
          d = d.substring(0, d.length - 1);
        }
        digit = d.length;
      }
      return (<span>{parseFloat(cell).toFixed(digit)}</span>);
    } else {
      return (<span>{cell}</span>);
    }
  } else {
    return (<span></span>);
  }
}

export function parseDigit(cell, digit) {
  if (cell) {
    if (!isNaN(cell)) {
      let s = String(cell);
      if (s.split(".").length > 1) {
        let d = s.split(".")[1];
        while (d.substring(d.length - 1, d.length) === "0" && d.length > 1) {
          d = d.substring(0, d.length - 1);
        }
        digit = d.length;
      }
      return parseFloat(cell).toFixed(digit);
    } else {
      return cell;
    }
  }
}

export function resultFormatter(cell, row) {
  return (<span></span>);
}

export function dateformat(dateObj) {
  if (dateObj) {
    let year = dateObj.getFullYear();
    let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    let date = ("0" + dateObj.getDate()).slice(-2);
    return year + "-" + month + "-" + date;
  }
  return "";
}

export function dateformatYYYYMMDD(dateObj) {
  if (dateObj) {
    let year = dateObj.getFullYear();
    let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    let date = ("0" + dateObj.getDate()).slice(-2);
    return "" + year + month + date;
  }
  return "";
}

export function dateformatYYYYMMDDHHMMSS(dateObj) {
  if (dateObj) {
    let year = dateObj.getFullYear();
    let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    let date = ("0" + dateObj.getDate()).slice(-2);
    let hours = ("0" + dateObj.getHours()).slice(-2);
    let minutes = ("0" + dateObj.getMinutes()).slice(-2);
    let seconds = ("0" + dateObj.getSeconds()).slice(-2);
    return "" + year + month + date + hours + minutes + seconds;
  }
  return "";
}

export function parseDate(strDate) {
  if (strDate) {
    let date = new Date();
    if (strDate.length === 6) {
      date.setFullYear(strDate.substr(0, 4));
      date.setMonth(parseInt(strDate.substr(4, 2)) - 1);
    } else if (strDate.length === 8) {
      date.setFullYear(strDate.substr(0, 4));
      date.setMonth(parseInt(strDate.substr(4, 2)) - 1);
      date.setDate(strDate.substr(6, 2));
    } else if (strDate.length === 10) {
      date.setFullYear(strDate.substr(0, 4));
      date.setMonth(parseInt(strDate.substr(5, 2)) - 1);
      date.setDate(strDate.substr(8, 2));
    } else {
      let timestamp = Date.parse(strDate);
      if (isNaN(timestamp) == false) {
        date = new Date(timestamp);
      }
    }
    return date;
  }
}

export function parseDatetime(strDatetime) {
  if (strDatetime) {
    //YYYY-MM-DD HH:MM:SS形式
    let date = new Date();
    if (strDatetime.length === 19) {
      date.setFullYear(strDatetime.substr(0, 4));
      date.setMonth(parseInt(strDatetime.substr(5, 2)) - 1);
      date.setDate(strDatetime.substr(8, 2));
      date.setHours(strDatetime.substr(11, 2));
      date.setMinutes(strDatetime.substr(14, 2));
      date.setSeconds(strDatetime.substr(17, 2));
    }
    return date;
  }
}

export function dateformatJPN(dateObj) {

  if (!dateObj) {
    return "";
  }

  const weekday = ['日', '月', '火', '水', '木', '金', '土'];
  let year = dateObj.getFullYear();
  let month = (dateObj.getMonth() + 1);
  let date = dateObj.getDate();
  let week = weekday[dateObj.getDay()];
  return year + "年" + month + "月" + date + "日（" + week + "）";
}

export function dateformatJPNYYYYMMDD(dateObj) {

  if (!dateObj) {
    return "";
  }

  let year = dateObj.getFullYear();
  let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  let date = ("0" + dateObj.getDate()).slice(-2);
  return year + "年" + month + "月" + date + "日";
}

export function dateformatJPNYYMMDD(dateObj) {

  if (!dateObj) {
    return "";
  }

  let year = dateObj.getFullYear();
  year = ("" + year).substring(2);
  let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  let date = ("0" + dateObj.getDate()).slice(-2);
  return year + "年" + month + "月" + date + "日";
}

export function dateformatJPNByType(dateObj, type) {

  if (!dateObj) {
    return "";
  }

  const weekday = ['日', '月', '火', '水', '木', '金', '土'];
  const weekdayEng = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  if ("year" === type) {
    return dateObj.getFullYear();
  } else if ("month" === type) {
    return (dateObj.getMonth() + 1);
  } else if ("date" === type) {
    return dateObj.getDate();
  } else if ("hours" === type) {
    return dateObj.getHours();
  } else if ("minutes" === type) {
    return dateObj.getMinutes();
  } else if ("dateOfWeek" === type) {
    return weekday[dateObj.getDay()];
  } else if ("dateOfWeekEng" === type) {
    return weekdayEng[dateObj.getDay()];
  }
  return "";
}

export function dateformatDB(dateObj) {
  if (dateObj) {
    let year = dateObj.getFullYear();
    let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    let date = ("0" + dateObj.getDate()).slice(-2);
    return year + "-" + month + "-" + date;
  }
  return dateObj;
}

export function datetimeformatDB(dateObj) {
  if (dateObj) {
    let year = dateObj.getFullYear();
    let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    let date = ("0" + dateObj.getDate()).slice(-2);

    let hours = ("0" + dateObj.getHours()).slice(-2);
    let minutes = ("0" + dateObj.getMinutes()).slice(-2);
    let seconds = ("0" + dateObj.getSeconds()).slice(-2);
    return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
  }
  return dateObj;
}

export function timeformatHHMM(timeStr) {
  if (timeStr) {
    return timeStr.substr(0, 5);
  }
  return timeStr;
}

export function nvl(val) {
  if (val == null) {
    return "";
  }
  return val;
}

export function toNull(val) {
  if ("" === val) {
    return null;
  }
  return val;
}

export function setFocusCalendar(e, id) {
  e.preventDefault();
  $("#" + id).focus();
}

export const onSearchTime = async (query) => {
  let time = [
    {name: '00:00', id: '00:00'},
    {name: '00:15', id: '00:15'},
    {name: '00:30', id: '00:30'},
    {name: '00:45', id: '00:45'},
    {name: '01:00', id: '01:00'},
    {name: '01:15', id: '01:15'},
    {name: '01:30', id: '01:30'},
    {name: '01:45', id: '01:45'},
    {name: '02:00', id: '02:00'},
    {name: '02:15', id: '02:15'},
    {name: '02:30', id: '02:30'},
    {name: '02:45', id: '02:45'},
    {name: '03:00', id: '03:00'},
    {name: '03:15', id: '03:15'},
    {name: '03:30', id: '03:30'},
    {name: '03:45', id: '03:45'},
    {name: '04:00', id: '04:00'},
    {name: '04:15', id: '04:15'},
    {name: '04:30', id: '04:30'},
    {name: '04:45', id: '04:45'},
    {name: '05:00', id: '05:00'},
    {name: '05:15', id: '05:15'},
    {name: '05:30', id: '05:30'},
    {name: '05:45', id: '05:45'},
    {name: '06:00', id: '06:00'},
    {name: '06:15', id: '06:15'},
    {name: '06:30', id: '06:30'},
    {name: '06:45', id: '06:45'},
    {name: '07:00', id: '07:00'},
    {name: '07:15', id: '07:15'},
    {name: '07:30', id: '07:30'},
    {name: '07:45', id: '07:45'},
    {name: '08:00', id: '08:00'},
    {name: '08:15', id: '08:15'},
    {name: '08:30', id: '08:30'},
    {name: '08:45', id: '08:45'},
    {name: '09:00', id: '09:00'},
    {name: '09:15', id: '09:15'},
    {name: '09:30', id: '09:30'},
    {name: '09:45', id: '09:45'},
    {name: '10:00', id: '10:00'},
    {name: '10:15', id: '10:15'},
    {name: '10:30', id: '10:30'},
    {name: '10:45', id: '10:45'},
    {name: '11:00', id: '11:00'},
    {name: '11:15', id: '11:15'},
    {name: '11:30', id: '11:30'},
    {name: '11:45', id: '11:45'},
    {name: '12:00', id: '12:00'},
    {name: '12:15', id: '12:15'},
    {name: '12:30', id: '12:30'},
    {name: '12:45', id: '12:45'},
    {name: '13:00', id: '13:00'},
    {name: '13:15', id: '13:15'},
    {name: '13:30', id: '13:30'},
    {name: '13:45', id: '13:45'},
    {name: '14:00', id: '14:00'},
    {name: '14:15', id: '14:15'},
    {name: '14:30', id: '14:30'},
    {name: '14:45', id: '14:45'},
    {name: '15:00', id: '15:00'},
    {name: '15:15', id: '15:15'},
    {name: '15:30', id: '15:30'},
    {name: '15:45', id: '15:45'},
    {name: '16:00', id: '16:00'},
    {name: '16:15', id: '16:15'},
    {name: '16:30', id: '16:30'},
    {name: '16:45', id: '16:45'},
    {name: '17:00', id: '17:00'},
    {name: '17:15', id: '17:15'},
    {name: '17:30', id: '17:30'},
    {name: '17:45', id: '17:45'},
    {name: '18:00', id: '18:00'},
    {name: '18:15', id: '18:15'},
    {name: '18:30', id: '18:30'},
    {name: '18:45', id: '18:45'},
    {name: '19:00', id: '19:00'},
    {name: '19:15', id: '19:15'},
    {name: '19:30', id: '19:30'},
    {name: '19:45', id: '19:45'},
    {name: '20:00', id: '20:00'},
    {name: '20:15', id: '20:15'},
    {name: '20:30', id: '20:30'},
    {name: '20:45', id: '20:45'},
    {name: '21:00', id: '21:00'},
    {name: '21:15', id: '21:15'},
    {name: '21:30', id: '21:30'},
    {name: '21:45', id: '21:45'},
    {name: '22:00', id: '22:00'},
    {name: '22:15', id: '22:15'},
    {name: '22:30', id: '22:30'},
    {name: '22:45', id: '22:45'},
    {name: '23:00', id: '23:00'},
    {name: '23:15', id: '23:15'},
    {name: '23:30', id: '23:30'},
    {name: '23:45', id: '23:45'},
  ];

  let locOptions = [];
  time.map((result) => {
    if (query.length === 0 || result.name.indexOf(query) >= 0) {
      locOptions.push({name: result.name, id: result.id});
    }
  });
  return locOptions;
}

export const jumpToTopByDevice = (history) => {
  // if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
  //   if(location.pathname.indexOf("mb_") < 0){
  //     history.push("/mb_top");
  //   }
  // } else {
  //     history.push("/");
  // }
}

export const getDownloadFileName = (contentDisposition) => {

  let fileName = contentDisposition.substring(
    contentDisposition.indexOf("filename=") + 9,
    contentDisposition.length
  );

  fileName = decodeURI(fileName).replace(/\+/g, " ");
  return fileName;

}

export const isLatestVersion = async (isSp = false) => {

  let url = "version"
  let res = null;
  let device = null;

  if (!isSp) {
    res = await getAPI(url);
    device = "pc";
  } else {
    res = await getSpAPI(url)
    device = "sp";
  }

  let localStorageVersion = localStorage.getItem("const_manager_version_" + device);
  if (res.version != localStorageVersion) {
    console.log("更新:" + localStorageVersion + " >> " + res.version);
    localStorage.setItem("const_manager_version_" + device, res.version);
    localStorage.removeItem("storage_pic_list");
    localStorage.removeItem("storage_pic_file_name");
    localStorage.removeItem("storage_pic_file_info");

    localStorage.removeItem("book_search_condition");
    localStorage.removeItem("const_search_condition");
    localStorage.removeItem("cal_search_condition");
    localStorage.removeItem("design_search_condition");
    localStorage.removeItem("doc_search_condition");

    window.location.reload(true);
  }

}

export const getVersion = (isSp = false) => {

  let device = null;
  if (!isSp) {
    device = "pc";
  } else {
    device = "sp";
  }
  return localStorage.getItem("const_manager_version_" + device);

}

export const logout = async (isSp = false) => {

  await inactivateRefreshToken();
  localStorage.removeItem("const_manager_token");
  window.location = location.protocol + "//" + AUTHORIZATION_SERVER + (!isSp ? "" : "/sp");

}

export const lpad = (val, digits, char) => {

  if (!val) {
    val = "";
  } else {
    val = String(val);
  }

  while (val.length < digits) {
    val = char + val;
  }

  return val;
}

export const rpad = (val, digits, char) => {

  if (!val) {
    val = "";
  } else {
    val = String(val);
  }

  while (val.length < digits) {
    val = val + char;
  }

  return val;
}

export const addField = (obj, fieldName, value) => {

  if (!fieldName) {
    return obj;
  }
  if (value === null || value === undefined || value === '') {
    return obj;
  }
  obj[fieldName] = value;
  return obj;

}

// ファイルユーティリティ
export const getDocType = (fileName) => {
  if (fileName.split('.').length > 0) {
    return fileName.split('.').pop();
  }
  return "";
}

export const getDocName = (fileName) => {
  return fileName.substr(0, fileName.lastIndexOf("."));
}

//住所補完＆自動セット
export const formatZip = async (zip, callbackSetZip, callbackSetPref = null, callbackSetPrefSelected = null, callbackSetCity = null) => {

  if (!zip) {
    return;
  }

  if (zip.length != 7 || !zip.match(/^[0-9]+$/)) {
    return;
  }

  zip = zip.substr(0, 3) + "-" + zip.substr(3, 4);
  setZipAuto(zip, callbackSetZip, callbackSetPref, callbackSetPrefSelected, callbackSetCity);

}

//住所自動セット
export const setZipAuto = async (zip, callbackSetZip, callbackSetPref, callbackSetPrefSelected, callbackSetCity) => {

  callbackSetZip(toSingleByte(zip));

  if (!zip) {
    return;
  }
  if (zip.length !== 8) {
    return;
  }
  const res = await fetch("https://zipcloud.ibsnet.co.jp/api/search?zipcode=" + zip, {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
    }
  });
  let json = await res.json();
  if (!json.results) {
    return;
  }
  if (callbackSetPref) {
    callbackSetPref(json.results[0].address1);
  }
  if (callbackSetPrefSelected) {
    callbackSetPrefSelected({name: json.results[0].address1, id: json.results[0].address1});
  }
  if (callbackSetCity) {
    callbackSetCity(json.results[0].address2 + json.results[0].address3);
  }
}

//住所補完＆自動セット
export const formatTimeHHMM = (time) => {

  if (!time) {
    return time;
  }

  time = toSingleByte(time);

  //0-9と:のみにする
  let times = time.split('');
  time = '';
  for (let i = 0; i < times.length; i++) {
    if (times[i].match(/[0-9\:]/)) {
      time += times[i];
    }
  }

  if (time.length < 3) {
    return "";
  }

  if (time.length === 5) {
    return time;
  }

  time = time.replaceAll(":", "");
  time = ("00" + time).slice(-4);
  time = time.substr(0, 2) + ":" + time.substr(2, 2);
  return time;
}


// TYPEAHEAD 共通関数　/////////////////////////////////
export const onSearchCategory = async () => {
  const searchCondition = {categ_id: "CATEGORY", sort_field: "code_id"};
  const res = await getAPI("ms_code_search_view", searchCondition);
  return res.results.map((result) => {
    return ({
      id: result.code_name,
      name: result.code_name
    });
  });
}

export const onSearchOpnArea = async () => {
  const searchCondition = {categ_id: "OPN_AREA", sort_field: "code_id"};
  const res = await getAPI("ms_code_search_view", searchCondition);
  return res.results.map((result) => {
    return ({
      id: result.code_name,
      name: result.code_name
    });
  });
}

export const onSearchNote2 = async () => {
  const searchCondition = {categ_id: "NOTE2", sort_field: "code_id"};
  const res = await getAPI("ms_code_search_view", searchCondition);
  return res.results.map((result) => {
    return ({
      id: result.code_name,
      name: result.code_name
    });
  });
}

export const onSearchConst = async (query) => {

  let searchCondition = {
    name: query
  }
  const res = await getAPI("ms_const_typeahead_view", searchCondition);
  let locOptions = [];
  res.results.map((result) => {
    locOptions.push({name: result.name, id: result.const_id});
  });
  return locOptions;
}

export const onSearchConstEnd = async (query) => {

  let searchCondition = {
    name: query
  }
  const res = await getAPI("ms_const_typeahead_view", searchCondition);
  let locOptions = [];
  res.results.map((result) => {
    locOptions.push({name: result.name, id: result.const_id});
  });
  return locOptions;
}

export const onSearchDesign = async (query, designType) => {
  let searchCondition = {
    name: query,
    design_type: designType ? designType : "意匠・構造"
  }
  const res = await getAPI("ms_design_typeahead_view", searchCondition);
  let locOptions = [];
  res.results.map((result) => {
    locOptions.push({name: result.name, id: result.design_id});
  });
  return locOptions;
}

export const onSearchPref = async (query) => {
  let prefs = [
    {name: '北海道', id: '北海道'},
    {name: '青森県', id: '青森県'},
    {name: '岩手県', id: '岩手県'},
    {name: '宮城県', id: '宮城県'},
    {name: '秋田県', id: '秋田県'},
    {name: '山形県', id: '山形県'},
    {name: '福島県', id: '福島県'},
    {name: '茨城県', id: '茨城県'},
    {name: '栃木県', id: '栃木県'},
    {name: '群馬県', id: '群馬県'},
    {name: '埼玉県', id: '埼玉県'},
    {name: '千葉県', id: '千葉県'},
    {name: '東京都', id: '東京都'},
    {name: '神奈川県', id: '神奈川県'},
    {name: '新潟県', id: '新潟県'},
    {name: '富山県', id: '富山県'},
    {name: '石川県', id: '石川県'},
    {name: '福井県', id: '福井県'},
    {name: '山梨県', id: '山梨県'},
    {name: '長野県', id: '長野県'},
    {name: '岐阜県', id: '岐阜県'},
    {name: '静岡県', id: '静岡県'},
    {name: '愛知県', id: '愛知県'},
    {name: '三重県', id: '三重県'},
    {name: '滋賀県', id: '滋賀県'},
    {name: '京都府', id: '京都府'},
    {name: '大阪府', id: '大阪府'},
    {name: '兵庫県', id: '兵庫県'},
    {name: '奈良県', id: '奈良県'},
    {name: '和歌山県', id: '和歌山県'},
    {name: '鳥取県', id: '鳥取県'},
    {name: '島根県', id: '島根県'},
    {name: '岡山県', id: '岡山県'},
    {name: '広島県', id: '広島県'},
    {name: '山口県', id: '山口県'},
    {name: '徳島県', id: '徳島県'},
    {name: '香川県', id: '香川県'},
    {name: '愛媛県', id: '愛媛県'},
    {name: '高知県', id: '高知県'},
    {name: '福岡県', id: '福岡県'},
    {name: '佐賀県', id: '佐賀県'},
    {name: '長崎県', id: '長崎県'},
    {name: '熊本県', id: '熊本県'},
    {name: '大分県', id: '大分県'},
    {name: '宮崎県', id: '宮崎県'},
    {name: '鹿児島県', id: '鹿児島県'},
    {name: '沖縄県', id: '沖縄県'},
  ];

  let locOptions = [];
  prefs.map((result) => {
    if (query.length === 0 || result.name.indexOf(query) >= 0) {
      locOptions.push({name: result.name, id: result.id});
    }
  });
  return locOptions;
}

const status0Options = [
  {name: '着工前', id: 'NOT_START'},
  {name: '工事中', id: 'PROCESSING'},
  {name: '完了', id: 'COMPLETE'},
  {name: '中止', id: 'CANCEL'}
]
export const onSearchStatus0 = async (query) => {

  let locOptions = [];
  status0Options.map((result) => {
    if (query.length === 0 || result.name.indexOf(query) >= 0) {
      locOptions.push({name: result.name, id: result.id});
    }
  });
  return locOptions;
}
export const getStatus0ById = (id) => {
  for (let i = 0; i < status0Options.length; i++) {
    if (status0Options[i].id === id) {
      return status0Options[i];
    }
  }
  return null;
}

const status1Options = [
  {name: '有', id: 'HAS_BOOK'},
  {name: '無', id: 'HAS_NO_BOOK'}
]
export const onSearchStatus1 = async (query) => {

  let locOptions = [];
  status1Options.map((result) => {
    if (query.length === 0 || result.name.indexOf(query) >= 0) {
      locOptions.push({name: result.name, id: result.id});
    }
  });
  return locOptions;
}
export const getStatus1ById = (id) => {
  for (let i = 0; i < status1Options.length; i++) {
    if (status1Options[i].id === id) {
      return status1Options[i];
    }
  }
  return null;
}

const remainChargeOptions = [
  {name: '有', id: 'REMAIN'},
  {name: '無', id: 'NO_REMAIN'}
]
export const onSearchRemainCharge = async (query) => {

  let locOptions = [];
  remainChargeOptions.map((result) => {
    if (query.length === 0 || result.name.indexOf(query) >= 0) {
      locOptions.push({name: result.name, id: result.id});
    }
  });
  return locOptions;
}
export const getRemainChargeById = (id) => {
  for (let i = 0; i < remainChargeOptions.length; i++) {
    if (remainChargeOptions[i].id === id) {
      return remainChargeOptions[i];
    }
  }
  return null;
}

// 入力チェック　/////////////////////////////////
export function checkKey(event, pattern) {
  if (!event.key.match(pattern)) {
    event.preventDefault();
    return;
  }
}

// 全角を半角に変換　/////////////////////////////////
export function toSingleByte(value) {
  value = toSingleByteAlphaNumberOnly(value);
  value = value.replace(/[－﹣‐﹘―⎯⏤ー─━]/g, '-');
  return value;
}

export function toSingleByteAlphaNumberOnly(value) {
  value = "" + value;
  value = value.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
  return value;
}

// 半角を全角に変換　/////////////////////////////////
export function toMultibyte(value) {
  value = "" + value;
  value = value.replace(/[A-Za-z0-9]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
  });
  value = value.replace(/[-−⁃‑‒–—ｰ]/g, 'ー');
  return value;
}

// 数値用カンマ削除　/////////////////////////////////
export function parseNumber(value) {
  let val = '' + value;
  val = toSingleByte(value);
  val = val.replace(/,/g, '');
  return parseFloat(val);

}// 数値用カンマ追加　/////////////////////////////////
export function formatNumber(value) {
  if (!value) {
    return value;
  }
  let val = '' + value;
  val = toSingleByte(value);
  val = val.replace(/,/g, '');
  if (val.match(/[^0-9.-]/)) {
    return "";
  }
  val = parseFloat(val).toLocaleString();
  return val;
}

// ボタンローディング開始
export const startLoadingBtn = (id, variant = "primary") => {

  let list = $("#" + id).children("span");
  for (let i = 0; i < list.length; i++) {
    $(list[0]).css("display", "none");
  }
  $("#" + id).append("<img id='img_loading_btn' src='/static/loading_btn_30_" + variant + ".gif' style='margin: 0 auto;height: 20px;'/>");
  $("#" + id).prop("disabled", true);

}

// ボタンローディング終了
export const finishLoadingBtn = (id) => {

  $("#" + id).children("#img_loading_btn").remove();
  let list = $("#" + id).children("span");
  for (let i = 0; i < list.length; i++) {
    $(list[0]).css("display", "block");
  }
  $("#" + id).prop("disabled", false);

}

// スリープ関数
export const sleepByPromise = (sec) => {
  return new Promise(resolve => setTimeout(resolve, sec * 1000));
}

export const onFocusSelect = (e) => {
  $(e.target).select();
}
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {Card, Col, Container, Form, FormGroup, InputGroup, Nav, Row} from "react-bootstrap";
import {deleteAPI, getAPI, postAPI, putAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
  faFile,
  faMapMarkerAlt,
  faPen,
  faQrcode
} from "@fortawesome/free-solid-svg-icons";
import {
  addField,
  checkKey,
  formatZip,
  nvl,
  onSearchPref,
  setZipAuto,
  toMultibyte,
  toNull,
  toSingleByte,
  toSingleByteAlphaNumberOnly
} from "../CMUtil";
import AsyncSelect from "react-select/async";
import {GlobalContext} from "../Global";

export const ConstDtl = ({match: {params: {const_id}}}) => {

  const hist = useHistory();
  const initIsNameError = useRef(true);

  const {loginUser, setLoginUser} = useContext(GlobalContext);
  const [updateType, setUpdateType] = useState("");

  const [constId, setConstId] = useState("");
  const [isErrorName, setIsNameError] = useState(false);
  const [name, setName] = useState("");
  const [nameSub, setNameSub] = useState("");
  const [tel, setTel] = useState("");
  const [fax, setFax] = useState("");
  const [zip, setZip] = useState("");
  const [pref, setPref] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [history, setHistory] = useState("");
  const [safetyDoc, setSafetyDoc] = useState("");
  const [safetyDocText, setSafetyDocText] = useState("");
  const [invSpec, setInvSpec] = useState("");
  const [invSpecType, setInvSpecType] = useState("");
  const [invSpecTypeOther, setInvSpecTypeOther] = useState("");
  const [invSendTo, setInvSendTo] = useState("");
  const [invCutoffDate, setInvCutoffDate] = useState("");
  const [invCutoffDateMonthEnd, setInvCutoffDateMonthEnd] = useState(false);
  const [invArrMonth, setInvArrMonth] = useState("");
  const [invArrDate, setInvArrDate] = useState("");
  const [invArrDateMonthEnd, setInvArrDateMonthEnd] = useState(false);
  const [payNotice, setPayNotice] = useState("");
  const [payMonth, setPayMonth] = useState("");
  const [payDate, setPayDate] = useState("");
  const [payDateMonthEnd, setPayDateMonthEnd] = useState(false);
  const [paySafetyCost, setPaySafetyCost] = useState("");
  const [payTransferFee, setPayTransferFee] = useState("");
  const [note, setNote] = useState("");

  const [prefSelected, setPrefSelected] = useState([]);

  const [safetyDocSelected, setSafetyDocSelected] = useState({});
  const [safetyDocList, setSafetyDocList] = useState([]);

  const [updateBtnDisable, setUpdateBtnDisable] = useState(true);

  //初期化
  useEffect(() => {

    const update = async () => {

      //登録ボタン制御
      let searchCondition = {
        categ_id: "PMN_BTN",
        code_id: "P_CS_" + loginUser.user_id
      }
      res = await getAPI("ms_code_search_view", searchCondition);
      if (res.count) {
        setUpdateBtnDisable(false);
      }

      if (!const_id) {
        setIsNameError(false);
        initIsNameError.current = false;
        return;
      }
      let obj = await getAPI("ms_const/" + const_id);
      setUpdateType("update");
      setConstId(obj.const_id);
      setName(nvl(obj.name));
      setNameSub(nvl(obj.name_sub));
      setTel(nvl(obj.tel));
      setFax(nvl(obj.fax));
      setZip(nvl(obj.zip));
      setPref(nvl(obj.pref));
      if (obj.pref) {
        setPrefSelected({name: obj.pref, id: obj.pref});
      }
      setCity(nvl(obj.city));
      setAddress(nvl(obj.address));
      setSafetyDoc(nvl(obj.safety_doc));
      setSafetyDocSelected({id: obj.safety_doc, name: obj.safety_doc});
      setSafetyDocText(nvl(obj.safety_doc_text));
      setInvSpec(nvl(obj.inv_spec));
      setInvSpecType(nvl(obj.inv_spec_type));
      setInvSpecTypeOther(nvl(obj.inv_spec_type_other));
      setInvSendTo(nvl(obj.inv_send_to));
      if (obj.inv_cutoff_date !== -1) {
        setInvCutoffDate(nvl(obj.inv_cutoff_date));
        setInvCutoffDateMonthEnd(false);
      } else {
        setInvCutoffDate("");
        setInvCutoffDateMonthEnd(true);
      }
      setInvArrMonth(nvl(obj.inv_arr_month));
      if (obj.inv_arr_date !== -1) {
        setInvArrDate(nvl(obj.inv_arr_date));
        setInvArrDateMonthEnd(false);
      } else {
        setInvArrDate("");
        setInvArrDateMonthEnd(true);
      }
      setPayNotice(nvl(obj.pay_notice));
      setPayMonth(nvl(obj.pay_month));
      if (obj.pay_date !== -1) {
        setPayDate(nvl(obj.pay_date));
        setPayDateMonthEnd(false);
      } else {
        setPayDate("");
        setPayDateMonthEnd(true);
      }
      setPaySafetyCost(nvl(obj.pay_safety_cost));
      setPayTransferFee(nvl(obj.pay_transfer_fee));
      setNote(nvl(obj.note));

      //施工実績
      let condition = {};
      let res = null;
      addField(condition, "const_id", obj.const_id);
      res = await getAPI("tr_book_search_view", condition);
      if (res.count === 0) {
        setHistory("無し");
      } else {
        setHistory("有り");
      }

      let locIsNameError = await checkNameError(obj.const_id, obj.name, obj.pref, obj.city, obj.address);
      setIsNameError(locIsNameError);
      initIsNameError.current = false;
    }
    update();

  }, []);

  useEffect(() => {

    if (initIsNameError.current) {
      return;
    }

    const update = async () => {
      let locIsNameError = await checkNameError(constId, name, pref, city, address);
      setIsNameError(locIsNameError);
    }
    update();

  }, [name, pref, city, address]);

  const checkNameError = async (locConstId, locName, locPref, locCity, locAddress) => {

    if (!locName) {
      return false;
    }
    if (!locPref) {
      return false;
    }
    if (!locCity) {
      return false;
    }
    if (!locAddress) {
      return false;
    }

    let condition = {};
    addField(condition, "name_exact", locName);
    addField(condition, "pref_exact", locPref);
    addField(condition, "city_exact", locCity);
    addField(condition, "address_exact", locAddress);
    let res = await getAPI("ms_const_search_view", condition);

    if (res.count >= 2) {
      return true
    } else if (res.count >= 1) {
      if (res.results[0].const_id !== locConstId) {
        return true
      }
    }
    return false;
  }

  const handleChangePref = (value) => {
    setPrefSelected(value ? value : "");
    setPref(value ? value.id : "");
  }

  const onClickInsert = async () => {
    let obj = newData();
    obj = await postAPI("ms_const/", obj);
    setConstId(obj.const_id);
    hist.push("/const/" + obj.const_id);
    setUpdateType("update");
    success("登録完了");
  }

  const onClickUpdate = async () => {
    let obj = newData();
    await putAPI("ms_const/" + const_id + "/", obj);
    success("更新完了");
  }

  const onClickDelete = async () => {
    let obj = newData();
    let res = await deleteAPI("ms_const/" + const_id + "/", obj);
    if (res) {
      success("削除完了");
      hist.push("/const_list/");
    }
  }

  const newData = () => {
    return {
      const_id: toNull(constId),
      name: toNull(name),
      name_sub: toNull(nameSub),
      tel: toNull(tel),
      fax: toNull(fax),
      zip: toNull(zip),
      pref: toNull(pref),
      city: toNull(city),
      address: toNull(address),
      safety_doc: toNull(safetyDoc),
      safety_doc_text: toNull(safetyDocText),
      inv_spec: toNull(invSpec),
      inv_spec_type: toNull(invSpecType),
      inv_spec_type_other: toNull(invSpecTypeOther),
      inv_send_to: toNull(invSendTo),
      inv_cutoff_date: invCutoffDateMonthEnd ? -1 : toNull(invCutoffDate),
      inv_arr_month: toNull(invArrMonth),
      inv_arr_date: invArrDateMonthEnd ? -1 : toNull(invArrDate),
      pay_notice: toNull(payNotice),
      pay_month: toNull(payMonth),
      pay_date: payDateMonthEnd ? -1 : toNull(payDate),
      pay_safety_cost: toNull(paySafetyCost),
      pay_transfer_fee: toNull(payTransferFee),
      note: toNull(note),
      create_pg: 'ConstDtl',
      update_pg: 'ConstDtl'
    };
  }

  const handleChangeSafetyDoc = (value) => {
    setSafetyDocSelected(value ? value : "");
    setSafetyDoc(value ? value.id : "");
  }

  const onSearchSafetyDoc = async (query) => {
    let locOptions = [];

    let locSafetyDocList = safetyDocList;
    if (locSafetyDocList.length === 0) {
      //初回のみ選択肢セット
      const res = await getAPI("ms_code_search_view", {categ_id: "SAFEDOC", sort_field: "code_id"});
      locSafetyDocList = [];
      res.results.map(code => {
        locSafetyDocList.push({
          id: code.code_name,
          name: code.code_name
        });
      });
      setSafetyDocList(locSafetyDocList);
    }

    locSafetyDocList.map((l) => {
      if (l.name.indexOf(query) > -1) {
        locOptions.push(l);
      }
    });
    return locOptions;
  }

  const onClickInvCutoffMonthEnd = (e) => {
    if (e.target.checked) {
      setInvCutoffDate("");
    }
    $("#invCutoffDate").prop("disabled", e.target.checked);
    setInvCutoffDateMonthEnd(e.target.checked);
  }

  const onClickInvArrMonthEnd = (e) => {
    if (e.target.checked) {
      setInvArrDate("")
    }
    $("#invArrDate").prop("disabled", e.target.checked);
    setInvArrDateMonthEnd(e.target.checked);
  }

  const onClickPayDayMonthEnd = (e) => {
    if (e.target.checked) {
      setPayDate("");
    }
    $("#payDate").prop("disabled", e.target.checked);
    setPayDateMonthEnd(e.target.checked);
  }

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <div className="h1">
          <h1 className="float-left align-items-end">
            施工業者
          </h1>
          <div className="float-none" style={{height: "30px"}}>&nbsp;</div>
        </div>

        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                基本情報
              </h3>
            </Col>
            <Col className="text-right">
              {updateType !== 'update' &&
                <Button variant="primary" onClick={onClickInsert} disabled={updateBtnDisable}><FontAwesomeIcon
                  icon={faPen} size="lg"/>登録</Button>}
              {updateType === 'update' &&
                <Button variant="primary" onClick={onClickUpdate} disabled={updateBtnDisable}><FontAwesomeIcon
                  icon={faPen} size="lg"/>登録</Button>}
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="1">施工業者</Form.Label>
              <Col sm="4" className="input-group">
                <Form.Control
                  id="name"
                  type="text"
                  value={name}
                  maxLength={80}
                  onChange={e => setName(e.target.value)}
                  onBlur={e => setName(toSingleByteAlphaNumberOnly(e.target.value))}
                />
                <span style={{margin: "0.5rem"}}>
                {!isErrorName &&
                  <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{color: "green", width: "1.1rem"}}/>
                }
                  {isErrorName &&
                    <FontAwesomeIcon icon={faExclamationTriangle} size="lg" style={{color: "red", width: "1.1rem"}}/>
                  }
                </span>
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">フリガナ</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="nameSub"
                  type="text"
                  value={nameSub}
                  maxLength={80}
                  onChange={e => setNameSub(e.target.value)}
                  onBlur={e => setNameSub(toSingleByteAlphaNumberOnly(e.target.value))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">電話</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="tel"
                  type="tel"
                  value={tel}
                  style={{imeMode: "disabled"}}
                  onKeyPress={e => checkKey(e, /[0-9\-]/)}
                  maxLength={20}
                  onChange={e => setTel(e.target.value)}
                  onBlur={e => setTel(toSingleByte(e.target.value))}
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">ＦＡＸ</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="fax"
                  type="text"
                  value={fax}
                  onKeyPress={e => checkKey(e, /[0-9\-]/)}
                  maxLength={20}
                  onChange={e => setFax(e.target.value)}
                  onBlur={e => setFax(toSingleByte(e.target.value))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">住所</Form.Label>
              <Col sm="2" className="input-group">
                <InputGroup.Text className="input-group-text-cm" style={{fontWeight: "700"}}>〒</InputGroup.Text>
                <Form.Control id="zip"
                              type="text"
                              value={zip}
                              style={{textAlign: "center"}}
                              onKeyPress={e => checkKey(e, /[0-9\-]/)}
                              maxLength={8}
                              onChange={e => setZipAuto(e.target.value, setZip, setPref, setPrefSelected, setCity)}
                              onBlur={e => formatZip(e.target.value, setZip, setPref, setPrefSelected, setCity)}
                />
              </Col>
              <Col sm="2">
                <AsyncSelect sm="2"
                             className="async-select"
                             cacheOptions
                             defaultOptions
                             isClearable
                             value={prefSelected}
                             getOptionLabel={e => e.name}
                             getOptionValue={e => e.id}
                             loadOptions={onSearchPref}
                             onChange={handleChangePref}
                             placeholder=""
                />
              </Col>
              <Col sm="6" className="input-group">
                <Form.Control type="text" value={city} id="address2"
                              style={{borderRadius: "0"}}
                              onChange={e => setCity(e.target.value)}/>
                <Form.Control type="text" value={address}
                              style={{width: "20%", borderTopLeftRadius: "0", borderBottomLeftRadius: "0"}}
                              onChange={e => setAddress(e.target.value)}
                              onBlur={e => setAddress(toMultibyte(e.target.value))}/>
                <Button as={Link} variant="link" to={"/map?const_id=" + constId} style={{paddingRight: "0"}}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} size="2x"/>
                </Button>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">実績</Form.Label>
              <Col sm="4">
                <InputGroup.Text className="input-group-text-cm" style={{fontWeight: "700"}}>
                  <Form.Control plaintext readOnly value={history}/>
                </InputGroup.Text>
              </Col>
            </Form.Group>
          </Card>

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                安全書類
              </h3>
            </Col>
          </Row>
          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="2">書式</Form.Label>
              <Col sm="4">
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={safetyDocSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchSafetyDoc}
                  onChange={handleChangeSafetyDoc}
                  placeholder=""
                />
              </Col>
              <Col sm="5">
                <Form.Control
                  id="safetyDocText"
                  type="text"
                  value={safetyDocText}
                  onChange={e => setSafetyDocText(e.target.value)}
                  placeholder="追加情報"
                />
              </Col>
            </Form.Group>
          </Card>

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                請求書
              </h3>
            </Col>
          </Row>
          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="2">指定請求書</Form.Label>
              <Col sm="2">
                <Form.Check
                  inline
                  id="invSpec1"
                  type="radio"
                  name="invSpec"
                  label="有り"
                  value="有"
                  onChange={e => setInvSpec('有')}
                  checked={invSpec === '有'}
                />
              </Col>
              <Col sm="2">
                <Form.Check
                  inline
                  id="invSpec2"
                  type="radio"
                  name="invSpec"
                  label="無し"
                  value="無"
                  onChange={e => setInvSpec('無')}
                  checked={invSpec === '無'}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="2">（有りの場合）</Form.Label>
              <Col sm="2">
                <Form.Check
                  inline
                  id="invSpecType1"
                  type="radio"
                  name="invSpecType"
                  label="用紙の購入"
                  value="用紙の購入"
                  onChange={e => setInvSpecType(e.target.value)}
                  checked={invSpecType === "用紙の購入"}/>
              </Col>
              <Col sm="2">
                <Form.Check
                  inline
                  id="invSpecType2"
                  type="radio"
                  name="invSpecType"
                  label="ＨＰより"
                  value="ＨＰより"
                  onChange={e => setInvSpecType(e.target.value)}
                  checked={invSpecType === "ＨＰより"}/>
              </Col>
              <Col sm="2">
                <Form.Check
                  inline
                  id="invSpecType3"
                  type="radio"
                  name="invSpecType"
                  label="その他"
                  value="その他"
                  onChange={e => setInvSpecType(e.target.value)}
                  checked={invSpecType === "その他"}/>
              </Col>
              <Col sm="3">
                <InputGroup>
                  <Form.Control id="invSpecTypeOther" type="text" value={invSpecTypeOther}
                                onChange={e => setInvSpecTypeOther(e.target.value)}/>
                </InputGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="2">請求書送付先</Form.Label>
              <Col sm="2">
                <Form.Check
                  inline
                  id="invSendTo1"
                  type="radio"
                  name="invSendTo"
                  label="現場事務所"
                  value="現場事務所"
                  onChange={e => setInvSendTo(e.target.value)}
                  checked={invSendTo === "現場事務所"}/>
              </Col>
              <Col sm="2">
                <Form.Check
                  inline
                  id="invSendTo2"
                  type="radio"
                  name="invSendTo"
                  label="本社・支店"
                  value="本社・支店"
                  onChange={e => setInvSendTo(e.target.value)}
                  checked={invSendTo === "本社・支店"}/>
              </Col>
            </Form.Group>
          </Card>

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                支払い
              </h3>
            </Col>
          </Row>
          <Card className="card-secondary w-80 mx-auto mb-0">

            <Form.Group as={Row}>
              <Form.Label column sm="2">支払い通知書</Form.Label>
              <Col sm="2">
                <Form.Check
                  inline
                  id="payNotice1"
                  type="radio"
                  name="payNotice"
                  label="有り"
                  value="有り"
                  onChange={e => setPayNotice(e.target.value)}
                  checked={payNotice === "有り"}/>
              </Col>
              <Col sm="2">
                <Form.Check
                  inline
                  id="payNotice2"
                  type="radio"
                  name="payNotice"
                  label="無し"
                  value="無し"
                  onChange={e => setPayNotice(e.target.value)}
                  checked={payNotice === "無し"}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">締め日</Form.Label>
              <Col sm="4">
                <InputGroup.Text className="input-group-text-cm"
                                 style={{fontWeight: "700", paddingLeft: "1rem"}}>毎月</InputGroup.Text>
              </Col>
              <Col sm="2">
                <InputGroup>
                  <Form.Control
                    id="invCutoffDate"
                    type="number"
                    className="text-right"
                    min={1}
                    max={31}
                    value={invCutoffDate}
                    onChange={e => setInvCutoffDate(e.target.value)}
                    disabled={invCutoffDateMonthEnd}
                  />
                  <InputGroup.Text className="input-group-text-cm" style={{fontWeight: "700"}}>日</InputGroup.Text>
                  <Form.Check
                    id="invCutoffDate1"
                    type="checkbox"
                    className="ml-3"
                    label="末日"
                    value={-1}
                    onChange={e => onClickInvCutoffMonthEnd(e)}
                    checked={invCutoffDateMonthEnd}/>
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">必着日</Form.Label>
              <Col sm="2">
                <Form.Check
                  inline
                  id="invArrMonth1"
                  type="radio"
                  name="invArrMonth"
                  label="当月"
                  value="当月"
                  onChange={e => setInvArrMonth(e.target.value)}
                  checked={invArrMonth === "当月"}/>
              </Col>
              <Col sm="2">
                <Form.Check
                  inline
                  id="invArrMonth2"
                  type="radio"
                  name="invArrMonth"
                  label="翌月"
                  value="翌月"
                  onChange={e => setInvArrMonth(e.target.value)}
                  checked={invArrMonth === "翌月"}/>
              </Col>
              <Col sm="2">
                <InputGroup>
                  <Form.Control
                    id="invArrDate"
                    type="number"
                    className="text-right"
                    min={1}
                    max={31}
                    value={invArrDate}
                    onChange={e => setInvArrDate(e.target.value)}
                    disabled={invArrDateMonthEnd}
                  />
                  <InputGroup.Text className="input-group-text-cm" style={{fontWeight: "700"}}>日</InputGroup.Text>
                  <Form.Check
                    id="invArrDate1"
                    type="checkbox"
                    className="ml-3"
                    label="末日"
                    value={-1}
                    onChange={e => onClickInvArrMonthEnd(e)}
                    checked={invArrDateMonthEnd}/>
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">支払日</Form.Label>
              <Col sm="2">
                <Form.Check
                  inline
                  id="payMonth1"
                  type="radio"
                  name="payMonth"
                  label="翌月"
                  value="翌月"
                  onChange={e => setPayMonth(e.target.value)}
                  checked={payMonth === "翌月"}/>
              </Col>
              <Col sm="2">
                <Form.Check
                  inline
                  id="payMonth2"
                  type="radio"
                  name="payMonth"
                  label="翌々月"
                  value="翌々月"
                  onChange={e => setPayMonth(e.target.value)}
                  checked={payMonth === "翌々月"}/>
              </Col>
              <Col sm="2">
                <InputGroup>
                  <Form.Control
                    id="payDate"
                    type="number"
                    className="text-right"
                    min={1}
                    max={31}
                    value={payDate}
                    onChange={e => setPayDate(e.target.value)}
                    disabled={payDateMonthEnd}
                  />
                  <InputGroup.Text className="input-group-text-cm" style={{fontWeight: "700"}}>日</InputGroup.Text>
                  <Form.Check
                    id="payDate1"
                    type="checkbox"
                    className="ml-3"
                    label="末日"
                    value={-1}
                    onChange={e => onClickPayDayMonthEnd(e)} checked={payDateMonthEnd}/>
                </InputGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="2">安全協力会費</Form.Label>
              <Col sm="2">
                <InputGroup>
                  <Form.Control id="paySafetyCost" type="number" className="text-right" value={paySafetyCost}
                                onChange={e => setPaySafetyCost(e.target.value)}/>
                  <InputGroup.Text className="input-group-text-cm" style={{fontWeight: "700"}}>％</InputGroup.Text>
                </InputGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="2">振込手数料相殺額</Form.Label>
              <Col sm="2">
                <InputGroup>
                  <Form.Control id="payTransferFee" type="number" className="text-right" value={payTransferFee}
                                onChange={e => setPayTransferFee(e.target.value)}/>
                  <InputGroup.Text className="input-group-text-cm" style={{fontWeight: "700"}}>円</InputGroup.Text>
                </InputGroup>
              </Col>
            </Form.Group>

          </Card>


          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                備考
              </h3>
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="2"></Form.Label>
              <Col sm="9">
                <Form.Control as="textarea" rows={20} value={note} onChange={e => setNote(e.target.value)}/>
              </Col>
            </Form.Group>
          </Card>

          <Row>
            <Col>
              {updateType === 'update' && <Button variant="danger" onClick={onClickDelete}>削除</Button>}
            </Col>
          </Row>

        </Container>
      </div>
      <Toast/>
    </>
  );
}

export default withRouter(ConstDtl);
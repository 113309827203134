import React, {useContext, useEffect, useState} from 'react';
import {getAPI} from "../APIUtil";
import {GlobalContext} from "../Global";
import {AUTHORIZATION_SERVER} from "../Const";

export const PageRoutesAuthenticatedGuard = ({children}) => {

  const {loginUser, setLoginUser} = useContext(GlobalContext);

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [screen, setScreen] = useState("")

  useEffect(() => {
    const init = async () => {

      let obj = await getAPI("current_login_user");
      if(!obj){
        window.location = location.protocol + "//" + AUTHORIZATION_SERVER + "/auth/";
        return;
      }
      if(obj.count !== 1){
        window.location = location.protocol + "//" + AUTHORIZATION_SERVER + "/auth/";
      }

      setLoginUser(obj.results[0]);
      setScreen(<>{children}</>);
    }
    init();
  }, []);

  return (
    <>
      {screen}
    </>
  );
}

export default PageRoutesAuthenticatedGuard;

import {DropdownButton, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import {RoutePaths} from "./PageRoutesAuthenticated";
import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCalendarAlt,
  faCog,
  faFolder,
  faList,
  faPen,
  faSearch,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import {dateformatYYYYMMDD, logout} from "../CMUtil";
import {GlobalContext} from "../Global";

export const AppNav = () => {

  const [month, setMonth] = useState("");

  const {loginUser, setLoginUser} = useContext(GlobalContext);
  const [permission, setPermission] = useState("");

  //初期化
  useEffect(() => {
    const update = async () => {
      let yearStr = dateformatYYYYMMDD(new Date()).substring(0,4);
      let monthStr = dateformatYYYYMMDD(new Date()).substring(4,6);
      setMonth(yearStr + "-" + monthStr);
    }
    update();

    if(loginUser && loginUser.permission){
      setPermission(loginUser.permission.code_id);
    }

  }, []);

  const navDropDownMaster = (<span><FontAwesomeIcon icon={faCog}/>マスタ設定　</span>);
  const navDropDownUser = (<span><FontAwesomeIcon icon={faUser}/>{loginUser.last_name + ' ' + loginUser.first_name}　</span>);

  return (
    <Navbar expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link as={Link} to={RoutePaths.root.book_list}>
            <FontAwesomeIcon icon={faBook}/>
            工事台帳
          </Nav.Link>
          <Nav.Link as={Link} to={"/cal/" + month + "/"}>
            <FontAwesomeIcon icon={faCalendarAlt}/>
            カレンダー
          </Nav.Link>
          <Nav.Link as={Link} to={RoutePaths.root.book_status}>
            <FontAwesomeIcon icon={faList}/>
            予実一覧
          </Nav.Link>
          <DropdownButton title={navDropDownMaster} variant="nav" style={{width: "150px", marginTop: "0"}}>
            <NavDropdown.Item as={Link} to={RoutePaths.root.const_list}>施工業者</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={RoutePaths.root.design_list}>設計事務所</NavDropdown.Item>
            {
              permission === 'PMT_ADMIN' &&
              <NavDropdown.Item as={Link} to={RoutePaths.root.user_list}>社員</NavDropdown.Item>
            }
            {
              permission === 'PMT_ADMIN' &&
              <NavDropdown.Item as={Link} to={RoutePaths.root.code_list}>システム設定</NavDropdown.Item>
            }
          </DropdownButton>
          <Nav.Link as={Link} to={RoutePaths.root.doc_list}>
            <FontAwesomeIcon icon={faFolder}/>
            ドキュメント
          </Nav.Link>
          <Nav.Link as={Link} to={"/report_dtl/" + loginUser.user_id + "/" + month}>
            <FontAwesomeIcon icon={faPen}/>
            作業日報
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Navbar.Collapse className="justify-content-end">
        <DropdownButton title={navDropDownUser} variant="nav" style={{width: "150px", marginTop: "0"}}>
          <NavDropdown.Item onClick={e => logout()}>ログアウト</NavDropdown.Item>
        </DropdownButton>
      </Navbar.Collapse>
    </Navbar>
  )
};

export default AppNav;
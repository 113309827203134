import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faCaretDown, faCaretUp, faFileDownload, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone
} from "react-bootstrap-table2-paginator";
import {Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import overlayFactory from 'react-bootstrap-table2-overlay';
import {amountFormatter, dateformatJPNYYYYMMDD, formatNumber, parseDate} from "./CMUtil";

export const MdDataTableFreezeHeader = ({
                                          keyField,
                                          listData,
                                          columns,
                                          loading,
                                          onTableChange,
                                          page,
                                          sizePerPage,
                                          totalSize,
                                          showPagenation,
                                          linkToAdd,
                                          noDataIndication,
                                          onClickLinkToDetail
                                        }) => {

  const init1 = useRef(true);

  const [paginationOption, setPaginationOption] = useState({totalSize: 0});
  const [data, setData] = useState([]);

  const headerColumns = [
    {
      dataField: 'book_no',
      text: '番号',
      classes: 'tbl-col link',
      sort: true,
      headerStyle: {width: '85px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <div><a onClick={() => onClickLinkToDetail(row.book_id)}>{cell ? cell : "未定"}</a></div>
      )
    },
    {
      dataField: 'book_name',
      text: '現場名',
      classes: 'tbl-col tbl-col-head-end',
      sort: true,
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title={cell}>
          {cell}
        </span>
      )
    }
  ];

  const dataColumns = [
    {
      dataField: 'doc_count',
      text: '',
      classes: 'tbl-col icon-link',
      headerStyle: {width: '80px'},
      formatter: (cell, row) => (
        <span className={cell > 0 ? "d-inline-block" : "d-none"} onClick={() => window.open("/pic_list/" + row.book_id)}>
            <FontAwesomeIcon icon={faCamera} size="1x"/>
        </span>
      )
    },
    {
      dataField: 'book_status',
      text: '進捗',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '80px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'const_name',
      text: '施工業者',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '230px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title={cell}>
          {cell}
        </span>
      )
    },
    {
      dataField: 'ext_design_names',
      text: '設計事務所',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '230px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title={cell}>
          {cell}
        </span>
      )
    },
    {
      dataField: 'str_design_names',
      text: '構造設計',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '230px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title={cell}>
          {cell}
        </span>
      )
    },
    {
      dataField: 'quot_book_id',
      text: '見積',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '80px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{cell ? '有' : '無'}</span>
      )
    },
    {
      dataField: 'estimate_amount',
      text: '受注金額',
      classes: 'tbl-col text-right',
      sort: true,
      headerStyle: {width: '140px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{row.quot_total_total ? row.quot_total_total : cell}</span>
      )
    },
    {
      dataField: 'book_address',
      text: '住所',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '350px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'category',
      text: '分類',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'main_usage',
      text: '主要用途',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'opn_area',
      text: '施工箇所',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'note1',
      text: '備考',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'note2',
      text: '変更・改修',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'note3',
      text: 'デベロッパ',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'pic_const',
      text: '担当者',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'pic_design',
      text: '設計担当者',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'start_date',
      text: '着工日',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '120px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      )
    },

    {
      dataField: 'complete_date',
      text: '完了日',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '120px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      )
    },
    {
      dataField: 'warranty_date',
      text: '保証書',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '120px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      )
    },
    {
      dataField: 'memo',
      text: '現場メモ',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'memo2',
      text: '設計メモ',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'special_note',
      text: '特記事項',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'remain_charge',
      text: '請求残額',
      classes: 'tbl-col text-right',
      headerStyle: {width: '150px'},
      formatter: (cell, row) => (
        <span>{formatNumber(cell)}</span>
      )
    }
  ];

  useEffect(() => {

    if (init1.current) {
      init1.current = false;
      return;
    }

    const iPaginationOption = {
      bootstrap4: true,
      page: page ? page : 1,
      sizePerPage: sizePerPage ? sizePerPage : 50,
      totalSize: listData ? listData.count : 0,
      paginationTotalRenderer: (from, to, size) => {
        return (
          <span className="react-bootstrap-table-pagination-total">
        全 {size} 件中 {from} ～ {to} 件表示
        </span>
        )
      },
      showTotal: true,
      withFirstAndLast: true,
      alwaysShowAllBtns: true,
      firstPageText: '<<',
      prePageText: '<',
      nextPageText: '>',
      lastPageText: '>>'
    };
    setPaginationOption(iPaginationOption);

    if (listData.results) {
      setData(listData.results);
    }
  }, [listData])

  const NoDataIndication = () => (
    <span>登録情報はありません</span>
  );

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOption)}>
        {
          ({
             paginationProps,
             paginationTableProps
           }) => (
            <div>
              <Row>
                {showPagenation &&
                  <Col sm="3" style={{marginTop: ".5rem"}}>
                    <PaginationTotalStandalone {...paginationProps} />
                  </Col>
                }
                {showPagenation &&
                  <Col sm="6" className="justify-content-md-center">
                    <PaginationListStandalone {...paginationProps} />
                  </Col>
                }
                {linkToAdd &&
                  <Col sm="3" className="text-right">
                    <Button as={Link} variant="add" to={linkToAdd}>
                      <FontAwesomeIcon icon={faPlusCircle} size="lg"/>
                      追加
                    </Button>
                  </Col>
                }
              </Row>
              <Row>
                <Col id="data-table-headers" sm="6" style={{maxWidth: "450px", paddingRight: "0px"}}>
                  <BootstrapTable
                    remote
                    responsive
                    keyField={keyField}
                    classes="dm-datatable"
                    striped={true}
                    data={data}
                    columns={headerColumns ? headerColumns : []}
                    onTableChange={onTableChange}
                    {...paginationTableProps}
                    loading={loading}
                    overlay={overlayFactory({
                      spinner: false,
                      styles: {overlay: (base) => ({...base, background: 'rgba(255, 255, 255, 0.5)'})}
                    })}
                    noDataIndication={() => <NoDataIndication/>}
                  />
                </Col>
                <Col id="data-table-data" sm="6" style={{paddingLeft: "0px"}}>
                  <BootstrapTable
                    remote
                    responsive
                    keyField={keyField}
                    classes="dm-datatable"
                    striped={true}
                    data={data}
                    columns={dataColumns}
                    onTableChange={onTableChange}
                    {...paginationTableProps}
                    loading={loading}
                    overlay={overlayFactory({
                      spinner: true,
                      styles: {overlay: (base) => ({...base, background: 'rgba(255, 255, 255, 0.5)'})}
                    })}
                    noDataIndication={() => <NoDataIndication/>}
                  />
                </Col>
              </Row>
              {showPagenation && <PaginationListStandalone {...paginationProps} />}
              {showPagenation && <PaginationTotalStandalone {...paginationProps} />}
            </div>
          )
        }
      </PaginationProvider>
    </>
  )
};

export default MdDataTableFreezeHeader;

export function sortCaret(order, column) {
  if (order === "asc") {
    return (
      <>
        <FontAwesomeIcon icon={faCaretUp} size="lg" style={{marginLeft: '5px'}}/>
      </>
    )
  } else if (order === "desc") {
    return (
      <>
        <FontAwesomeIcon icon={faCaretDown} size="lg" style={{marginLeft: '5px'}}/>
      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}

export const InitialLoading = ({loadingCss}) => {

  return (
    <div className={loadingCss}>
      <div className="_loading_overlay_spinner css-50etie">
        <svg viewBox="25 25 50 50">
          <circle style={{strokeWidth: "2", fill: "none", strokeMiterlimit: "10"}} cx="50" cy="50" r="20"></circle>
        </svg>
      </div>
    </div>
  )
}
import React, {useEffect, useImperativeHandle, useState} from "react";
import {Form, InputGroup} from "react-bootstrap";
import {checkKey, formatTimeHHMM, nvl, onFocusSelect, timeformatHHMM, toNull, toSingleByte} from "../CMUtil";
import {getAPI} from "../APIUtil";
import AsyncSelect from "react-select/async";

export const ScheduleDtl = React.forwardRef((props, ref) => {

    const [index, setIndex] = useState("");
    const [scheduleId, setScheduleId] = useState("");
    const [cancel, setCancel] = useState(false);
    const [opnDate, setOpnDate] = useState("");
    const [car1Id, setCar1Id] = useState("");
    const [car2Id, setCar2Id] = useState("");
    const [car3Id, setCar3Id] = useState("");
    const [bookId, setBookId] = useState("");
    const [bookAddress, setBookAddress] = useState("");
    const [opnId, setOpnId] = useState("");
    const [opnDetail, setOpnDetail] = useState("");
    const [depTime, setDepTime] = useState("");
    const [mtgStartTime, setMtgStartTime] = useState("");
    const [opnStartTime, setOpnStartTime] = useState("");
    const [note, setNote] = useState("");
    const [createType, setCreateType] = useState("");

    const [car1Selected, setCar1Selected] = useState({});
    const [car2Selected, setCar2Selected] = useState({});
    const [car3Selected, setCar3Selected] = useState({});
    const [bookSelected, setBookSelected] = useState({});
    const [opnSelected, setOpnSelected] = useState({});

    //INITIALIZE
    useEffect(() => {

      const update = async () => {

        setIndex(nvl(props.index));

        let schedule = props.schedule;
        setScheduleId(nvl(schedule.schedule_id));
        setCancel(schedule.cancel ? schedule.cancel : false);
        setOpnDate(schedule.opn_date);
        if (schedule.car_1) {
          setCar1Id(schedule.car_1.code_id);
          setCar1Selected([{id: schedule.car_1.code_id, name: schedule.car_1.code_name}]);
        }
        if (schedule.car_2) {
          setCar2Id(schedule.car_2.code_id);
          setCar2Selected([{id: schedule.car_2.code_id, name: schedule.car_2.code_name}]);
        }
        if (schedule.car_3) {
          setCar3Id(schedule.car_3.code_id);
          setCar3Selected([{id: schedule.car_3.code_id, name: schedule.car_3.code_name}]);
        }
        if (schedule.book) {
          setBookId(schedule.book.book_id);
          setBookSelected([{
            id: schedule.book.book_id,
            name: schedule.book.book_name,
            book_no: nvl(schedule.book.book_no)
          }]);
          setBookAddress(nvl(schedule.book.pref) + nvl(schedule.book.city));
        }
        if (schedule.opn) {
          setOpnId(schedule.opn.code_id);
          setOpnSelected([{id: schedule.opn.code_id, name: schedule.opn.code_name}]);
        }
        setOpnDetail(nvl(schedule.opn_detail));
        setDepTime(nvl(timeformatHHMM(schedule.dep_time)));
        setMtgStartTime(nvl(timeformatHHMM(schedule.mtg_start_time)));
        setOpnStartTime(nvl(timeformatHHMM(schedule.opn_start_time)));
        setNote(nvl(schedule.note));
        setCreateType(nvl(schedule.create_type));
      }
      update();
    }, []);

    // useEffect(() => {
    //
    // }, [props.reportList]);


    const onSearchCar = async (query) => {

      let locOptions = [];
      props.carList.map((l) => {
        if (l.name.indexOf(query) > -1) {
          locOptions.push(l);
        }
      });

      return locOptions;
    }

    const handleChangeCar1 = (value) => {
      setCar1Selected(value);
      setCar1Id(value.id);
    }

    const handleChangeCar2 = (value) => {
      setCar2Selected(value);
      setCar2Id(value.id);
    }

    const handleChangeCar3 = (value) => {
      setCar3Selected(value);
      setCar3Id(value.id);
    }

    const onSearchOpn = async (query) => {

      let locOptions = [];
      props.opnList.map((l) => {
        if (l.name.indexOf(query) > -1) {
          locOptions.push(l);
        }
      });

      return locOptions;
    }

    const handleChangeOpn = (value) => {
      setOpnSelected(value);
      setOpnId(value.id);
    }

    const onSearchBook = async (query) => {
      let searchCondition = {
        name: query
      }
      const res = await getAPI("tr_book_typeahead_view", searchCondition);
      return res.results.map((result) => ({
        name: result.book_name,
        id: result.book_id,
        book_no: nvl(result.book_no)
      }));
    }

    const handleChangeBook = async (value) => {
      setBookSelected(value);
      setBookId(value.id);
      let obj = await getAPI("tr_book/" + value.id);
      setBookAddress(nvl(obj.pref) + nvl(obj.city));
    }

    const clearnOpnDetail = () => {
      setOpnDetail(toSingleByte(opnDetail));
    }

    const postSetDepTime = (value, index) => {
      setDepTime(value);
      let checkValue = value.replace(":", "");
      if (checkValue.length >= 4) {
        $("#mtg_start_time_" + index)[0].focus();
      }
    }

    const postMtgStartTime = (value, index) => {
      setMtgStartTime(value);
      let checkValue = value.replace(":", "");
      if (checkValue.length >= 4) {
        $("#opn_start_time_" + index)[0].focus();
      }
    }

    const postOpnStartTime = (value, index) => {
      setOpnStartTime(value);
      let checkValue = value.replace(":", "");
      if (checkValue.length >= 4) {
        let id = "#dep_time_" + (index + 1);
        if ($(id).length === 1) {
          $(id)[0].focus();
        }
      }
    }

    useImperativeHandle(ref, () => ({
      getStates: () => {

        return {
          index: toNull(index),
          schedule_id: toNull(scheduleId),
          opn_date: toNull(opnDate),
          book_id: toNull(bookId),
          car_1_id: toNull(car1Id),
          car_2_id: toNull(car2Id),
          car_3_id: toNull(car3Id),
          opn_id: toNull(opnId),
          opn_detail: toNull(opnDetail),
          dep_time: toNull(depTime),
          mtg_start_time: toNull(mtgStartTime),
          opn_start_time: toNull(opnStartTime),
          note: toNull(note),
          create_type: toNull(createType),
          cancel: cancel,
          create_pg: 'ScheduleDtl',
          update_pg: 'ScheduleDtl'
        };
      }
    }), [
      scheduleId,
      opnDate,
      bookId,
      car1Id,
      car2Id,
      car3Id,
      opnId,
      opnDetail,
      note,
      depTime,
      mtgStartTime,
      opnStartTime
    ]);

    return (
      <div style={{paddingRight: "0rem", paddingLeft: "0.3rem"}}>
        <div>
          <table style={{width: "100%"}}>
            <tbody>
            <tr>
              <th style={{width: "15%", textAlign: "center"}}>車</th>
              <th style={{textAlign: "center"}}>現場／作業</th>
              <th style={{width: "15%", textAlign: "center"}}>施工内容</th>
              <th style={{width: "15%", textAlign: "center"}}>その他</th>
              <th style={{width: "13%", textAlign: "center"}}>手配</th>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table style={{width: "100%"}}>
            <tbody>
            <tr>
              <td style={{width: "15%"}}>
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={car1Selected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchCar}
                  onChange={handleChangeCar1}
                  isDisabled={cancel}
                />
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={car2Selected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchCar}
                  onChange={handleChangeCar2}
                  placeholder=""
                  isDisabled={cancel}
                />
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={car3Selected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchCar}
                  onChange={handleChangeCar3}
                  placeholder=""
                  isDisabled={cancel}
                />
              </td>
              <td>
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={bookSelected}
                  getOptionLabel={e => Object.keys(e).length === 0 ? "" : `${e.book_no} ${e.name}`}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchBook}
                  onChange={handleChangeBook}
                  placeholder=""
                  isDisabled={cancel}
                />
                <Form.Control plaintext readOnly value={bookAddress} style={{paddingLeft: "0.7rem"}} disabled={cancel}/>
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={opnSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchOpn}
                  onChange={handleChangeOpn}
                  placeholder=""
                  isDisabled={cancel}
                />
              </td>
              <td style={{width: "15%"}}>
                <Form.Control as="textarea" style={{height: "106px", resize: "none"}} defaultValue={opnDetail}
                              onChange={e => setOpnDetail(e.target.value)}
                              onBlur={e => clearnOpnDetail()}
                              disabled={cancel}/>
              </td>
              <td style={{width: "15%"}} className="text-right">
                <Form.Control as="textarea" style={{height: "106px", resize: "none"}} defaultValue={note}
                              onChange={e => setNote(e.target.value)} disabled={cancel}/>
              </td>
              <td style={{width: "13%"}}>
                <InputGroup style={{borderBottom: "none"}}>
                  <Form.Control
                    className="dep_time"
                    id={"dep_time_" + index}
                    type="tel"
                    value={depTime}
                    onKeyPress={e => checkKey(e, /[0-9\:]/)}
                    onFocus={e => onFocusSelect(e)}
                    onChange={e => postSetDepTime(e.target.value, index)}
                    onBlur={e => setDepTime(formatTimeHHMM(e.target.value))}
                    style={{border: "1px solid #cccccc", textAlign: "center", imeMode: "inactive"}}
                    disabled={cancel}
                  />
                  <InputGroup.Text style={{border: "1px solid #cccccc", fontWeight: "700", borderRadius: "0"}}
                                   className={cancel ? "input-group-text-disabled" : ""}>集</InputGroup.Text>
                </InputGroup>
                <InputGroup style={{borderBottom: "none"}}>
                  <Form.Control
                    className="mtg_start_time"
                    id={"mtg_start_time_" + index}
                    type="tel"
                    value={mtgStartTime}
                    onKeyPress={e => checkKey(e, /[0-9\:]/)}
                    onFocus={e => onFocusSelect(e)}
                    onChange={e => postMtgStartTime(e.target.value, index)}
                    onBlur={e => setMtgStartTime(formatTimeHHMM(e.target.value))}
                    style={{border: "1px solid #cccccc", textAlign: "center"}}
                    disabled={cancel}/>
                  <InputGroup.Text style={{border: "1px solid #cccccc", fontWeight: "700", borderRadius: "0"}}
                                   className={cancel ? "input-group-text-disabled" : ""}>朝</InputGroup.Text>
                </InputGroup>
                <InputGroup style={{borderBottom: "none"}}>
                  <Form.Control
                    className="opn_start_time"
                    id={"opn_start_time_" + index}
                    type="tel"
                    value={opnStartTime}
                    onKeyPress={e => checkKey(e, /[0-9\:]/)}
                    onFocus={e => onFocusSelect(e)}
                    onChange={e => postOpnStartTime(e.target.value, index)}
                    onBlur={e => setOpnStartTime(formatTimeHHMM(e.target.value))}
                    style={{border: "1px solid #cccccc", textAlign: "center"}}
                    disabled={cancel}/>
                  <InputGroup.Text style={{border: "1px solid #cccccc", fontWeight: "700", borderRadius: "0"}}
                                   className={cancel ? "input-group-text-disabled" : ""}>打</InputGroup.Text>
                </InputGroup>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
);

export default React.memo(ScheduleDtl);
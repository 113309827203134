import {withRouter} from "react-router";
import React, {useEffect, useRef, useState} from 'react';
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import {Card, Col, Container, Form, InputGroup, Row, Table} from "react-bootstrap";
import {
  addField,
  dateformat, dateformatDB, dateformatJPNByType,
  dateformatYYYYMMDD,
  setFocusCalendar,
  useDebounce
} from "../CMUtil";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faInfoCircle, faPenNib, faPenSquare} from "@fortawesome/free-solid-svg-icons";
import {getAPI} from "../APIUtil";

export const BookStatus = () => {

  const iniSearch = useRef(true);
  const [locPage, setLocPage] = useState(null);

  const [bookNo, setBookNo] = useState("");
  const debouncedBookNo = useDebounce(bookNo, 500, setLocPage);
  const [dateFrom, setDateFrom] = useState(function () {
    let locDateFrom = new Date();
    locDateFrom.setMonth(locDateFrom.getMonth() - 1);
    locDateFrom.setDate(1);
    return locDateFrom;
  });
  const debouncedDateFrom = useDebounce(dateFrom, 500, setLocPage);
  const [dateTo, setDateTo] = useState(function () {
    let locDateTo = new Date();
    locDateTo.setMonth(locDateTo.getMonth() + 2);
    locDateTo.setDate(1);
    locDateTo.setDate(locDateTo.getDate() - 1);
    return locDateTo;
  });
  const debouncedDateTo = useDebounce(dateTo, 500, setLocPage);
  const [today, setToday] = useState(dateformatDB(new Date()));

  const [completeDateFrom, setCompleteDateFrom] = useState("");
  const debouncedCompleteDateFrom = useDebounce(completeDateFrom, 500, setLocPage);
  const [completeDateTo, setCompleteDateTo] = useState("");
  const debouncedCompleteDateTo = useDebounce(completeDateTo, 500, setLocPage);

  const [statusBefore, setStatusBefore] = useState(false);
  const debouncedStatusBefore = useDebounce(statusBefore, 500, setLocPage);
  const [statusGoing, setStatusGoing] = useState(true);
  const debouncedStatusGoing = useDebounce(statusGoing, 500, setLocPage);
  const [statusDelay, setStatusDelay] = useState(true);
  const debouncedStatusDelay = useDebounce(statusDelay, 500, setLocPage);
  const [statusComplete, setStatusComplete] = useState(true);
  const debouncedStatusComplete = useDebounce(statusComplete, 500, setLocPage);
  const [statusCancel, setStatusCancel] = useState(false);
  const debouncedStatusCancel = useDebounce(statusCancel, 500, setLocPage);

  const [qoGrandTtl, setQoGrandTtl] = useState("");
  const [coGrandTtl, setCoGrandTtl] = useState("");
  const [invGrandTtl, setInvGrandTtl] = useState("");
  const [deGrandTtl, setDeGrandTtl] = useState("");

  const [listData, setListData] = useState([]);
  const [dateList, setDateList] = useState([]);

  // 初期化
  useEffect(() => {

    const update = async () => {
      await searchBookStatus();
    }

    update();

  }, []);

  //検索
  useEffect(() => {

      if (iniSearch.current) {
        iniSearch.current = false;
        return;
      }
      const update = async () => {
        await searchBookStatus();
      }

      update();
    },
    [
      debouncedBookNo,
      debouncedDateFrom,
      debouncedDateTo,
      debouncedCompleteDateFrom,
      debouncedCompleteDateTo,
      debouncedStatusBefore,
      debouncedStatusGoing,
      debouncedStatusDelay,
      debouncedStatusComplete,
      debouncedStatusCancel
    ]);

  const onClickClear = () => {
    setBookNo("");

    let locDateFrom = new Date();
    locDateFrom.setMonth(locDateFrom.getMonth() - 1);
    locDateFrom.setDate(1);
    setDateFrom(locDateFrom);

    let locDateTo = new Date();
    locDateTo.setMonth(locDateTo.getMonth() + 2);
    locDateTo.setDate(1);
    locDateTo.setDate(locDateTo.getDate() - 1);
    setDateTo(locDateTo);

    setCompleteDateFrom("");
    setCompleteDateTo("");

    setStatusBefore(false);
    setStatusGoing(true);
    setStatusDelay(true);
    setStatusComplete(true);
    setStatusCancel(false);
  }

  const searchBookStatus = async () => {

    let condition = {};
    addField(condition, "book_no", bookNo);
    addField(condition, "date_from", dateformat(dateFrom));
    addField(condition, "date_to", dateformat(dateTo));
    addField(condition, "complete_date_from", dateformat(completeDateFrom));
    addField(condition, "complete_date_to", dateformat(completeDateTo));
    let status = []
    if (statusBefore) {
      status.push("着工前");
    }
    if (statusGoing) {
      status.push("工事中");
    }
    if (statusDelay) {
      status.push("遅延");
    }
    if (statusComplete) {
      status.push("完了");
    }
    if (statusCancel) {
      status.push("中止");
    }
    addField(condition, "status", status.join(","));
    const res = await getAPI("tr_book_status_search_view", condition);
    const resTtl = await getAPI("tr_book_status_grand_total_search_view", condition);

    setListData(res.results);
    setQoGrandTtl(resTtl.qo_ttl_amount);
    setCoGrandTtl(resTtl.co_ttl_amount);
    setInvGrandTtl(resTtl.iv_ttl_amount);
    setDeGrandTtl(resTtl.de_ttl_amount);

    let locDateList = [];
    let countDate = new Date(dateFrom.getTime());
    while (dateformatYYYYMMDD(countDate) <= dateformatYYYYMMDD(dateTo)) {
      locDateList.push(new Date(countDate.getTime()));
      countDate.setDate(countDate.getDate() + 1);
    }
    setDateList(locDateList);
  }

  const isLastDateInMonth = (calendarDate) => {
    let date = new Date(calendarDate);
    date.setDate(date.getDate() + 1);
    return date.getDate() === 1;
  }

  const getBookStatus = (dbStatus) => {

    if (dbStatus === '工事中') {
      return "sts_going";
    } else if (dbStatus === '着工前') {
      return "sts_schedule";
    } else if (dbStatus === '遅延') {
      return "sts_delay";
    } else if (dbStatus === '完了') {
      return "sts_complete";
    }
  }

  const getCalStatus = (startDateSchedule, startDate, completeDateSchedule, completeDate, calendarDate) => {

    calendarDate = dateformatDB(calendarDate);

    if (startDate && startDate <= calendarDate && completeDate && calendarDate <= completeDate) {
      return "sts_wk";
    }
    if (startDate && startDate <= calendarDate && !completeDate && calendarDate <= today) {
      return "sts_wk";
    }

    if (startDateSchedule && startDateSchedule <= calendarDate && completeDateSchedule && calendarDate <= completeDateSchedule) {
      return "sts_sh";
    }
    if (startDateSchedule && startDateSchedule <= calendarDate && !completeDateSchedule) {
      return "sts_sh";
    }

    return "sts_off";
  }

  const setSearchCompleteDateFrom = (value) => {
    if (value) {
      setDateFrom(value);
    }
    setCompleteDateFrom(value);
  }

  const setSearchCompleteDateTo = (value) => {
    if (value) {
      setDateTo(value);
    }
    setCompleteDateTo(value);
  }

  return (
    <div>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <Row className="h1">
          <Col sm={8} style={{paddingTop: "7px", paddingBottom: "7px"}}>
            <h1>
              現場別予定表
            </h1>
          </Col>
          <Col sm="4" className="text-right" style={{position: "fixed", zIndex: "10", right: "0"}}>
          </Col>
        </Row>

        <Card className="card-search">
          <Container className="container-main-search">
            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm="1" className="text-right">現場</Form.Label>
              <Col sm="2">
                <Form.Control type="text" value={bookNo} onChange={e => setBookNo(e.target.value)}/>
              </Col>
              <Form.Label column sm="1" className="text-right">工事期間</Form.Label>
              <Col sm="4" className="input-group">
                <DatePicker id="dateFrom" selected={dateFrom} onChange={(date) => setDateFrom(date)}
                            dateFormat="yyyy年MM月dd日" locale="ja"/>
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'dateFrom')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
                <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
                <DatePicker id="dateTo" selected={dateTo} onChange={(date) => setDateTo(date)}
                            dateFormat="yyyy年MM月dd日" locale="ja"/>
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'dateTo')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
              </Col>
              <Col className="text-right" sm="4">
                <Button variant="clear" onClick={e => onClickClear()}>条件クリア</Button>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2">
              <Col sm="3">
              </Col>
              <Form.Label column sm="1" className="text-right">完了日</Form.Label>
              <Col sm="4" className="input-group">
                <DatePicker id="completeDateFrom" selected={completeDateFrom}
                            onChange={(date) => setSearchCompleteDateFrom(date)}
                            dateFormat="yyyy年MM月dd日" locale="ja"/>
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'completeDateFrom')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
                <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
                <DatePicker id="completeDateTo" selected={completeDateTo}
                            onChange={(date) => setSearchCompleteDateTo(date)}
                            dateFormat="yyyy年MM月dd日" locale="ja"/>
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'completeDateTo')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1" className="text-right">状況</Form.Label>
              <Col sm="11">
                <Form.Check
                  inline
                  id="status_before"
                  type="checkbox"
                  className="ml-3"
                  label="着工前"
                  onChange={e => {
                    setStatusBefore(!statusBefore)
                  }}
                  checked={statusBefore}
                />
                <Form.Check
                  inline
                  id="status_going"
                  type="checkbox"
                  className="ml-3"
                  label="工事中"
                  onChange={e => {
                    setStatusGoing(!statusGoing)
                  }}
                  checked={statusGoing}/>
                <Form.Check
                  inline
                  id="status_delay"
                  type="checkbox"
                  className="ml-3"
                  label="遅延"
                  onChange={e => {
                    setStatusDelay(!statusDelay)
                  }}
                  checked={statusDelay}/>
                <Form.Check
                  inline
                  id="status_complete"
                  type="checkbox"
                  className="ml-3"
                  label="完了"
                  onChange={e => {
                    setStatusComplete(!statusComplete)
                  }}
                  checked={statusComplete}/>
                <Form.Check
                  inline
                  id="status_cancel"
                  type="checkbox"
                  className="ml-3"
                  label="中止"
                  onChange={e => {
                    setStatusCancel(!statusCancel)
                  }}
                  checked={statusCancel}/>
              </Col>
            </Form.Group>

          </Container>
        </Card>

        <Container className="container-main-list">
          <Table className="tbl_status" style={{width: (1060 + (dateList.length * 25)) + "px"}}>
            <thead>
            <tr>
              <td className="tbl-h-00">
                <Table className="tbl_status_inner">
                  <tbody>
                  <tr>
                    <td style={{width: "80px", textAlign: "center"}}>工事No.</td>
                    <td>現場</td>
                    <td style={{width: "115px", textAlign: "right"}}>受注金額</td>
                    <td style={{width: "115px", textAlign: "right"}}>工事金額</td>
                    <td style={{width: "115px", textAlign: "right"}}>請求金額</td>
                    <td style={{width: "115px", textAlign: "right"}}>入金金額</td>
                    <td style={{width: "110px", textAlign: "center"}}>着工日</td>
                    <td style={{width: "110px", textAlign: "center"}}>完了日</td>
                    <td style={{width: "60px", textAlign: "center", borderRight: "solid 1px #ced4da"}}>状況</td>
                  </tr>
                  <tr>
                    <td style={{textAlign: "center"}}></td>
                    <td style={{textAlign: "right", padding : "3px 0px", color:"#000"}}>合計：</td>
                    <td style={{textAlign: "right", padding : "3px 2px 3px 0px" ,color:"#000"}}>{qoGrandTtl}</td>
                    <td style={{textAlign: "right", padding : "3px 2px 3px 0px" ,color:"#000"}}>{coGrandTtl}</td>
                    <td style={{textAlign: "right", padding : "3px 2px 3px 0px" ,color:"#000"}}>{invGrandTtl}</td>
                    <td style={{textAlign: "right", padding : "3px 2px 3px 0px" ,color:"#000"}}>{deGrandTtl}</td>
                    <td style={{textAlign: "center"}}></td>
                    <td style={{textAlign: "center"}}></td>
                    <td style={{textAlign: "center", borderRight: "solid 1px #ced4da"}}></td>
                  </tr>
                  </tbody>
                </Table>
              </td>
              {dateList.map(date => (
                <td key={"h_" + dateformatYYYYMMDD(date)}
                    className={"tbl-h-10 col-chart text-center " + isLastDateInMonth(date)}
                >{dateformatJPNByType(date, 'date')}</td>
              ))}
            </tr>
            </thead>

            <tbody>
            {listData.map((obj, index) => (
              <tr key={index}>
                <td className={"tbl-h-01 " + (listData.length === index + 1 ? "tbl-h-01-b" : "")}>
                  <Table className="tbl_status_inner">
                    <tbody>
                    <tr>
                      <td className="a">
                        <Button as={Link} variant="link" to={"/book/" + obj.book_id + "/1/"}>
                          {obj.book_no}
                        </Button>
                      </td>
                      <td>{obj.book_name}</td>
                      <td className="b">
                        {obj.qo_ttl_amount !== '―' ? obj.qo_ttl_amount : obj.estimate_amount}
                        {obj.qo_ttl_amount === '―' && obj.estimate_amount !== '―' &&
                          <div className="d-inline-block w-100" tabIndex="0" data-toggle="tooltip" title={"概算金額"}>
                            <FontAwesomeIcon icon={faPenNib} size="1x" style={{color: "#0067a2"}}/>
                          </div>
                        }
                      </td>
                      <td className="b">
                        {obj.co_ttl_amount}
                        {obj.co_text &&
                          <div className="d-inline-block w-100" tabIndex="0" data-toggle="tooltip" title={obj.co_text}>
                            <FontAwesomeIcon icon={faInfoCircle} size="1x" style={{color: "#0067a2"}}/>
                          </div>
                        }
                      </td>
                      <td className="b">{obj.iv_ttl_amount}</td>
                      <td className="b">{obj.de_ttl_amount}</td>
                      <td className="c">
                        <div style={{textAlign: "center"}}>
                          {obj.start_date_schedule_str}
                        </div>
                        <div style={{textAlign: "center"}}>
                          {obj.start_date_str}
                        </div>
                      </td>
                      <td className="c">
                        <div style={{textAlign: "center"}}>
                          {obj.complete_date_schedule_str}
                        </div>
                        <div style={{textAlign: "center"}}>
                          {obj.complete_date_str}
                        </div>
                      </td>
                      <td className="d">
                        {obj.status}
                      </td>
                    </tr>
                    </tbody>
                  </Table>
                </td>
                {dateList.map(date => (
                  <td key={"b_" + obj.book_id + "_" + dateformatYYYYMMDD(date)}
                      className="tbl-b-00"
                  >
                    <div
                      className={
                        "book-status-chart" +
                        " " + getBookStatus(obj.status) +
                        " " + getCalStatus(obj.start_date_schedule, obj.start_date, obj.complete_date_schedule, obj.complete_date, date)
                      }
                    >&nbsp;</div>
                  </td>
                ))}
              </tr>
            ))}
            </tbody>
          </Table>
          {/*</div>*/}
        </Container>
      </div>
    </div>
  );
}

export default withRouter(BookStatus);
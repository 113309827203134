import React from "react";
import { toast, ToastContainer } from "react-toastify";

export const Toast = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
    />
  )
};

export default Toast;

export const success = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored"
  });
}

export const error = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored"
  });
}

/**
 * @param {Array} response django検証応答
 * @param {any} lookup フィールドの表示テキストへのマッピング 
 */
export const validationError = (response, lookup) => {
  const errors = [];
  for (const [key, fieldErrors] of Object.entries(response)) {
    fieldErrors.forEach(fieldError => {
      errors.push(
        <div key={key + fieldError}>
          {lookup[key]}: <small>{fieldError}</small>
        </div>
      );
    });
  }

  toast.error(
    <>{
      errors.length > 0 ?
        errors :
        "サーバーエラーが発生しました。"
    }</>,
    {
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored"
    });
}
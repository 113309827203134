import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import {Card, Col, Container, Form, InputGroup, Nav, Row} from "react-bootstrap";
import {deleteAPI, downloadAPI, getAPI, postAPI, putAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import BookNav from "./BookNav";
import MdDatatable from "../MdDataTable";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faFile, faMinusCircle, faPen, faPlusCircle, faQrcode} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import {
  amountFormatter,
  dateformatDB,
  finishLoadingBtn,
  formatNumber,
  nvl,
  parseDate,
  qtyFormatetr,
  setFocusCalendar,
  startLoadingBtn,
  toNull,
  toSingleByte
} from "../CMUtil";

export const InvDtl = ({match: {params: {p_inv_id}}}) => {

  const hist = useHistory();
  const location = useLocation();

  const iniInvId = useRef(true);

  const [updateCount, setUpdateCount] = useState(0);

  const [invId, setInvId] = useState("");
  const [bookId, setBookId] = useState("");
  const [bookNo, setBookNo] = useState("");
  const [quotId, setQuotId] = useState("");
  const [ver, setVer] = useState("");

  const [resultChargeId, setResultChargeId] = useState("");

  const [comName, setComName] = useState("");
  const [comZip, setComZip] = useState("");
  const [comTel, setComTel] = useState("");
  const [comAddr, setComAddr] = useState("");

  const [lastUpdateDate, setLastUpdateDate] = useState("");
  const [constName, setConstName] = useState("");

  const [bookName, setBookName] = useState("");
  const [bookPref, setBookPref] = useState("");
  const [bookCity, setBookCity] = useState("");
  const [bookAddress, setBookAddress] = useState("");

  const [constAddress, setConstAddress] = useState("");
  const [totalAmount, setTotalAmount] = useState("");

  const [orderNo, setOrderNo] = useState("");
  const [constNo, setConstNo] = useState("");

  const [comAcc1, setComAcc1] = useState("");
  const [comAcc2, setComAcc2] = useState("");

  const invColumns = [
    {
      dataField: 'id',
      text: '',
      headerStyle: {width: '50px'},
    },
    {
      dataField: 'opn_detail',
      text: '施工箇所及び施工内容',
      classes: 'tbl-col tbl-col-editable',
      editCellClasses: 'tbl-col-editing'
    },
    {
      dataField: 'qty',
      text: '数量',
      classes: 'tbl-col tbl-col-editable',
      align: 'right',
      headerStyle: {width: '85px'},
      editCellClasses: 'tbl-col-editing tbl-col-editing-number',
      formatter: (cell, row) => {
        return qtyFormatetr(cell, row, 1);
      }
    },
    {
      dataField: 'unit',
      text: '単位',
      classes: 'tbl-col tbl-col-editable',
      align: 'center',
      headerStyle: {width: '85px'},
      editCellClasses: 'tbl-col-editing tbl-col-editing-number',
    },
    {
      dataField: 'price',
      text: '単価',
      classes: 'tbl-col tbl-col-editable',
      align: 'right',
      headerStyle: {width: '85px'},
      editCellClasses: 'tbl-col-editing tbl-col-editing-number',
      formatter: amountFormatter,
    },
    {
      dataField: 'amount',
      text: '金額',
      classes: 'tbl-col tbl-col-primary',
      align: 'right',
      headerStyle: {width: '85px'},
      editCellClasses: 'tbl-col-editing',
      formatter: amountFormatter,
    },
    {
      dataField: 'note',
      text: '備考',
      headerClasses: 'tbl-col-5',
      classes: 'tbl-col tbl-col-editable',
      align: 'right',
      headerStyle: {width: '85px'},
      editCellClasses: 'tbl-col-editing',
    },
  ];
  const [invList, setInvList] = useState([]);
  const rightColumns = [
    {
      dataField: 'id',
      text: '',
      hidden: true
    },
    {
      dataField: 'col_0',
      text: '　',
      classes: 'tbl-col-btn',
      align: 'center',
      headerStyle: {width: '6rem'},
      formatter: (cell, row) => {
        return (
          <>
            <Button variant="add" style={{
              width: "2.5rem",
              height: "2.2rem",
              margin: "0rem 0.5rem 0rem 0.05rem",
              borderRadius: "0.5rem"
            }}
                    onClick={e => onClickAddRow(e, row)}>
              <FontAwesomeIcon icon={faPlusCircle} size="2x"/>
            </Button>
            <Button variant="add" style={{
              width: "2.5rem",
              height: "2.2rem",
              margin: "0rem 0.05rem 0rem 0.05rem",
              borderRadius: "0.5rem"
            }}
                    onClick={e => onClickDeleteRow(e, row)}>
              <FontAwesomeIcon icon={faMinusCircle} size="2x"/>
            </Button>
          </>
        );
      }
    },
  ];

  const [invAdditionList, setInvAdditionList] = useState([]);
  const invAdditionColumns = [
    {
      dataField: 'id',
      text: '',
      headerStyle: {width: '50px'},
    },
    {
      dataField: 'opn_detail',
      text: '',
      classes: 'tbl-col tbl-col-editable',
      editCellClasses: 'tbl-col-editing'
    },
    {
      dataField: 'qty',
      text: '',
      classes: 'tbl-col tbl-col-editable',
      align: 'right',
      headerStyle: {width: '85px'},
      editCellClasses: 'tbl-col-editing tbl-col-editing-number',
      formatter: (cell, row) => {
        return qtyFormatetr(cell, row, 1);
      }
    },
    {
      dataField: 'unit',
      text: '',
      classes: 'tbl-col tbl-col-editable',
      align: 'center',
      headerStyle: {width: '85px'},
      editCellClasses: 'tbl-col-editing tbl-col-editing-number',
    },
    {
      dataField: 'price',
      text: '',
      classes: 'tbl-col tbl-col-editable',
      align: 'right',
      headerStyle: {width: '85px'},
      editCellClasses: 'tbl-col-editing tbl-col-editing-number',
      formatter: amountFormatter,
    },
    {
      dataField: 'amount',
      text: '',
      classes: 'tbl-col tbl-col-primary',
      align: 'right',
      headerStyle: {width: '85px'},
      editCellClasses: 'tbl-col-editing',
      formatter: amountFormatter,
    },
    {
      dataField: 'note',
      text: '',
      headerClasses: 'tbl-col-5',
      classes: 'tbl-col tbl-col-editable',
      align: 'right',
      headerStyle: {width: '85px'},
      editCellClasses: 'tbl-col-editing',
    },
  ];

  const [rightList, setRightList] = useState([]);

  //初期化
  useEffect(() => {
    const update = async () => {

      if (p_inv_id !== "new") {
        setInvId(p_inv_id);
      }
      setUpdateCount(updateCount + 1);

    }
    update();

  }, []);

  useEffect(() => {

    const update = async () => {

      if (iniInvId.current) {
        iniInvId.current = false;
        return;
      }

      let res = null;
      let searchCondition = null;

      //企業情報
      res = await getAPI("ms_code/COM_NAME/");
      if (res) {
        setComName(res.val_1);
      }
      res = await getAPI("ms_code/COM_ADDR/");
      if (res) {
        setComAddr(res.val_1);
      }
      res = await getAPI("ms_code/COM_ZIP/");
      if (res) {
        setComZip(res.val_1);
      }
      res = await getAPI("ms_code/COM_TEL/");
      if (res) {
        setComTel(res.val_1);
      }
      res = await getAPI("ms_code/COM_ACC_1/");
      if (res) {
        setComAcc1(res.val_1);
      }
      res = await getAPI("ms_code/COM_ACC_2/");
      if (res) {
        setComAcc2(res.val_1);
      }

      let locInvList = [];
      let locInvAdditionList = [];
      let inv = null;
      let book = null;
      let quot = null;
      let orderNo = "";
      let resultChargeId = null;
      let locLastUpdateDate = "";
      let locTotal = "";
      if (p_inv_id === "new") {

        //初回表示
        let query = new URLSearchParams(location.search);
        let rcObj = await getAPI("tr_result_charge/" + query.get("result_charge_id"));
        let rObj = await getAPI("tr_result/" + rcObj.result.result_id);

        for (let i = 1; i <= 50; i++) {
          if (!rcObj["qty_" + i] && !rcObj["amount_" + i]) {
            //数量未入力は表示しない
            continue;
          }

          locInvList.push(
            {
              id: i,
              opn_detail: rObj["opn_detail_" + i],
              qty: rcObj["qty_" + i],
              unit: rObj["unit_" + i],
              price: rObj["price_" + i],
              amount: rcObj["amount_" + i],
              note: null
            }
          );
        }

        // 小計
        locInvAdditionList.push({
          "no": 0,
          "column_name": "sub_total",
          "opn_detail": "小　計",
          "qty": null,
          "unit": null,
          "price": null,
          "amount": rcObj.sub_total,
        });
        // 諸経費
        if (rcObj.expense) {
          locInvAdditionList.push({
            "no": 1,
            "column_name": "expense",
            "opn_detail": "諸経費",
            "qty": 1,
            "unit": "式",
            "price": null,
            "amount": rcObj.expense,
          });
        }
        // 交通宿泊費
        if (rcObj.transport) {
          locInvAdditionList.push({
            "no": 2,
            "column_name": "transport",
            "opn_detail": "交通宿泊費",
            "qty": null,
            "unit": null,
            "price": null,
            "amount": rcObj.transport,
          });
        }
        // 出精値引き
        if (rcObj.discount) {
          locInvAdditionList.push({
            "no": 3,
            "column_name": "discount",
            "opn_detail": "出精値引き",
            "qty": null,
            "unit": null,
            "price": null,
            "amount": rcObj.discount,
          });
        }
        // 法定福利費
        if (rcObj.benefits) {
          locInvAdditionList.push({
            "no": 4,
            "column_name": "benefits",
            "opn_detail": "法定福利費",
            "qty": 1,
            "unit": "式",
            "price": null,
            "amount": rcObj.benefits,
          });
        }
        // 合計
        locInvAdditionList.push({
          "no": 5,
          "column_name": "total",
          "opn_detail": "合　計",
          "qty": null,
          "unit": null,
          "price": null,
          "amount": rcObj.total,
        });

        book = rObj.book;
        quot = rObj.quot;
        orderNo = rObj.order_no;
        resultChargeId = rcObj.result_charge_id;

      } else {

        //２回目以降表示（登録データ表示）
        let invObj = await getAPI("tr_inv/" + p_inv_id);
        for (let i = 1; i <= 50; i++) {
          locInvList.push(
            {
              id: i,
              opn_detail: invObj["opn_detail_" + i],
              qty: invObj["qty_" + i],
              unit: invObj["unit_" + i],
              price: invObj["price_" + i],
              amount: invObj["amount_" + i],
              note: invObj["note_" + i]
            }
          );
        }

        // 諸経費
        if (invObj.expense) {
          locInvAdditionList.push({
            "no": 0,
            "column_name": "expense",
            "opn_detail": "諸経費",
            "qty": 1,
            "unit": "式",
            "price": null,
            "amount": invObj.expense,
          });
        }
        // 交通宿泊費
        if (invObj.transport) {
          locInvAdditionList.push({
            "no": 1,
            "column_name": "transport",
            "opn_detail": "交通宿泊費",
            "qty": null,
            "unit": null,
            "price": null,
            "amount": invObj.transport,
          });
        }
        // 出精値引き
        if (invObj.discount) {
          locInvAdditionList.push({
            "no": 2,
            "column_name": "discount",
            "opn_detail": "出精値引き",
            "qty": null,
            "unit": null,
            "price": null,
            "amount": invObj.discount,
          });
        }
        // 小計
        locInvAdditionList.push({
          "no": 3,
          "column_name": "sub_total",
          "opn_detail": "小　計",
          "qty": null,
          "unit": null,
          "price": null,
          "amount": invObj.sub_total,
        });
        // 法定福利費
        if (invObj.benefits) {
          locInvAdditionList.push({
            "no": 4,
            "column_name": "opn_detail",
            "opn_detail": "法定福利費",
            "qty": 1,
            "unit": "式",
            "price": null,
            "amount": invObj.benefits,
          });
        }
        // // 値引き
        // if (invObj.discount) {
        //   locInvAdditionList.push({
        //     "no": 3,
        //     "column_name": "discount",
        //     "opn_detail": "値引き",
        //     "qty": null,
        //     "unit": null,
        //     "price": null,
        //     "amount": invObj.discount,
        //   })
        // }
        // 合計
        locInvAdditionList.push({
          "no": 6,
          "column_name": "total",
          "opn_detail": "合　計",
          "qty": null,
          "unit": null,
          "price": null,
          "amount": invObj.total,
        });

        book = invObj.book;
        quot = invObj.quot;
        orderNo = invObj.result_charge.result.order_no;
        locTotal = formatNumber(invObj.total);
        resultChargeId = invObj.result_charge.result_charge_id;
        locLastUpdateDate = invObj.last_update_date;

      }

      setBookId(nvl(book.book_id));
      setBookNo(nvl(book.book_no));
      setQuotId(nvl(quot.quot_id));
      setVer(nvl(quot.ver));
      setOrderNo(nvl(orderNo));
      setTotalAmount(nvl(locTotal));
      setResultChargeId(nvl(resultChargeId));
      setLastUpdateDate(nvl(parseDate(locLastUpdateDate)));

      setConstName(nvl(book.const.name));
      setBookName(nvl(book.book_name));
      setBookPref(nvl(book.pref));
      setBookCity(nvl(book.city));
      setBookAddress(nvl(book.address));

      // setConstAddress(nvl(book.pref) + nvl(book.city) + nvl(book.address));
      if (inv) {
        setOrderNo(nvl(inv.order_no));
        setConstNo(nvl(inv.const_no));
      }

      let lastRowNo = 0;
      for (let i = 0; i < locInvList.length; i++) {
        Object.entries(locInvList[i]).map(([key, value], index) => {
          if (key !== 'id' && value) {
            lastRowNo = i;
          }
        });
      }
      lastRowNo++;
      locInvList.splice(lastRowNo, locInvList.length - lastRowNo);

      setInvList(locInvList);
      setInvAdditionList(locInvAdditionList);

      let locRightList = [];
      for (let i = 0; i < locInvList.length; i++) {
        locRightList.push({
          "id": i
        });
      }
      setRightList(locRightList);
    }
    update();

  }, [updateCount]);

  const onClickInsert = async () => {
    startLoadingBtn("btn-insert");
    let obj = newData();
    obj = await postAPI("tr_inv/", obj);
    finishLoadingBtn("btn-insert");
    success("登録完了");
    setInvId(obj.inv_id);
    hist.push("/inv_dtl/" + obj.inv_id);
  }

  const onClickUpdate = async () => {
    startLoadingBtn("btn-update");
    let obj = newData();
    await putAPI("tr_inv/" + invId + "/", obj);
    finishLoadingBtn("btn-update");
    success("登録完了");
  }

  const onClickAddRow = (e, row) => {
    let locInvList = invList;
    let newRow = {
      id: 0,
      opn_detail: null,
      qty: null,
      unit: null,
      price: null,
      note: null,
    };
    locInvList.splice(row.id, 0, newRow);
    for (let i = 0; i < locInvList.length; i++) {
      locInvList[i]["id"] = i + 1;
    }

    let locRightList = [];
    for (let i = 0; i < locInvList.length; i++) {
      locRightList.push({
        "id": i
      });
    }

    setInvList(locInvList);
    setRightList(locRightList);
  }

  const onClickDeleteRow = (e, row) => {
    let locInvList = invList;
    locInvList.splice(row.id, 1);
    for (let i = 0; i < locInvList.length; i++) {
      locInvList[i]["id"] = i + 1;
    }

    let locRightList = [];
    for (let i = 0; i < locInvList.length; i++) {
      locRightList.push({
        "id": i
      });
    }

    setInvList(locInvList);
    setRightList(locRightList);
  }

  const onClickDownload = async () => {
    startLoadingBtn("btn-download", "secondary");
    let searchCondition = {
      inv_id: invId
    }

    await downloadAPI("download/inv", searchCondition);
    finishLoadingBtn("btn-download");
  }

  const onClickDelete = async () => {
    let obj = newData();
    let res = await deleteAPI("tr_inv/" + invId + "/", obj);
    if (res) {
      success("削除完了");
      hist.push("/result/" + bookId + "/1/");
    }
  }

  const newData = () => {
    let data = {
      inv_id: toNull(invId),
      book_id: toNull(bookId),
      result_charge_id: toNull(resultChargeId),
      quot_id: toNull(quotId),
      last_update_date: toNull(dateformatDB(lastUpdateDate)),
      pg: 'InvDtl'
    };

    for (let i = 0; i < invList.length; i++) {
      data["opn_detail_" + (i + 1)] = toNull(invList[i].opn_detail);
      data["qty_" + (i + 1)] = toNull(invList[i].qty);
      data["unit_" + (i + 1)] = toNull(invList[i].unit);
      data["price_" + (i + 1)] = toNull(invList[i].price);
      data["amount_" + (i + 1)] = toNull(invList[i].amount);
      data["note_" + (i + 1)] = toNull(invList[i].note);
    }

    invAdditionList.map((row) => {
      data[row.column_name] = row.amount;
    });

    return data;
  }

  return (
    <>
      <div className="body-dtl">
        <AppNav/>
        <BookNav bookId={bookId} ver={ver} quotId={quotId}/>
        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                請求書
              </h3>
            </Col>
            <Col className="text-right">
              <Button variant="secondary" id="btn-download" onClick={onClickDownload}>
                <span>
                  <FontAwesomeIcon icon={faFile} size="lg"/>請求書
                </span>
              </Button>&nbsp;
              {!invId &&
                <Button id="btn-insert" variant="primary" onClick={onClickInsert}><span><FontAwesomeIcon icon={faPen}
                                                                                                         size="lg"/>登録</span></Button>}
              {invId &&
                <Button id="btn-update" variant="primary" onClick={onClickUpdate}><span><FontAwesomeIcon icon={faPen}
                                                                                                         size="lg"/>登録</span></Button>}
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0" style={{fontWeight: "700"}}>

            <Row>
              <Col sm="1"></Col>
              <Col sm="2"></Col>
              <Col className="text-center"></Col>
              <Col sm="2" className="input-group">
                <DatePicker id="startDate" selected={lastUpdateDate} onChange={(date) => setLastUpdateDate(date)}
                            dateFormat="yyyy年MM月dd日" locale="ja"/>
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'startDate')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
              </Col>
              <Col sm="1"></Col>
            </Row>

            <Form.Group as={Row}>
              <Form.Label column sm="1">施工会社</Form.Label>
              <Col sm="4">
                <Form.Control plaintext readOnly value={constName}/>
              </Col>
              <Col sm="1"></Col>
              <Col sm="5">
                <Form.Control plaintext readOnly value={orderNo}/>
              </Col>
              <Col sm="1"></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">ご請求金額</Form.Label>
              <Col sm="4">
                <Form.Control plaintext readOnly value={totalAmount}/>
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">工事番号</Form.Label>
              <Col sm="4">
                <Form.Control plaintext readOnly value={bookNo}/>
              </Col>
              <Col sm="1"></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">現場名称</Form.Label>
              <Col sm="4">
                <Form.Control plaintext readOnly value={bookName}/>
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">お振込み先</Form.Label>
              <Col sm="4">
                <Form.Control plaintext readOnly value={comAcc1 + "　" + comAcc2}/>
              </Col>
              <Col sm="1"></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">工事場所</Form.Label>
              <Col sm="4">
                <Form.Control plaintext readOnly value={bookPref + bookCity + bookAddress}/>
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1"></Form.Label>
              <Col sm="4">
                <Form.Control plaintext readOnly value={comName}/>
              </Col>
              <Col sm="1"></Col>
            </Form.Group>

          </Card>

          {/* *** 一覧 *** */}
          {/*<Container className="container-main-list tbl-no-wrap dm-datatable-no-wrap">*/}
          <Card className="card-thirdly w-80 mx-auto">
            <Row>
              <Col sm="12" className="tbl-result-body tbl-result-main">
                <BootstrapTable
                  keyField="id"
                  classes="dm-datatable"
                  striped={true}
                  data={invList}
                  columns={invColumns}
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    onStartEdit: (row, column, rowIndex, columnIndex) => {
                      $("input[class*=edit-text]")[0].select();
                    },
                    afterSaveCell: (oldValue, newValue, row, column) => {

                      //数量変更時、金額を計算
                      let dataField = column.dataField;
                      newValue = toSingleByte(newValue);
                      if (dataField === "qty" || dataField === "price") {
                        let qty = row.qty;
                        let price = row.price;
                        if (dataField === "qty") {
                          qty = newValue;
                        }
                        if (dataField === "price") {
                          price = newValue;
                        }

                        if (qty === null || isNaN(qty)) {
                          qty = 0;
                        }
                        if (price === null || isNaN(price)) {
                          price = 0;
                        }
                        let amount = qty * price;
                        row['amount'] = amount === 0 ? "" : amount;
                      }
                    }

                  })}
                />
              </Col>
              {/*<Col sm="1" className="tbl-result-body tbl-result-btn">*/}
              {/*  <BootstrapTable*/}
              {/*    keyField="id"*/}
              {/*    data={rightList}*/}
              {/*    columns={rightColumns}*/}
              {/*  />*/}
              {/*</Col>*/}
              <Col sm="12" className="tbl-result-body tbl-result-main">
                <BootstrapTable
                  keyField="no"
                  classes="dm-datatable"
                  striped={true}
                  data={invAdditionList}
                  columns={invAdditionColumns}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                {invId && <Button variant="danger" onClick={onClickDelete}>削除</Button>}
              </Col>
            </Row>

          </Card>
          {/*</Container>*/}

        </Container>
      </div>
      <Toast/>
    </>
  );
}

export default withRouter(InvDtl);
import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../Toast";
import {getAPI} from "../APIUtil";

export const BookOpn = ({match: {params: {book_opn_id}}}) => {

  const [bookOpnId, setBookOpnId] = useState();

  //INITIALIZE
  useEffect(() => {
    const update = async () => {
      let obj = await getAPI("tr_book_opn/" + book_opn_id);
      setBookOpnId(obj.book_opn_id);
    }
    update();
  }, []);

  return (
    <>
      <AppNav/>
      <div>
        bookOpnId={bookOpnId}
      </div>
      <Toast/>
    </>
  );
}

export default withRouter(BookOpn);
import {Link} from "react-router-dom";
import React from "react";
import Button from "react-bootstrap/Button";

export const BookNav = ({bookId, ver, quotId}) => {
  return (
    <div className="h1 clearfix">
      <h1 className="float-left">
        工事物件詳細
      </h1>
      <Button as={Link} to={"/book/" + bookId + "/" + ver} variant="link-menu" className="float-left">
        工事台帳
      </Button>
      {bookId &&
      <Button as={Link} to={"/quot/" + bookId + "/" + ver} variant="link-menu" className="float-left">
        お見積り
      </Button>
      }
      {bookId && quotId &&
      <Button as={Link} to={"/result/" + bookId + "/" + ver} variant="link-menu" className="float-left">
        出来高調書
      </Button>
      }
    </div>
  )
};

export default BookNav;
import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Card, Col, Container, Form, Row} from "react-bootstrap";
import {deleteAPI, getAPI, postAPI, putAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import AsyncSelect from "react-select/async";
import {dateformatDB, nvl, toNull, toSingleByte} from "../CMUtil";

export const UserDtl = ({match: {params: {user_id}}}) => {

  const hist = useHistory();

  const [updateType, setUpdateType] = useState("");
  const [userId, setUserId] = useState("");
  const [passwd, setPasswd] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [deptId, setDeptId] = useState("");
  const [permissionId, setPermissionId] = useState("");
  const [email, setEmail] = useState("");
  const [spNum, setSpNum] = useState("");

  const [deptIdSelected, setDeptIdSelected] = useState(null);
  const [deptIdList, setDeptIdList] = useState([]);
  const [permissionIdSelected, setPermissionIdSelected] = useState(null);
  const [permissionIdList, setPermissionIdList] = useState([]);

  //初期化
  useEffect(() => {

    const update = async () => {
      if (!user_id) {
        return;
      }
      let obj = await getAPI("ms_user/" + user_id);
      setUpdateType("update");
      setUserId(nvl(obj.user_id));
      setPasswd(nvl(obj.passwd));
      setLastName(nvl(obj.last_name));
      setFirstName(nvl(obj.first_name));
      if (obj.dept) {
        setDeptId(nvl(obj.dept.code_id));
        setDeptIdSelected({id: obj.dept.code_id, name: obj.dept.code_name});
      }
      if (obj.permission) {
        setPermissionId(nvl(obj.permission.code_id));
        setPermissionIdSelected({id: obj.permission.code_id, name: obj.permission.code_name});
      }
      setSpNum(nvl(obj.sp_num));
    }
    update();

  }, []);

  const handleChangeDeptId = (value) => {
    setDeptIdSelected(value ? value : "");
    setDeptId(value ? value.id : "");
  }

  const onSearchDeptId = async (query) => {
    let locOptions = [];

    let locDeptIdList = deptIdList;
    if (locDeptIdList.length === 0) {
      //初回のみ選択肢セット
      const res = await getAPI("ms_code_search_view", {categ_id: "DEPT", sort_field: "code_id"});
      locDeptIdList = [];
      res.results.map(code => {
        locDeptIdList.push({
          id: code.code_id,
          name: code.code_name
        });
      });
      setDeptIdList(locDeptIdList);
    }

    locDeptIdList.map((l) => {
      if (l.name.indexOf(query) > -1) {
        locOptions.push(l);
      }
    });
    return locOptions;
  }

  const handleChangePermissionId = (value) => {
    setPermissionIdSelected(value ? value : "");
    setPermissionId(value ? value.id : "");
  }

  const onSearchPermissionId = async (query) => {
    let locOptions = [];

    let locPermissionIdList = permissionIdList;
    if (locPermissionIdList.length === 0) {
      //初回のみ選択肢セット
      const res = await getAPI("ms_code_search_view", {categ_id: "PERMISSION", sort_field: "code_id"});
      locPermissionIdList = [];
      res.results.map(code => {
        locPermissionIdList.push({
          id: code.code_id,
          name: code.code_name
        });
      });
      setPermissionIdList(locPermissionIdList);
    }

    locPermissionIdList.map((l) => {
      if (l.name.indexOf(query) > -1) {
        locOptions.push(l);
      }
    });
    return locOptions;
  }

  const onClickInsert = async () => {
    let obj = newData();
    obj = await postAPI("ms_user/", obj);
    setUserId(obj.user_id);
    setUpdateType("update");
    hist.push("/user/" + obj.user_id);
    success("登録完了");
  }

  const onClickUpdate = async () => {
    let obj = newData();
    await putAPI("ms_user/" + user_id + "/", obj);
    success("更新完了");
  }

  const onClickDelete = async () => {
    let currentLoginUser = await getAPI("current_login_user");
    currentLoginUser = currentLoginUser.results[0];
    let obj = newData();
    obj.delete_user_id = currentLoginUser.user_id;
    obj.delete_date = dateformatDB(new Date());
    obj.delete_pg = "UserDtl";

    let res = await putAPI("ms_user/" + user_id + "/", obj);
    if (res) {
      success("削除完了");
      hist.push("/user_list/");
    }
  }

  const newData = () => {
    return {
      user_id: toNull(userId),
      passwd: toNull(passwd),
      email: toNull(email),
      last_name: toNull(lastName),
      first_name: toNull(firstName),
      dept_id: toNull(deptId),
      permission_id: toNull(permissionId),
      sp_num: toNull(spNum),
      update_pg: 'UserDtl'
    };
  }

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <div className="h1">
          <h1 className="float-left align-items-end">
            社員
          </h1>
          <div className="float-none" style={{height: "30px"}}>&nbsp;</div>
        </div>

        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                基本情報
              </h3>
            </Col>
            <Col className="text-right">
              {updateType !== 'update' && <Button variant="primary" onClick={onClickInsert}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
              {updateType === 'update' && <Button variant="primary" onClick={onClickUpdate}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="1">社員ＩＤ</Form.Label>
              <Col sm="4">
                {
                  updateType === "update" &&
                  <Form.Control plaintext readOnly value={userId}/>
                }
                {
                  updateType !== "update" &&
                  <Form.Control
                    id="userId"
                    type="tel"
                    value={userId}
                    onChange={e => setUserId(e.target.value)}
                    onBlur={e => setUserId(toSingleByte(e.target.value))}
                  />
                }
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">名前</Form.Label>
              <Col sm="2">
                <Form.Control id="lastName" type="text" value={lastName} onChange={e => setLastName(e.target.value)} placeholder="姓"/>
              </Col>
              <Col sm="2">
                <Form.Control id="firstName" type="text" value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="名"/>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">パスワード</Form.Label>
              <Col sm="4">
                <Form.Control id="passwd" type="password" value={passwd} onChange={e => setPasswd(e.target.value)}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">メール</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="email"
                  type="tel"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  onBlur={e => setEmail(toSingleByte(e.target.value))}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">部署</Form.Label>
              <Col sm="4">
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={deptIdSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchDeptId}
                  onChange={handleChangeDeptId}
                  placeholder=""
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">権限</Form.Label>
              <Col sm="4">
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={permissionIdSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchPermissionId}
                  onChange={handleChangePermissionId}
                  placeholder=""
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">携帯</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="spNum"
                  type="text"
                  value={spNum}
                  onChange={e => setSpNum(e.target.value)}
                  onBlur={e => setSpNum(toSingleByte(e.target.value))}
                />
              </Col>
            </Form.Group>
          </Card>

          <Row>
            <Col>
              {updateType === 'update' && <Button variant="danger" onClick={onClickDelete}>削除</Button>}
            </Col>
          </Row>

        </Container>
      </div>
      <Toast/>
    </>
  );
}

export default withRouter(UserDtl);
import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {withRouter} from 'react-router';
import {getAPI} from "../APIUtil";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AppNav from "./AppNav";
import MdDatatable, {InitialLoading, sortCaret} from "../MdDataTable";
import {Card, Col, Container, Form, Row} from "react-bootstrap";
import {addField, useDebounce} from "../CMUtil";
import Button from "react-bootstrap/Button";

export const CodeList = () => {

  const history = useHistory();
  const iniSearch = useRef(true);

  const [loadingCss, setLoadingCss] = useState("loading_panel_init");

  const [loading, setLoading] = useState(true);
  const [locPage, setLocPage] = useState(null);
  const [locSizePerPage, setLocSizePerPage] = useState(null);
  const [locTotalSize, setLocTotalSize] = useState(null);
  const [locSortField, setLocSortField] = useState(null);
  const [locSortOrder, setLocSortOrder] = useState(null);

  const [codeId, setCodeId] = useState("");
  const debouncedCodeId = useDebounce(codeId, 500, setLocPage);
  const [codeName, setCodeName] = useState("", setLocPage);
  const debouncedCodeName = useDebounce(codeName, 500, setLocPage);
  const [categName, setCategName] = useState("", setLocPage);
  const debouncedCategName = useDebounce(categName, 500, setLocPage);

  const [listData, setListData] = useState([]);

  // 項目定義
  const columns = [
    {
      dataField: 'code_id',
      text: 'コード',
      classes: 'tbl-col link',
      sort: true,
      headerStyle: {width: '80px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <div><a onClick={() => onClickLinkToDetail(row.code_id)}>{cell}</a></div>
      )
    },
    {
      dataField: 'code_name',
      text: 'コード名',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'categ_name',
      text: '種別名',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'categ_id',
      text: '種別コード',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'val_1',
      text: '値１',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'val_2',
      text: '値２',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'val_3',
      text: '値３',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    }
  ];

  // 初期化
  useEffect(() => {
    const init = async () => {
      if (!history.location.state) {
        setLocPage(1);
      } else if (!history.location.state.pages) {
        setLocPage(1);
      } else if (!history.location.state.pages.filters) {
        setLocPage(1);
      } else if (Object.keys(history.location.state.pages.filters).length === 0) {
        setLocPage(1);
      } else {
        setLocPage(history.location.state.pages.page);
        setLocSizePerPage(history.location.state.pages.sizePerPage);
        setLocSortField(history.location.state.pages.sortField);
        setLocSortOrder(history.location.state.pages.sortOrder);
      }
    }
    init();
  }, []);

  // 検索処理
  useEffect(() => {
    if (iniSearch.current) {
      iniSearch.current = false;
      return;
    }
    const update = async () => {
      setLoading(true);
      let searchCondition = {};
      addField(searchCondition, "page", locPage);
      addField(searchCondition, "size_per_page", locSizePerPage);
      addField(searchCondition, "sort_field", locSortField);
      addField(searchCondition, "sort_order", locSortOrder);
      addField(searchCondition, "code_id", codeId);
      addField(searchCondition, "code_name", codeName);
      addField(searchCondition, "categ_name", categName);
      const res = await getAPI("ms_code_search_view", searchCondition);
      setListData(res);
      setLoading(false);
      setLoadingCss("loading_panel_fadeout");
    }
    update();
  }, [
    locPage,
    locSizePerPage,
    locSortField,
    locSortOrder,
    debouncedCodeId,
    debouncedCodeName,
    debouncedCategName,
  ]);

  const onTableChange = async (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
    if ("pagination" === type) {
      setLocPage(page);
    }
    if ("sizePerPage" === type) {
      setLocSizePerPage(sizePerPage);
    }
    if ("sort" === type) {
      setLocSortField(sortField);
      setLocSortOrder(sortOrder);
    }
  }

  const onClickLinkToDetail = (code_id) => {
    history.push("/code/" + code_id);
  }

  const onClickClear = () =>{
    setCodeId("");
    setCodeName("");
    setCategName("");
  }

  return (
    <>
      <AppNav/>
      <div className="h1">
        <h1>
          システム設定
        </h1>
      </div>
      <Card className="card-search">
        <Container className="container-main-search">
          <Form.Group as={Row}>
            <Form.Label column sm="1">コード</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={codeId} onChange={e => setCodeId(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">コード名</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={codeName} onChange={e => setCodeName(e.target.value)}/>
            </Col>
            <Col sm="1">
              <Button variant="clear" onClick={e => onClickClear()}>条件クリア</Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">種別名</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={categName} onChange={e => setCategName(e.target.value)}/>
            </Col>
          </Form.Group>
        </Container>
      </Card>

      <Container className="container-main-list">
        {/* *** 一覧 *** */}
        <InitialLoading loadingCss={loadingCss}/>
        <MdDatatable
          keyField="code_id"
          listData={listData}
          columns={columns}
          loading={loading}
          onTableChange={onTableChange}
          page={locPage}
          sizePerPage={locSizePerPage}
          totalSize={locTotalSize}
          sortField={locSortField}
          sortOrder={locSortOrder}
          showPagenation={true}
          linkToAdd="/code/"
        />
      </Container>
    </>
  );
}

export default withRouter(CodeList);
import React, {useEffect, useState} from 'react';
import {getAPI} from "./APIUtil";

export const GlobalContext = React.createContext({});

export const GlobalProvider = ({
                                 children
                               }) => {

  const [loginUser, setLoginUser] = useState({
    "user_id": "",
    "name": "",
    "permission": ""
  });

  //INITIALIZE
  useEffect(() => {

    const update = async () => {
    }
    update();

  }, []);

  return (
    <GlobalContext.Provider
      value={{
        loginUser,
        setLoginUser,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
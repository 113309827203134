import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory, withRouter} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {downloadAPI, getAPI} from "../APIUtil";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AppNav from "./AppNav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MdDatatable, {InitialLoading, sortCaret} from "../MdDataTable";
import {
  addField,
  checkKey,
  dateformat,
  dateformatJPNYYYYMMDD,
  finishLoadingBtn,
  formatNumber,
  formatZip, getRemainChargeById,
  getStatus0ById,
  getStatus1ById,
  isLatestVersion,
  jumpToTopByDevice,
  onSearchCategory,
  onSearchNote2,
  onSearchOpnArea,
  onSearchPref,
  onSearchRemainCharge,
  onSearchStatus0,
  onSearchStatus1,
  parseDate,
  setFocusCalendar,
  startLoadingBtn,
  useDebounce,
} from "../CMUtil";
import {Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {faCalendarAlt, faFileDownload} from "@fortawesome/free-solid-svg-icons";
import DatePicker, {registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import AsyncSelect from "react-select/async";
import MdDataTableFreezeHeader from "../MdDataTableFreezeHeader";

registerLocale('ja', ja)

export const BookList = () => {

  const history = useHistory();
  const iniSearch = useRef(true);

  const [loadingCss, setLoadingCss] = useState("loading_panel_init");

  const [loading, setLoading] = useState(true);
  const [locPage, setLocPage] = useState(null);
  const [locSizePerPage, setLocSizePerPage] = useState(100);
  const [locTotalSize, setLocTotalSize] = useState(null);
  const [locSortField, setLocSortField] = useState(null);
  const [locSortOrder, setLocSortOrder] = useState(null);

  const [bookId, setBookId] = useState("");
  const debouncedBookId = useDebounce(bookId, 500, setLocPage);
  const [bookNo, setBookNo] = useState("");
  const debouncedBookNo = useDebounce(bookNo, 500, setLocPage);
  const [bookName, setBookName] = useState("");
  const debouncedBookName = useDebounce(bookName, 500, setLocPage);
  const [constName, setConstName] = useState("");
  const debouncedConstName = useDebounce(constName, 500, setLocPage);
  const [extDesignName, setExtDesignName] = useState("");
  const debouncedExtDesignName = useDebounce(extDesignName, 500, setLocPage);
  const [strDesignName, setStrDesignName] = useState("");
  const debouncedStrDesignName = useDebounce(strDesignName, 500, setLocPage);
  const [zip, setZip] = useState("");
  const debouncedZip = useDebounce(zip, 500, setLocPage);
  const [pref, setPref] = useState("");
  const debouncedPref = useDebounce(pref, 500, setLocPage);
  const [cityAddress, setCityAddress] = useState("");
  const debouncedCityAddress = useDebounce(cityAddress, 500, setLocPage);
  const [category, setCategory] = useState("");
  const debouncedCategory = useDebounce(category, 500, setLocPage);
  const [mainUsage, setMainUsage] = useState("");
  const debouncedMainUsage = useDebounce(mainUsage, 500, setLocPage);
  const [mainUsageAnd, setMainUsageAnd] = useState(true);
  const debouncedMainUsageAnd = useDebounce(mainUsageAnd, 500, setLocPage);
  const [opnArea, setOpnArea] = useState("");
  const debouncedOpnArea = useDebounce(opnArea, 500, setLocPage);
  const [note1, setNote1] = useState("");
  const debouncedNote1 = useDebounce(note1, 500, setLocPage);
  const [note2, setNote2] = useState("");
  const debouncedNote2 = useDebounce(note2, 500, setLocPage);
  const [note3, setNote3] = useState("");
  const debouncedNote3 = useDebounce(note3, 500, setLocPage);
  const [picConst, setPicConst] = useState("");
  const debouncedPicConst = useDebounce(picConst, 500, setLocPage);
  const [picDesign, setPicDesign] = useState("");
  const debouncedPicDesign = useDebounce(picDesign, 500, setLocPage);
  const [startDateFrom, setStartDateFrom] = useState("");
  const debouncedStartDateFrom = useDebounce(startDateFrom, 500, setLocPage);
  const [startDateTo, setStartDateTo] = useState("");
  const debouncedStartDateTo = useDebounce(startDateTo, 500, setLocPage);
  const [completeDateFrom, setCompleteDateFrom] = useState("");
  const debouncedCompleteDateFrom = useDebounce(completeDateFrom, 500, setLocPage);
  const [completeDateTo, setCompleteDateTo] = useState("");
  const debouncedCompleteDateTo = useDebounce(completeDateTo, 500, setLocPage);
  const [warrantyDateFrom, setWarrantyDateFrom] = useState("");
  const debouncedWarrantyDateFrom = useDebounce(warrantyDateFrom, 500, setLocPage);
  const [warrantyDateTo, setWarrantyDateTo] = useState("");
  const debouncedWarrantyDateTo = useDebounce(warrantyDateTo, 500, setLocPage);

  const [status0, setStatus0] = useState("");
  const debouncedStatus0 = useDebounce(status0, 500, setLocPage);
  const [status1, setStatus1] = useState("");
  const debouncedStatus1 = useDebounce(status1, 500, setLocPage);
  const [remainCharge, setRemainCharge] = useState("");
  const debouncedRemainCharge = useDebounce(remainCharge, 500, setLocPage);

  const [memo, setMemo] = useState("");
  const debouncedMemo = useDebounce(memo, 500, setLocPage);
  const [memo2, setMemo2] = useState("");
  const debouncedMemo2 = useDebounce(memo2, 500, setLocPage);
  const [specialNote, setSpecialNote] = useState("");
  const debouncedSpecialNote = useDebounce(specialNote, 500, setLocPage);

  const [prefSelected, setPrefSelected] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  const [opnAreaSelected, setOpnAreaSelected] = useState([]);
  const [note2Selected, setNote2Selected] = useState([]);
  const [status0Selected, setStatus0Selected] = useState([]);
  const [status1Selected, setStatus1Selected] = useState([]);
  const [remainChargeSelected, setRemainChargeSelected] = useState([]);

  const [listData, setListData] = useState([]);

  // 項目定義
  const columns = [
    {
      dataField: 'book_no',
      text: '工事番号',
      classes: 'tbl-col link',
      sort: true,
      headerStyle: {width: '85px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <div><a onClick={() => onClickLinkToDetail(row.book_id)}>{cell ? cell : "未定"}</a></div>
      )
    },
    {
      dataField: 'book_name',
      text: '現場名',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '230px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title={cell}>
          {cell}
        </span>
      )
    },
    {
      dataField: 'book_status',
      text: '進捗',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '80px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'const_name',
      text: '施工業者',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '230px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title={cell}>
          {cell}
        </span>
      )
    },
    {
      dataField: 'ext_design_names',
      text: '設計事務所',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '230px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title={cell}>
          {cell}
        </span>
      )
    },
    {
      dataField: 'str_design_names',
      text: '構造設計',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '230px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title={cell}>
          {cell}
        </span>
      )
    },
    {
      dataField: 'quot_book_id',
      text: '見積',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '60px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{cell ? '有' : '無'}</span>
      )
    },
    {
      dataField: 'book_address',
      text: '住所',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '350px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'category',
      text: '分類',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'main_usage',
      text: '主要用途',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'opn_area',
      text: '施工箇所',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'note1',
      text: '備考',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'note2',
      text: '変更・改修',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'note3',
      text: 'デベロッパ',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'pic_const',
      text: '担当者',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'pic_design',
      text: '設計担当者',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'start_date',
      text: '着工日',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '120px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      )
    },

    {
      dataField: 'complete_date',
      text: '完了日',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '120px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      )
    },
    {
      dataField: 'warranty_date',
      text: '保証書',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '120px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      )
    },
    {
      dataField: 'memo',
      text: '現場メモ',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'memo2',
      text: '設計メモ',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'special_note',
      text: '特記事項',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'remain_charge',
      text: '請求残額',
      classes: 'tbl-col text-right',
      headerStyle: {width: '150px'},
      formatter: (cell, row) => (
        <span>{formatNumber(cell)}</span>
      )
    }
  ];

  // 初期化
  useEffect(() => {

    let book_search_condition = localStorage.getItem("book_search_condition");
    if (book_search_condition) {
      let s = JSON.parse(book_search_condition);
      s.page ? setLocPage(s.page) : setLocPage(1);
      s.size_per_page ? setLocSizePerPage(s.size_per_page) : null;
      s.sort_field ? setLocSortField(s.sort_field) : null;
      s.sort_order ? setLocSortOrder(s.sort_order) : null;
      s.book_no ? setBookNo(s.book_no) : null;
      s.book_name ? setBookName(s.book_name) : null;
      s.const_name ? setConstName(s.const_name) : null;
      s.ext_design_name ? setExtDesignName(s.ext_design_name) : null;
      s.str_design_name ? setStrDesignName(s.str_design_name) : null;
      s.book_name ? setBookName(s.book_name) : null;
      s.zip ? setZip(s.zip) : null;
      s.pref ? setPref(s.pref) : null;
      s.pref ? setPrefSelected({name: s.pref, id: s.pref}) : null;
      s.city_address ? setCityAddress(s.city_address) : null;
      s.category ? setCategory(s.category) : null;
      s.category ? setCategorySelected({name: s.category, id: s.category}) : null;
      s.main_usage ? setMainUsage(s.main_usage) : null;
      s.main_usage_and !== null && s.main_usage_and !== '' && s.main_usage_and !== undefined ? setMainUsageAnd(s.main_usage_and) : true;
      s.opn_area ? setOpnArea(s.opn_area) : null;
      s.opn_area ? setOpnAreaSelected({name: s.opn_area, id: s.opn_area}) : null;
      s.note1 ? setNote1(s.note1) : null;
      s.note2 ? setNote2(s.note2) : null;
      s.note3 ? setNote3(s.note3) : null;
      s.pic_const ? setPicConst(s.pic_const) : null;
      s.pic_design ? setPicDesign(s.pic_design) : null;
      s.start_date_from ? setStartDateFrom(parseDate(s.start_date_from)) : null;
      s.start_date_to ? setStartDateTo(parseDate(s.start_date_to)) : null;
      s.complete_date_from ? setCompleteDateFrom(parseDate(s.complete_date_from)) : null;
      s.complete_date_to ? setCompleteDateTo(parseDate(s.complete_date_to)) : null;
      s.warranty_date_from ? setWarrantyDateFrom(parseDate(s.warranty_date_from)) : null;
      s.warranty_date_to ? setWarrantyDateTo(parseDate(s.warranty_date_to)) : null;
      s.status0 ? setStatus0(s.status0) : null;
      s.status0 ? setStatus0Selected(getStatus0ById(s.status0)) : null;
      s.status1 ? setStatus1(s.status1) : null;
      s.status1 ? setStatus1Selected(getStatus1ById(s.status1)) : null;
      s.remain_charge ? setRemainCharge(s.remain_charge) : null;
      s.remain_charge ? setRemainChargeSelected(getRemainChargeById(s.remain_charge)) : null;
      s.memo ? setMemo(s.memo) : null;
      s.memo2 ? setMemo2(s.memo2) : null;
      s.special_note ? setSpecialNote(s.special_note) : null;
    } else {
      setLocPage(1);
    }

    const init = async () => {
      await isLatestVersion(false);
      jumpToTopByDevice(history);
    }

    init();
  }, []);

  // 検索処理
  useEffect(() => {

      if (iniSearch.current) {
        iniSearch.current = false;
        return;
      }

      const update = async () => {

        setLoading(true);
        let condition = {};

        let sortField = locSortField;
        let sortOrder = locSortOrder;
        if (!sortField && !sortOrder) {
          //デフォルト　着工日降順
          sortField = null;
          sortOrder = null;
        }

        addField(condition, "page", locPage);
        addField(condition, "size_per_page", locSizePerPage);
        addField(condition, "sort_field", sortField);
        addField(condition, "sort_order", sortOrder);
        addField(condition, "book_id", bookId);
        addField(condition, "book_no", bookNo);
        addField(condition, "const_name", constName);
        addField(condition, "ext_design_name", extDesignName);
        addField(condition, "str_design_name", strDesignName);
        addField(condition, "book_name", bookName);
        addField(condition, "zip", zip);
        addField(condition, "pref", pref);
        addField(condition, "city_address", cityAddress);
        addField(condition, "category", category);
        addField(condition, "main_usage", mainUsage);
        addField(condition, "main_usage_and", mainUsageAnd);
        addField(condition, "opn_area", opnArea);
        addField(condition, "note1", note1);
        addField(condition, "note2", note2);
        addField(condition, "note3", note3);
        addField(condition, "pic_const", picConst);
        addField(condition, "pic_design", picDesign);
        addField(condition, "start_date_from", dateformat(startDateFrom));
        addField(condition, "start_date_to", dateformat(startDateTo));
        addField(condition, "complete_date_from", dateformat(completeDateFrom));
        addField(condition, "complete_date_to", dateformat(completeDateTo));
        addField(condition, "warranty_date_from", dateformat(warrantyDateFrom));
        addField(condition, "warranty_date_to", dateformat(warrantyDateTo));
        addField(condition, "status0", status0);
        addField(condition, "status1", status1);
        addField(condition, "remain_charge", remainCharge);
        addField(condition, "memo", memo);
        addField(condition, "memo2", memo2);
        addField(condition, "special_note", specialNote);

        localStorage.setItem("book_search_condition", JSON.stringify(condition));
        const res = await getAPI("tr_book_search_view", condition);
        setListData(res);
        setLoading(false);
        setLoadingCss("loading_panel_fadeout");

      }
      update();
    },
    [
      locPage,
      locSizePerPage,
      locSortField,
      locSortOrder,
      debouncedBookId,
      debouncedBookNo,
      debouncedBookName,
      debouncedConstName,
      debouncedExtDesignName,
      debouncedStrDesignName,
      debouncedZip,
      debouncedPref,
      debouncedCityAddress,
      debouncedCategory,
      debouncedMainUsage,
      debouncedMainUsageAnd,
      debouncedOpnArea,
      debouncedNote1,
      debouncedNote2,
      debouncedNote3,
      debouncedPicConst,
      debouncedPicDesign,
      debouncedStartDateFrom,
      debouncedStartDateTo,
      debouncedCompleteDateFrom,
      debouncedCompleteDateTo,
      debouncedWarrantyDateFrom,
      debouncedWarrantyDateTo,
      debouncedStatus0,
      debouncedStatus1,
      debouncedRemainCharge,
      debouncedMemo,
      debouncedMemo2,
      debouncedSpecialNote
    ]);

  const onTableChange = (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
    if ("pagination" === type) {
      setLocPage(page);
    }
    if ("sort" === type) {
      setLocSortField(sortField);
      setLocSortOrder(sortOrder);
    }
  }

  const onClickLinkToDetail = (book_id) => {

    history.push("/book/" + book_id + "/1");
  }

  const handleChangePref = (value) => {
    setPrefSelected(value ? value : "");
    setPrefSearch(value ? value.id : "");
  }

  const handleChangeCategory = (value) => {
    setCategorySelected(value ?? "");
    setCategorySearch(value?.id ?? "");
  }

  const handleChangeOpnArea = (value) => {
    setOpnAreaSelected(value ?? "");
    setOpnAreaSearch(value?.id ?? "");
  }

  const handleChangeNote2 = (value) => {
    setNote2Selected(value ?? "");
    setNote2Search(value?.id ?? "");
  }

  const handleChangeStatus0 = (value) => {
    setStatus0Selected(value ?? "");
    setStatus0Search(value?.id ?? "");
  }

  const handleChangeStatus1 = (value) => {
    setStatus1Selected(value ?? "");
    setStatus1Search(value?.id ?? "");
  }

  const handleRemainCharge = (value) => {
    setRemainChargeSelected(value ?? "");
    setRemainChargeSearch(value?.id ?? "");
  }

  const onClickDownload = async () => {
    startLoadingBtn("btn-download", "secondary");

    let condition = {};
    let sortField = locSortField;
    let sortOrder = locSortOrder;
    if (!sortField && !sortOrder) {
      //デフォルト　着工日降順
      sortField = null;
      sortOrder = null;
    }
    addField(condition, "page", locPage);
    addField(condition, "size_per_page", locSizePerPage);
    addField(condition, "sort_field", sortField);
    addField(condition, "sort_order", sortOrder);
    addField(condition, "book_id", bookId);
    addField(condition, "book_no", bookNo);
    addField(condition, "const_name", constName);
    addField(condition, "ext_design_name", extDesignName);
    addField(condition, "str_design_name", strDesignName);
    addField(condition, "book_name", bookName);
    addField(condition, "zip", zip);
    addField(condition, "pref", pref);
    addField(condition, "city_address", cityAddress);
    addField(condition, "category", category);
    addField(condition, "main_usage", mainUsage);
    addField(condition, "main_usage_and", mainUsageAnd);
    addField(condition, "opn_area", opnArea);
    addField(condition, "note1", note1);
    addField(condition, "note2", note2);
    addField(condition, "note3", note3);
    addField(condition, "pic_const", picConst);
    addField(condition, "pic_design", picDesign);
    addField(condition, "start_date_from", dateformat(startDateFrom));
    addField(condition, "start_date_to", dateformat(startDateTo));
    addField(condition, "complete_date_from", dateformat(completeDateFrom));
    addField(condition, "complete_date_to", dateformat(completeDateTo));
    addField(condition, "warranty_date_from", dateformat(warrantyDateFrom));
    addField(condition, "warranty_date_to", dateformat(warrantyDateTo));
    addField(condition, "status0", status0);
    addField(condition, "status1", status1);
    addField(condition, "remain_charge", remainCharge);
    addField(condition, "memo", memo);
    addField(condition, "memo2", memo2);
    addField(condition, "special_note", specialNote);
    await downloadAPI("download/book_list", condition);
    finishLoadingBtn("btn-download");
  }

  const onClickClear = () => {
    setBookIdSearch("");
    setBookNoSearch("");
    setBookNameSearch("");
    setConstNameSearch("");
    setExtDesignNameSearch("");
    setStrDesignNameSearch("");
    setZipSearch("");
    setPrefSearch("");
    setPrefSelected(null);
    setCityAddressSearch("");
    setCategorySearch("");
    setCategorySelected(null);
    setMainUsageSearch("");
    setMainUsageSearchAnd(true);
    setOpnAreaSearch("");
    setOpnAreaSelected(null);
    setNote1Search("");
    setNote2Search("");
    setNote2Selected(null);
    setNote3Search("");
    setPicConstSearch("");
    setPicDesignSearch("");
    setStartDateFromSearch("");
    setStartDateToSearch("");
    setCompleteDateFromSearch("");
    setCompleteDateToSearch("");
    setWarrantyDateFromSearch("");
    setWarrantyDateToSearch("");
    setStatus0Search("");
    setStatus0Selected(null);
    setStatus1Search("");
    setStatus1Selected(null);
    setRemainCharge("");
    setRemainChargeSelected(null);
    setMemoSearch("");
    setMemo2Search("");
    setSpecialNoteSearch("");
    setLocSortField("");
    setLocSortOrder("");
    localStorage.removeItem("book_search_condition");
  }

  const setBookIdSearch = (value) => {
    setLocPage(1);
    setBookId(value);
  }

  const setBookNoSearch = (value) => {
    setLocPage(1);
    setBookNo(value);
  }

  const setBookNameSearch = (value) => {
    setLocPage(1);
    setBookName(value);
  }
  const setConstNameSearch = (value) => {
    setLocPage(1);
    setConstName(value);
  }
  const setExtDesignNameSearch = (value) => {
    setLocPage(1);
    setExtDesignName(value);
  }
  const setStrDesignNameSearch = (value) => {
    setLocPage(1);
    setStrDesignName(value);
  }
  const setZipSearch = (value) => {
    setLocPage(1);
    setZip(value);
  }
  const setPrefSearch = (value) => {
    setLocPage(1);
    setPref(value);
  }
  const setCityAddressSearch = (value) => {
    setLocPage(1);
    setCityAddress(value);
  }
  const setCategorySearch = (value) => {
    setLocPage(1);
    setCategory(value);
  }
  const setMainUsageSearch = (value) => {
    setLocPage(1);
    setMainUsage(value);
  }
  const setMainUsageSearchAnd = (value) => {
    setLocPage(1);
    setMainUsageAnd(value);
  }
  const setOpnAreaSearch = (value) => {
    setLocPage(1);
    setOpnArea(value);
  }
  const setNote1Search = (value) => {
    setLocPage(1);
    setNote1(value);
  }
  const setNote2Search = (value) => {
    setLocPage(1);
    setNote2(value);
  }
  const setNote3Search = (value) => {
    setLocPage(1);
    setNote3(value);
  }
  const setPicConstSearch = (value) => {
    setLocPage(1);
    setPicConst(value);
  }
  const setPicDesignSearch = (value) => {
    setLocPage(1);
    setPicDesign(value);
  }
  const setStartDateFromSearch = (value) => {
    setLocPage(1);
    setStartDateFrom(value);
  }
  const setStartDateToSearch = (value) => {
    setLocPage(1);
    setStartDateTo(value);
  }
  const setCompleteDateFromSearch = (value) => {
    setLocPage(1);
    setCompleteDateFrom(value);
  }
  const setCompleteDateToSearch = (value) => {
    setLocPage(1);
    setCompleteDateTo(value);
  }
  const setWarrantyDateFromSearch = (value) => {
    setLocPage(1);
    setWarrantyDateFrom(value);
  }
  const setWarrantyDateToSearch = (value) => {
    setLocPage(1);
    setWarrantyDateTo(value);
  }
  const setStatus0Search = (value) => {
    setLocPage(1);
    setStatus0(value);
  }
  const setStatus1Search = (value) => {
    setLocPage(1);
    setStatus1(value);
  }
  const setRemainChargeSearch = (value) => {
    setLocPage(1);
    setRemainCharge(value);
  }
  const setMemoSearch = (value) => {
    setLocPage(1);
    setMemo(value);
  }
  const setMemo2Search = (value) => {
    setLocPage(1);
    setMemo2(value);
  }

  const setSpecialNoteSearch = (value) => {
    setLocPage(1);
    setSpecialNote(value);
  }

  return (
    <>
      <AppNav/>
      <Row className="h1">
        <Col sm={8}>
          <h1>
            工事物件検索
          </h1>
        </Col>
        <Col sm="4" className="text-right">
          <Button variant="secondary" id="btn-download" onClick={onClickDownload}>
            <span>
            <FontAwesomeIcon icon={faFileDownload} size="lg"/>工事台帳
            </span>
          </Button>
        </Col>
      </Row>
      <Card className="card-search">
        <Container className="container-main-search">
          <Form.Group as={Row}>
            <Form.Label column sm="1">工事番号</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={bookNo} onChange={e => setBookNoSearch(e.target.value)}/>
            </Col>
            <Col sm="6"></Col>
            <Col sm="1">
              <Button variant="clear" onClick={e => onClickClear()}>条件クリア</Button>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">現場名</Form.Label>
            <Col sm="2">
              <Form.Control type="text" value={bookName} onChange={e => setBookNameSearch(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">施工箇所</Form.Label>
            <Col sm="2">
              <AsyncSelect sm="2"
                           id="opnArea"
                           className="async-select"
                           cacheOptions
                           defaultOptions
                           value={opnAreaSelected}
                           getOptionLabel={e => e.name}
                           getOptionValue={e => e.id}
                           loadOptions={onSearchOpnArea}
                           onChange={handleChangeOpnArea}
                           placeholder=""
              />
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">変更・改修</Form.Label>
            <Col sm="2">
              <AsyncSelect sm="2"
                           id="note2"
                           className="async-select"
                           cacheOptions
                           defaultOptions
                           value={note2Selected}
                           getOptionLabel={e => e.name}
                           getOptionValue={e => e.id}
                           loadOptions={onSearchNote2}
                           onChange={handleChangeNote2}
                           placeholder=""
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">住所</Form.Label>
            <Col sm="1" className="input-group">
              <Form.Control id="zip"
                            type="text"
                            value={zip}
                            style={{textAlign: "center"}}
                            placeholder="郵便番号"
                            onKeyPress={e => checkKey(e, /[0-9\-]/)} maxLength={8}
                            onChange={e => setZipSearch(e.target.value)}
                            onBlur={e => formatZip(e.target.value, setZip)}
              />
            </Col>
            <Col sm="2">
              <AsyncSelect sm="2"
                           className="async-select"
                           cacheOptions
                           defaultOptions
                           isClearable
                           value={prefSelected}
                           getOptionLabel={e => e.name}
                           getOptionValue={e => e.id}
                           loadOptions={onSearchPref}
                           onChange={handleChangePref}
                           placeholder=""
              />
            </Col>
            <Col sm="2" className="input-group">
              <Form.Control type="text" value={cityAddress}
                            style={{borderRadius: "0"}}
                            placeholder="市区町村 住所"
                            onChange={e => setCityAddressSearch(e.target.value)}/>
            </Col>
            <Form.Label column sm="1">意匠設計</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={extDesignName} onChange={e => setExtDesignNameSearch(e.target.value)}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">施工業者</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={constName} onChange={e => setConstNameSearch(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">構造設計</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={strDesignName} onChange={e => setStrDesignNameSearch(e.target.value)}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">工事担当者</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={picConst} onChange={e => setPicConstSearch(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">設計担当</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={picDesign} onChange={e => setPicDesignSearch(e.target.value)}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">進捗</Form.Label>
            <Col sm="2">
              <AsyncSelect sm="2"
                           id="status0"
                           className="async-select"
                           cacheOptions
                           defaultOptions
                           isClearable
                           value={status0Selected}
                           getOptionLabel={e => e.name}
                           getOptionValue={e => e.id}
                           loadOptions={onSearchStatus0}
                           onChange={handleChangeStatus0}
                           placeholder=""
              />
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">見積り</Form.Label>
            <Col sm="2">
              <AsyncSelect sm="2"
                           id="status1"
                           className="async-select"
                           cacheOptions
                           defaultOptions
                           isClearable
                           value={status1Selected}
                           getOptionLabel={e => e.name}
                           getOptionValue={e => e.id}
                           loadOptions={onSearchStatus1}
                           onChange={handleChangeStatus1}
                           placeholder=""
              />
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">分類</Form.Label>
            <Col sm="2">
              <AsyncSelect sm="2"
                           id="category"
                           className="async-select"
                           cacheOptions
                           defaultOptions
                           value={categorySelected}
                           getOptionLabel={e => e.name}
                           getOptionValue={e => e.id}
                           loadOptions={onSearchCategory}
                           onChange={handleChangeCategory}
                           placeholder=""
              />
            </Col>

          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">着工日</Form.Label>
            <Col sm="4" className="input-group">
              <DatePicker id="startDateFrom" selected={startDateFrom} onChange={(date) => setStartDateFromSearch(date)}
                          dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'startDateFrom')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
              <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
              <DatePicker id="startDateTo" selected={startDateTo} onChange={(date) => setStartDateToSearch(date)}
                          dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'startDateTo')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">主要用途</Form.Label>
            <Col sm="3">
              <Form.Control type="text" value={mainUsage} onChange={e => setMainUsageSearch(e.target.value)}/>
            </Col>
            <Col sm="1">
              <Form.Check
                type="checkbox"
                className="ml-3"
                label="AND"
                onChange={e => setMainUsageSearchAnd(!mainUsageAnd)}
                checked={mainUsageAnd}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">完了日</Form.Label>
            <Col sm="4" className="input-group">
              <DatePicker id="completeDateFrom" selected={completeDateFrom}
                          onChange={(date) => setCompleteDateFromSearch(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'completeDateFrom')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
              <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
              <DatePicker id="completeDateTo" selected={completeDateTo}
                          onChange={(date) => setCompleteDateToSearch(date)}
                          dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'completeDateTo')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">備考</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={note1} onChange={e => setNote1Search(e.target.value)}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">保証期間</Form.Label>
            <Col sm="4" className="input-group">
              <DatePicker id="warrantyDateFrom" selected={warrantyDateFrom}
                          onChange={(date) => setWarrantyDateFromSearch(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'warrantyDateFrom')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
              <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
              <DatePicker id="warrantyDateTo" selected={warrantyDateTo}
                          onChange={(date) => setWarrantyDateToSearch(date)}
                          dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'warrantyDateTo')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">デベロッパ</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={note3} onChange={e => setNote3Search(e.target.value)}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">現場メモ</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={memo} onChange={e => setMemoSearch(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">設計メモ</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={memo2} onChange={e => setMemo2Search(e.target.value)}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">特記事項</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={specialNote} onChange={e => setSpecialNoteSearch(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">請求残額</Form.Label>
            <Col sm="2">
              <AsyncSelect id="remainCharge"
                           className="async-select"
                           cacheOptions
                           defaultOptions
                           isClearable
                           value={remainChargeSelected}
                           getOptionLabel={e => e.name}
                           getOptionValue={e => e.id}
                           loadOptions={onSearchRemainCharge}
                           onChange={handleRemainCharge}
                           placeholder=""
              />
            </Col>
          </Form.Group>

        </Container>

      </Card>

      <Container className="container-main-list">
        {/* *** 一覧 *** */}
        <InitialLoading loadingCss={loadingCss}/>
        <MdDataTableFreezeHeader
          keyField="book_id"
          listData={listData}
          columns={columns}
          loading={loading}
          onTableChange={onTableChange}
          page={locPage}
          sizePerPage={locSizePerPage}
          totalSize={locTotalSize}
          sortField={locSortField}
          sortOrder={locSortOrder}
          showPagenation={true}
          linkToAdd="/book/"
          onClickLinkToDetail={onClickLinkToDetail}
        />
      </Container>
    </>
  );
}

export default withRouter(BookList);
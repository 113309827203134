import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {Top} from "./Top";
import PageRoutesAuthenticatedGuard from "./PageRoutesAuthenticatedGuard";
import PageRoutesAuthenticated from "./PageRoutesAuthenticated";

export const RoutePaths = {
  root: {
    top: '/',
  },
};

export const PageRoutes = () => {

  return (
    <Switch>
      <Route exact path={RoutePaths.root.top} component={Top}/>
      <PageRoutesAuthenticatedGuard>
        <PageRoutesAuthenticated />
      </PageRoutesAuthenticatedGuard>
    </Switch>
  );
};

export default PageRoutes;

import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {ConstList} from "./ConstList";
import {ConstDtl} from "./ConstDtl";
import {DesignList} from "./DesignList";
import {DesignDtl} from "./DesignDtl";
import {UserList} from "./UserList";
import {UserDtl} from "./UserDtl";
import {CodeDtl} from "./CodeDtl";
import {CodeList} from "./CodeList";
import {BookList} from "./BookList";
import {BookDtl} from "./BookDtl";
import {Map} from "./Map";
import {QuotDtl} from "./QuotDtl";
import {ReportDtl} from "./ReportDtl";
import {DocList} from "./DocList";
import {DocDtl} from "./DocDtl";
import {Cal} from "./Cal";
import {BookOpn} from "./BookOpn";
import {ResultDtl} from "./ResultDtl";
import {InvDtl} from "./InvDtl";
import {Schedule} from "./Schedule";
import {BookStatus} from "./BookStatus";
import {PicList} from "./PicList";

export const RoutePaths = {
  root: {
    user_list: '/user_list/',
    user_ini: '/user/',
    user_dtl: '/user/:user_id',
    const_list: '/const_list/',
    const_ini: '/const/',
    const_dtl: '/const/:const_id',
    design_list: '/design_list/',
    design_ini: '/design/',
    design_dtl: '/design/:design_id',
    code_list: '/code_list/',
    code_ini: '/code/',
    code_dtl: '/code/:p_id/',
    book_list: '/book_list/',
    book_ini: '/book/',
    book_dtl: '/book/:p_book_id/:p_ver',
    book_opn: '/book_opn/:book_opn_id',
    book_status: '/book_status/',
    report_dtl: '/report_dtl/:p_opn_user_id/:p_month',
    map: '/map/',
    quot_dtl: '/quot/:p_book_id/:p_ver',
    result_dtl: '/result/:p_book_id/:p_ver',
    inv_dtl: '/inv_dtl/:p_inv_id',
    doc_list: '/doc_list/',
    doc_ini: '/doc/',
    doc_dtl: '/doc/:p_doc_id',
    doc_const_category: '/doc/:p_book_id/:p_category',
    cal: '/cal/:p_month',
    schedule: '/schedule/:p_opn_date',
    pic_list: '/pic_list/:p_book_id',
  },
};

export const PageRoutesAuthenticated = () => {

  return (
    <Switch>
      <Route exact path={RoutePaths.root.user_list} component={UserList}/>
      <Route exact path={RoutePaths.root.user_ini} component={UserDtl}/>
      <Route exact path={RoutePaths.root.user_dtl} component={UserDtl}/>
      <Route exact path={RoutePaths.root.const_list} component={ConstList}/>
      <Route exact path={RoutePaths.root.const_ini} component={ConstDtl}/>
      <Route exact path={RoutePaths.root.const_dtl} component={ConstDtl}/>
      <Route exact path={RoutePaths.root.design_list} component={DesignList}/>
      <Route exact path={RoutePaths.root.design_ini} component={DesignDtl}/>
      <Route exact path={RoutePaths.root.design_dtl} component={DesignDtl}/>
      <Route exact path={RoutePaths.root.code_list} component={CodeList}/>
      <Route exact path={RoutePaths.root.code_ini} component={CodeDtl}/>
      <Route exact path={RoutePaths.root.code_dtl} component={CodeDtl}/>
      <Route exact path={RoutePaths.root.book_list} component={BookList}/>
      <Route exact path={RoutePaths.root.book_ini} component={BookDtl}/>
      <Route exact path={RoutePaths.root.book_dtl} component={BookDtl}/>
      <Route exact path={RoutePaths.root.book_opn} component={BookOpn}/>
      <Route exact path={RoutePaths.root.book_status} component={BookStatus}/>
      <Route exact path={RoutePaths.root.report_dtl} component={ReportDtl}/>
      <Route exact path={RoutePaths.root.map} component={Map}/>
      <Route exact path={RoutePaths.root.quot_dtl} component={QuotDtl}/>
      <Route exact path={RoutePaths.root.result_dtl} component={ResultDtl}/>
      <Route exact path={RoutePaths.root.inv_dtl} component={InvDtl}/>
      <Route exact path={RoutePaths.root.doc_list} component={DocList}/>
      <Route exact path={RoutePaths.root.doc_ini} component={DocDtl}/>
      <Route exact path={RoutePaths.root.doc_dtl} component={DocDtl}/>
      <Route exact path={RoutePaths.root.doc_const_category} component={DocDtl}/>
      <Route exact path={RoutePaths.root.cal} component={Cal}/>
      <Route exact path={RoutePaths.root.schedule} component={Schedule}/>
      <Route exact path={RoutePaths.root.pic_list} component={PicList}/>
    </Switch>
  );
};

export default PageRoutesAuthenticated;

import React from 'react';
import ReactDOM from "react-dom";
import App from "./pc/App";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import {GlobalProvider} from "./Global";
import {CookiesProvider} from "react-cookie";

ReactDOM.render(
  <GlobalProvider>
    <CookiesProvider>
      <App/>
    </CookiesProvider>
  </GlobalProvider>,
  document.getElementById('app')
);

import React from 'react';
import {BrowserRouter} from "react-router-dom";
import PageRoutes from "./PageRoutes";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../css/App.scss'

export const App = () => {

    return (
        <main>
            <BrowserRouter>
                <PageRoutes/>
            </BrowserRouter>
        </main>
    );
}

export default App;
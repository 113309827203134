import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp, faFileDownload, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone
} from "react-bootstrap-table2-paginator";
import {Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import overlayFactory from 'react-bootstrap-table2-overlay';

export const MdDatatable = ({keyField, listData, columns, loading, onTableChange, page, sizePerPage, totalSize, showPagenation, linkToAdd, noDataIndication}) => {

  const init1 = useRef(true);

  const [paginationOption, setPaginationOption] = useState({totalSize: 0});
  const [data, setData] = useState([]);

  useEffect(() => {

    if (init1.current) {
      init1.current = false;
      return;
    }

    const iPaginationOption = {
      bootstrap4: true,
      page: page ? page : 1,
      sizePerPage: sizePerPage ? sizePerPage : 50,
      totalSize: listData ? listData.count : 0,
      paginationTotalRenderer: (from, to, size) => {
        return (
          <span className="react-bootstrap-table-pagination-total">
        全 {size} 件中 {from} ～ {to} 件表示
        </span>
        )
      },
      showTotal: true,
      withFirstAndLast: true,
      alwaysShowAllBtns: true,
      firstPageText: '<<',
      prePageText: '<',
      nextPageText: '>',
      lastPageText: '>>'
    };
    setPaginationOption(iPaginationOption);

    if (listData.results) {
      setData(listData.results);
    }
  }, [listData])

  const NoDataIndication = () => (
    <span>登録情報はありません</span>
  );

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOption)}>
        {
          ({
             paginationProps,
             paginationTableProps
           }) => (
            <div>
              <Row>
                {showPagenation &&
                <Col sm="3" style={{marginTop: ".5rem"}}>
                  <PaginationTotalStandalone {...paginationProps} />
                </Col>
                }
                {showPagenation &&
                <Col sm="6" className="justify-content-md-center">
                  <PaginationListStandalone {...paginationProps} />
                </Col>
                }
                {linkToAdd &&
                <Col sm="3" className="text-right">
                  <Button as={Link} variant="add" to={linkToAdd}>
                    <FontAwesomeIcon icon={faPlusCircle} size="lg"/>
                    追加
                  </Button>
                </Col>
                }
              </Row>
              <BootstrapTable
                remote
                responsive
                keyField={keyField}
                classes="dm-datatable"
                striped={true}
                data={data}
                columns={columns}
                onTableChange={onTableChange}
                {...paginationTableProps}
                loading={loading}
                overlay={overlayFactory({spinner: true, styles: {overlay: (base) => ({...base, background: 'rgba(255, 255, 255, 0.5)'})}})}
                noDataIndication={() => <NoDataIndication/>}
              />
              {showPagenation && <PaginationListStandalone {...paginationProps} />}
              {showPagenation && <PaginationTotalStandalone {...paginationProps} />}
            </div>
          )
        }
      </PaginationProvider>
    </>
  )
};

export default MdDatatable;

export function sortCaret(order, column) {
  if (order === "asc") {
    return (
      <>
        <FontAwesomeIcon icon={faCaretUp} size="lg" style={{marginLeft: '5px'}}/>
      </>
    )
  } else if (order === "desc") {
    return (
      <>
        <FontAwesomeIcon icon={faCaretDown} size="lg" style={{marginLeft: '5px'}}/>
      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}

export const InitialLoading = ({loadingCss}) => {

  return (
    <div className={loadingCss}>
      <div className="_loading_overlay_spinner css-50etie">
        <svg viewBox="25 25 50 50">
          <circle style={{strokeWidth: "2", fill: "none", strokeMiterlimit: "10"}} cx="50" cy="50" r="20"></circle>
        </svg>
      </div>
    </div>
  )
}
import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory, withRouter} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {downloadAPI, downloadAPIByPost, getAPI, postAPI} from "../APIUtil";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AppNav from "./AppNav";
import {Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt, faCamera, faFile, faFileDownload, faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  addField,
  dateformat,
  dateformatJPN,
  dateformatJPNByType,
  dateformatJPNYYYYMMDD, finishLoadingBtn,
  linkFormatter,
  parseDate,
  setFocusCalendar, startLoadingBtn,
  useDebounce
} from "../CMUtil";
import MdDatatable, {InitialLoading, sortCaret} from "../MdDataTable";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import {useLocation} from "react-router";
import BootstrapTable from "react-bootstrap-table-next";

export const DocList = () => {

  const history = useHistory();
  const iniSearch = useRef(true);
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [loadingCss, setLoadingCss] = useState("loading_panel_init");

  const [loading, setLoading] = useState(true);
  const [locPage, setLocPage] = useState(null);
  const [locSizePerPage, setLocSizePerPage] = useState(1000);
  const [locTotalSize, setLocTotalSize] = useState(null);
  const [locSortField, setLocSortField] = useState("doc_update_date");
  const [locSortOrder, setLocSortOrder] = useState("desc");


  const [bookId, setBookId] = useState("");
  const debouncedBookId = useDebounce(bookId, 500, setLocPage);
  const [bookNo, setBookNo] = useState("");
  const debouncedBookNo = useDebounce(bookNo, 500, setLocPage);
  const [bookName, setBookName] = useState("");
  const debouncedBookName = useDebounce(bookName, 500, setLocPage);
  const [constId, setConstId] = useState("");
  const debouncedConstId = useDebounce(constId, 500, setLocPage);
  const [designId, setDesignId] = useState("");
  const debouncedDesignId = useDebounce(designId, 500, setLocPage);
  const [fileName, setFileName] = useState("");
  const debouncedFileName = useDebounce(fileName, 500, setLocPage);
  const [category, setCategory] = useState("");
  const debouncedCategory = useDebounce(category, 500, setLocPage);
  const [docCreateUserId, setDocCreateUserId] = useState("");
  const debouncedDocCreateUserId = useDebounce(docCreateUserId, 500, setLocPage);
  const [docCreateDateFrom, setDocCreateDateFrom] = useState("");
  const debouncedDocCreateDateFrom = useDebounce(docCreateDateFrom, 500, setLocPage);
  const [docCreateDateTo, setDocCreateDateTo] = useState("");
  const debouncedDocCreateDateTo = useDebounce(docCreateDateFrom, 500, setLocPage);
  const [docUpdateUserId, setDocUpdateUserId] = useState("");
  const debounceddocUpdateUserId = useDebounce(docUpdateUserId, 500, setLocPage);
  const [docUpdateDateFrom, setDocUpdateDateFrom] = useState("");
  const debouncedDocUpdateDateFrom = useDebounce(docUpdateDateFrom, 500, setLocPage);
  const [docUpdateDateTo, setDocUpdateDateTo] = useState("");
  const debouncedDocUpdateDateTo = useDebounce(docUpdateDateTo, 500, setLocPage);

  const [listData, setListData] = useState([]);
  const [selectedDocIdList, setSelectedDocIdList] = useState([]);

  const [constSelected, setConstSelected] = useState([]);
  const [designSelected, setDesignSelected] = useState([]);
  const [docCreateSelected, setDocCreateSelected] = useState([]);
  const [docUpdateSelected, setDocUpdateSelected] = useState([]);

  const [downloadBtnDisable, setDownloadBtnDisable] = useState(true);

  // 項目定義
  const columns = [
    {
      dataField: 'doc_id',
      text: 'ID',
      hidden: true,
      sort: false,
      headerStyle: {width: '80px'}
    },
    {
      dataField: 'book_name',
      text: '現場名',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{(row.book_no ? row.book_no + ' ' : '') + (row.book_name ? row.book_name : '')}</span>
      )
    },
    {
      dataField: 'book_id',
      text: '',
      classes: 'tbl-col icon-link',
      headerStyle: {width: '80px'},
      formatter: (cell, row) => (
        <span className="d-inline-block"
              onClick={() => window.open("/pic_list/" + cell)}>
          <FontAwesomeIcon icon={faCamera} size="1x"/>
        </span>
      )
    },
    {
      dataField: 'category',
      text: '区分',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '60px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'file_name',
      text: 'ファイル名',
      classes: 'tbl-col link',
      sort: true,
      headerStyle: {width: '200px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <div><a onClick={() => onClickLinkToDetail(row.doc_id)}>{cell}</a></div>
      )
    },
    {
      dataField: 'const_name',
      text: '施工業者',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '100px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
    },
    {
      dataField: 'ext_design_name',
      text: '設計事務所',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '60px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'doc_create_date',
      text: '登録日',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '65px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      )
    },
    {
      dataField: 'doc_update_date',
      text: '更新',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '65px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      )
    }
  ];

  // 初期化
  useEffect(() => {
    const init = async () => {
      let doc_search_condition = localStorage.getItem("doc_search_condition");
      if (query.get("book_id")) {
        const res = await getAPI("tr_book/" + query.get("book_id"));
        setBookNo(res.book_no);
        setBookName(res.book_name);
        setLocPage(1);

      } else if (doc_search_condition) {
        let s = JSON.parse(doc_search_condition);
        s.page ? setLocPage(s.page) : setLocPage(1);
        s.size_per_page ? setLocSizePerPage(s.size_per_page) : null;
        s.sort_field ? setLocSortField(s.sort_field) : null;
        s.sort_order ? setLocSortOrder(s.sort_order) : null;
        s.book_no ? setBookNo(s.book_no) : null;
        s.book_name ? setBookName(s.book_name) : null;
        s.const_id ? setConstId(s.const_id) : null;
        if (s.const_id) {
          let obj = await getAPI("tr_const/" + s.const_id);
          setConstSelected({name: obj.name, id: obj.const_id});
        }
        s.design_id ? setDesignId(s.design_id) : null;
        if (s.design_id) {
          let obj = await getAPI("tr_design/" + s.design_id);
          setDesignSelected({name: obj.name, id: obj.design_id});
        }
        s.file_name ? setFileName(s.file_name) : null;
        s.category ? setCategory(s.category) : null;
        s.doc_create_user_id ? setDocCreateUserId(s.doc_create_user_id) : null;
        s.doc_create_date_from ? setDocCreateDateFrom(s.doc_create_date_from) : null;
        s.doc_create_date_to ? setDocCreateDateTo(s.doc_create_date_to) : null;
        s.doc_update_user_id ? setDocUpdateUserId(s.doc_update_user_id) : null;
        s.doc_update_date_from ? setDocUpdateDateFrom(s.doc_update_date_from) : null;
        s.doc_update_date_to ? setDocUpdateDateTo(s.doc_update_date_to) : null;
        s.address ? setAddress(s.address) : null;
        s.note1 ? setNote(s.note) : null;

      } else {
        setLocPage(1);

      }
    }
    init();
  }, []);

// 検索処理
  useEffect(() => {
    if (iniSearch.current) {
      iniSearch.current = false;
      return;
    }
    const update = async () => {

      let condition = {};
      addField(condition, "page", locPage);
      addField(condition, "size_per_page", locSizePerPage);
      addField(condition, "sort_field", locSortField);
      addField(condition, "sort_order", locSortOrder);
      addField(condition, "book_id", bookId);
      addField(condition, "book_no", bookNo);
      addField(condition, "book_name", bookName);
      addField(condition, "const_id", constId);
      addField(condition, "design_id", designId);
      addField(condition, "file_name", fileName);
      addField(condition, "category", category);
      addField(condition, "doc_create_user_id", docCreateUserId);
      addField(condition, "doc_create_date_from", dateformat(docCreateDateFrom));
      addField(condition, "doc_create_date_to", dateformat(docCreateDateTo));
      addField(condition, "doc_update_user_id", docUpdateUserId);
      addField(condition, "doc_update_date_from", dateformat(docUpdateDateFrom));
      addField(condition, "doc_update_date_to", dateformat(docUpdateDateTo));
      addField(condition, "doc_list_search", "doc_list_search");

      localStorage.setItem("doc_search_condition", JSON.stringify(condition));
      const res = await getAPI("tr_doc_search_view", condition);
      setListData(res.results);
      setLoading(false);
      setLoadingCss("loading_panel_fadeout");
    }
    update();
  }, [
    locPage,
    locSizePerPage,
    locSortField,
    locSortOrder,
    debouncedBookId,
    debouncedBookNo,
    debouncedBookName,
    debouncedConstId,
    debouncedDesignId,
    debouncedFileName,
    debouncedCategory,
    debouncedDocCreateUserId,
    debouncedDocCreateDateFrom,
    debouncedDocCreateDateTo,
    debounceddocUpdateUserId,
    debouncedDocUpdateDateFrom,
    debouncedDocUpdateDateTo,
  ]);

  const onTableChange = (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
    if ("pagination" === type) {
      setLocPage(page);
    }
    if ("sort" === type) {
      setLocSortField(sortField);
      setLocSortOrder(sortOrder);
    }
  }

  const onClickLinkToDetail = (doc_id) => {
    history.push("/doc/" + doc_id);
  }

  const onSearchConst = async (query) => {
    let searchCondition = {
      name: query
    }
    const res = await getAPI("ms_const_typeahead_view", searchCondition);
    let locOptions = [];
    res.results.map((result) => {
      locOptions.push({name: result.name, id: result.const_id});
    });
    return locOptions;
  }

  const handleChangeConst = (value) => {
    setConstSelected(value ? value : "");
    setConstIdSearch(value ? value.id : "");
  }

  const onSearchDesign = async (query) => {
    let searchCondition = {
      name: query
    }
    const res = await getAPI("ms_design_typeahead_view", searchCondition);
    let locOptions = [];
    res.results.map((result) => {
      locOptions.push({name: result.name, id: result.design_id});
    });
    return locOptions;
  }

  const handleChangeDesign = (value) => {
    setDesignSelected(value ? value : "");
    setDesignIdSearch(value ? value.id : "");
  }

  const onSearchDocCreateUser = async (query) => {
    let searchCondition = {
      name: query
    }
    const res = await getAPI("ms_user_typeahead_view", searchCondition);
    let locOptions = [];
    res.results.map((result) => {
      locOptions.push({name: result.last_name + " " + result.first_name, id: result.user_id});
    });
    return locOptions;
  }

  const handleChangeDocCreateUser = (value) => {
    setDocCreateSelected(value ? value : "");
    setDocCreateUserIdSearch(value ? value.id : "");
  }

  const onSearchDocUpdateUser = async (query) => {
    let searchCondition = {
      name: query
    }
    const res = await getAPI("ms_user_typeahead_view", searchCondition);
    let locOptions = [];
    res.results.map((result) => {
      locOptions.push({name: result.last_name + " " + result.first_name, id: result.user_id});
    });
    return locOptions;
  }

  const handleChangeDocUpdateUser = (value) => {
    setDocUpdateSelected(value ? value : "");
    setDocUpdateUserIdSearch(value ? value.id : "");
  }

  const onClickClear = () => {

    setBookNoSearch("");
    setBookNameSearch("");
    setConstIdSearch("");
    setConstSelected(null);
    setDesignIdSearch("");
    setDesignSelected(null);
    setCategorySearch("");
    setFileNameSearch("");
    setDocCreateDateFromSearch("");
    setDocCreateDateToSearch("");
    setDocUpdateDateFromSearch("");
    setDocUpdateDateToSearch("");
    setDocCreateUserIdSearch("");
    setDocCreateSelected(null);
    setDocUpdateUserIdSearch("");
    setDocUpdateSelected(null);

    localStorage.removeItem("doc_search_condition");
  }

  const onClickDownload = async () => {

    startLoadingBtn("btn-download", "fifth");
    let condition = {};
    addField(condition, "doc_id_list", selectedDocIdList);
    await downloadAPIByPost("download_by_doc_ids", condition);
    finishLoadingBtn("btn-download");
  }

  const setBookNoSearch = (value) => {
    setLocPage(1);
    setBookNo(value);
  }

  const setBookNameSearch = (value) => {
    setLocPage(1);
    setBookName(value);
  }

  const setConstIdSearch = (value) => {
    setLocPage(1);
    setConstId(value);
  }

  const setDesignIdSearch = (value) => {
    setLocPage(1);
    setDesignId(value);
  }

  const setFileNameSearch = (value) => {
    setLocPage(1);
    setFileName(value);
  }

  const setCategorySearch = (value) => {
    setLocPage(1);
    setCategory(value);
  }

  const setDocCreateDateFromSearch = (value) => {
    setLocPage(1);
    setDocCreateDateFrom(value);
  }

  const setDocCreateDateToSearch = (value) => {
    setLocPage(1);
    setDocCreateDateTo(value);
  }

  const setDocUpdateDateFromSearch = (value) => {
    setLocPage(1);
    setDocUpdateDateFrom(value);
  }

  const setDocUpdateDateToSearch = (value) => {
    setLocPage(1);
    setDocUpdateDateTo(value);
  }

  const setDocCreateUserIdSearch = (value) => {
    setLocPage(1);
    setDocCreateUserId(value);
  }

  const setDocUpdateUserIdSearch = (value) => {
    setLocPage(1);
    setDocUpdateUserId(value);
  }

  const onSelectRow = (row, isSelect, rowIndex, e) => {
    let locDocId = row.doc_id;
    let locSelectedDocIdList = JSON.parse(JSON.stringify(selectedDocIdList));
    if (isSelect) {
      locSelectedDocIdList.push(locDocId);
    } else {
      let index = locSelectedDocIdList.indexOf(locDocId);
      locSelectedDocIdList.splice(index, 1);
    }
    setSelectedDocIdList(locSelectedDocIdList);

    if (locSelectedDocIdList.length > 0 && locSelectedDocIdList.length <= 40) {
      setDownloadBtnDisable(false);
    } else {
      setDownloadBtnDisable(true);
    }
  }

  const onSelectRowAll = (isSelect, rows, e) => {
    let locSelectedDocIdList = [];
    if (isSelect) {
      listData.map((row) => {
        locSelectedDocIdList.push(row.doc_id);
      });
    }
    setSelectedDocIdList(locSelectedDocIdList);

    if (locSelectedDocIdList.length > 0 && locSelectedDocIdList.length <= 40) {
      setDownloadBtnDisable(false);
    } else {
      setDownloadBtnDisable(true);
    }
  }

  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: onSelectRow,
    onSelectAll: onSelectRowAll,
    bgColor: "#FBF0C7"
  };

  return (
    <>
      <AppNav/>
      <div className="h1">
        <h1>
          ドキュメント
        </h1>
      </div>
      <Card className="card-search">
        <Container className="container-main-detail">
          <Form.Group as={Row}>
            <Form.Label column sm="1">工事番号</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={bookNo} onChange={e => setBookNoSearch(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">現場名</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={bookName} onChange={e => setBookNameSearch(e.target.value)}/>
            </Col>
            <Col sm="1">
              <Button variant="clear" onClick={e => onClickClear()}>条件クリア</Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">施工業者</Form.Label>
            <Col sm="4">
              <AsyncSelect
                isClearable
                className="async-select"
                cacheOptions
                defaultOptions
                value={constSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={onSearchConst}
                onChange={handleChangeConst}
                placeholder=""
              />
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">設計事務所</Form.Label>
            <Col sm="4">
              <AsyncSelect
                isClearable
                className="async-select"
                cacheOptions
                defaultOptions
                value={designSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={onSearchDesign}
                onChange={handleChangeDesign}
                placeholder=""
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">ファイル名</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={fileName} onChange={e => setFileName(e.target.value)}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">区分</Form.Label>
            <Col sm="10">
              <Form.Check
                inline
                id="category1"
                type="radio"
                name="category"
                label="見積り"
                value="見積り"
                onChange={e => setCategorySearch(e.target.value)}
                checked={category === "見積り"}/>
              <Form.Check
                inline
                id="category2"
                type="radio"
                name="category"
                label="出来高調書"
                value="出来高調書"
                onChange={e => setCategorySearch(e.target.value)}
                checked={category === "出来高調書"}/>
              <Form.Check
                inline
                id="category3"
                type="radio"
                name="category"
                label="請求書"
                value="請求書"
                onChange={e => setCategorySearch(e.target.value)}
                checked={category === "請求書"}/>
              <Form.Check
                inline
                id="category4"
                type="radio"
                name="category"
                label="保証書"
                value="保証書"
                onChange={e => setCategorySearch(e.target.value)}
                checked={category === "保証書"}/>
              <Form.Check
                inline
                id="category5"
                type="radio"
                name="category"
                label="意匠図"
                value="意匠図"
                onChange={e => setCategorySearch(e.target.value)}
                checked={category === "意匠図"}/>
              <Form.Check
                inline
                id="category6"
                type="radio"
                name="category"
                label="構造図"
                value="構造図"
                onChange={e => setCategorySearch(e.target.value)}
                checked={category === "構造図"}/>
              <Form.Check
                inline
                id="category7"
                type="radio"
                name="category"
                label="写真"
                value="写真"
                onChange={e => setCategorySearch(e.target.value)}
                checked={category === "写真"}/>
              <Form.Check
                inline
                id="category8"
                type="radio"
                name="category"
                label="その他"
                value="その他"
                onChange={e => setCategorySearch(e.target.value)}
                checked={category === "その他"}/>
            </Col>
          </Form.Group>


          <Form.Group as={Row}>
            <Form.Label column sm="1">作成日</Form.Label>
            <Col sm="4" className="input-group">
              <DatePicker id="docCreateDateFrom" selected={docCreateDateFrom}
                          onChange={(date) => setDocCreateDateFromSearch(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'docCreateDateFrom')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
              <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
              <DatePicker id="docCreateDateTo" selected={docCreateDateTo}
                          onChange={(date) => setDocCreateDateToSearch(date)}
                          dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'docCreateDateTo')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">更新日</Form.Label>
            <Col sm="4" className="input-group">
              <DatePicker id="docUpdateDateFrom" selected={docUpdateDateFrom}
                          onChange={(date) => setDocUpdateDateFromSearch(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'docUpdateDateFrom')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
              <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
              <DatePicker id="docUpdateDateTo" selected={docUpdateDateTo}
                          onChange={(date) => setDocUpdateDateToSearch(date)}
                          dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'docUpdateDateTo')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">作成者</Form.Label>
            <Col sm="4">
              <AsyncSelect
                isClearable
                className="async-select"
                cacheOptions
                defaultOptions
                value={docCreateSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={onSearchDocCreateUser}
                onChange={handleChangeDocCreateUser}
                placeholder=""
              />
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">更新者</Form.Label>
            <Col sm="4">
              <AsyncSelect
                isClearable
                className="async-select"
                cacheOptions
                defaultOptions
                value={docUpdateSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={onSearchDocUpdateUser}
                onChange={handleChangeDocUpdateUser}
                placeholder=""
              />
            </Col>
          </Form.Group>

        </Container>
      </Card>

      <Container className="container-main-list">
        <Row>
          <Col sm="12" className="text-right">
            <Button variant="add" id="btn-download" disabled={downloadBtnDisable} style={{marginRight: "2rem"}}
                    onClick={e => onClickDownload()}>
                <span>
                <FontAwesomeIcon icon={faFileDownload} size="lg"/>一括取得
                </span>
            </Button>
            <Button as={Link} variant="add" to="/doc/">
              <FontAwesomeIcon icon={faPlusCircle} size="lg"/>
              追加
            </Button>
          </Col>
        </Row>

        <BootstrapTable
          remote
          responsive
          keyField="doc_id"
          classes="dm-datatable"
          selectRow={selectRowProp}
          striped={true}
          data={listData}
          columns={columns}
          onTableChange={onTableChange}
          loading={loading}
        />
      </Container>    </>
  );
}

export default withRouter(DocList);
import React, {useContext, useEffect, useRef, useState} from 'react';
import {withRouter} from "react-router";
import 'react-toastify/dist/ReactToastify.css';
import {Col, Container, Form, Modal, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {
  addField,
  dateformatJPNByType,
  nvl,
  parseDate,
  useDebounce
} from "../CMUtil";
import {downloadImageSpAPI, getAccessToken, getAPI, getSpAPI, postSpAPI, putSpAPI} from "../APIUtil";
import {GlobalContext} from "../Global";
import {Document, Page, pdfjs} from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
export const PicList = ({match: {params: {p_book_id}}}) => {

  const {loginUser, setLoginUser} = useContext(GlobalContext);

  const iniBookId = useRef(true);

  const [bookNo, setBookNo] = useState("");
  const [bookName, setBookName] = useState("");
  const [bookId, setBookId] = useState("");
  const [picCount, setPicCount] = useState(0);

  const [docList, setDocList] = useState([]);

  const [modalImageShow, setModalImageShow] = useState(false);
  const [modalUrlImage, setModalUrlImage] = useState(""); //JPEG用
  const [modalNumPages, setModalNumPages] = useState([]); //PDF用
  const [modalDocId, setModalDocId] = useState("");
  const [modalDocType, setModalDocType] = useState("JPEG");
  const [modalDocCreateUserLastName, setModalDocCreateUserLastName] = useState("");
  const [modalDocCreateDate, setModalDocCreateDate] = useState("");

  //INITIALIZE
  useEffect(() => {

    setBookId(p_book_id);

  }, []);


  useEffect(() => {

    if (iniBookId.current) {
      iniBookId.current = false;
      return;
    }

    const update = async () => {

      let obj = await getAPI("tr_book/" + bookId);
      setBookNo(obj.book_no);
      setBookName(obj.book_name);

      let param = {
        book_id: bookId,
        sp_only: "sp",
        sort_field: "doc_create_date",
        sort_order: "desc"
      }
      let res = await getSpAPI("tr_doc_search_view", param);
      let locDocList = await createImageUrlList(res.results);
      setDocList(locDocList);
      setPicCount(res.count);
    }

    update();

  }, [bookId]);

  // 検索処理
  async function createImageUrlList(docList) {

    let newDocList = [];
    let cnt = 0;
    $("#loading-count-current").html(0);
    $("#loading-count-total").html(docList.length);
    $("#loading-background").css("display", "block");
    $("#loading-display").css("display", "block");
    for (const doc of docList) {
      const imgBlob = await downloadImageSpAPI("thumbnail/pic/" + doc.doc_id);
      const url = (window.URL || window.webkitURL).createObjectURL(imgBlob);
      doc['url'] = url;
      newDocList.push(doc);
      cnt++;
      $("#loading-count-current").html(cnt);
    }
    $("#loading-display").css("display", "none");
    $("#loading-background").css("display", "none");
    return newDocList;
  }

  const onClickModalImageClose = () => setModalImageShow(false);

  const onClickModalImage = async (doc) => {

    let locDocType = doc.file_name.substring(doc.file_name.lastIndexOf(".") + 1).toLowerCase();
    let locNumPages = [];
    let locUrlImage = "";
    let locDispDocType = ""
    let locDocId = doc.doc_id;

    if ("pdf" === locDocType || "docx" === locDocType || "doc" === locDocType) {
      let res = await getSpAPI("download_pdf_num_pages/" + locDocId);
      for (let i = 1; i <= res.num_pages; i++) {
        locNumPages.push(i);
      }
      locDispDocType = "pdf";

    } else if ("jpeg" === locDocType || "jpg" === locDocType || "png" === locDocType) {
      const imgBlob = await downloadImageSpAPI("download/pic/" + locDocId);
      const url = (window.URL || window.webkitURL).createObjectURL(imgBlob);
      locUrlImage = url;
      locDispDocType = "img";
    }

    setModalDocId(locDocId);
    setModalNumPages(locNumPages);
    setModalUrlImage(locUrlImage);
    setModalDocType(locDocType);
    setModalDocCreateUserLastName(doc.doc_create_user_last_name);
    setModalDocCreateDate(doc.doc_create_date);
    setModalImageShow(true);
  }

  return (
    <>

      <Container id="mb-history"
                 className="container-main-detail"
                 style={{marginTop: "0.2rem", paddingRight: "0.1rem", paddingLeft: "0.1rem"}}>

        <Row>
          <Col>
            <h1>
              {bookNo}&nbsp;{bookName}
            </h1>
          </Col>
        </Row>

        <div className="m-3">
          {docList.map((doc, index) => (
            <div className="float-left text-center m-2" key={doc.doc_id} onClick={e => onClickModalImage(doc)}
                 style={{width: "210px", cursor: "pointer"}}>
              <div className="text-left">
                {dateformatJPNByType(parseDate(doc.doc_create_date), 'month')}月
                {dateformatJPNByType(parseDate(doc.doc_create_date), 'date')}日&nbsp;
                {doc.doc_update_user_last_name}
              </div>
              <img
                src={doc.url}
                style={{display: "block", width: "200px", margin: "auto", border: "solid 1px #CCCCCC"}}/>
            </div>
          ))}
        </div>
      </Container>

      <Modal size="lg" show={modalImageShow} onHide={onClickModalImageClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body id="sp-camera-modal" style={{padding: "0.2rem"}}>
          <Row>
            <Col sm="2">作成者</Col>
            <Col sm="10">{modalDocCreateUserLastName}</Col>
          </Row>
          <Row className="mb-2">
            <Col sm="2">作成者</Col>
            <Col sm="10">
              {dateformatJPNByType(parseDate(modalDocCreateDate), 'year')}年
              {dateformatJPNByType(parseDate(modalDocCreateDate), 'month')}月
              {dateformatJPNByType(parseDate(modalDocCreateDate), 'date')}日&nbsp;
              {dateformatJPNByType(parseDate(modalDocCreateDate), 'hours')}時
              {dateformatJPNByType(parseDate(modalDocCreateDate), 'minutes')}分
            </Col>
          </Row>
          {
            modalDocType === 'pdf' &&
            <Document file={{
              url: '/api/download_pdf_by_doc_id/' + modalDocId,
              httpHeaders: {'X-AUTH-TOKEN': getAccessToken()}
            }}>
              {
                modalNumPages.map((page, idx) => {
                  return <Page key={page} pageNumber={page}/>
                })
              }
            </Document>
          }
          {
            modalDocType !== 'pdf' &&
            <img src={modalUrlImage}
                 style={{marginLeft: "auto", marginRight: "auto", display: "block", width: "100%"}}/>
          }
        </Modal.Body>
      </Modal>

      <div
        id="loading-background"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          opacity: "0.5",
          backgroundColor: "#FFFFFF",
          zIndex: "10",
          display: "none"
        }}>
      </div>
      <div
        id="loading-display"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          zIndex: "11",
          textAlign: "center",
          fontSize: "4rem",
          paddingTop: "12rem",
          fontWeight: "bold",
          color: "#666666",
          display: "none"
        }}>
        <span id="loading-count-current"></span>／<span id="loading-count-total"></span><br/>
        <span style={{fontSize: "1.5rem"}}>ダウンロード中</span>
      </div>

    </>
  );
}

export default withRouter(PicList);
import {AUTHORIZATION_SERVER, CON_API_URL} from "./Const";
import {dateformatYYYYMMDDHHMMSS, getDownloadFileName} from "./CMUtil";

let updatingAccessToken = false;

// スリープ関数(アクセストークン再取得用)
function sleepByPromise(sec) {
  return new Promise(resolve => setTimeout(resolve, sec * 1000));
}

// アクセストークン再取得(認証サーバ)
const updateAccessToken = async () => {

  let auth_server = window.location.protocol + "//" + AUTHORIZATION_SERVER;

  let const_manager_token = localStorage.getItem("const_manager_token");
  if (!const_manager_token) {
    //Refreshトークンなしの場合は終了
    location.href = auth_server + "/?error=refresh_token_not_found";
  }

  const_manager_token = JSON.parse(const_manager_token);
  let refresh_token = const_manager_token['refresh_token'];

  let data = {refresh_token: refresh_token};

  let formData = new FormData();
  formData.append('refresh_token', refresh_token);

  const res = await fetch(auth_server + "/issue_access_token/", {
    method: 'POST',
    mode: 'cors',
    body: formData
  });
  let access_result = await res.json();
  if (access_result.result == "OK") {
    let token = {};
    token["refresh_token"] = access_result.refresh_token;
    token["access_token"] = access_result.access_token;
    token["user_id"] = access_result.user_id;
    token["exp"] = access_result.exp;
    localStorage.setItem("const_manager_token", JSON.stringify(token));
    updatingAccessToken = false;
console.log("トークン更新完了");
  } else {
    //ログイン画面へ遷移
    location.href = auth_server + "/?error=refresh_token_expired";
  }
}

const TOKEN_EXPIRED = "TOKEN_EXPIRED";
const TOKEN_NOT_FOUND = "TOKEN_NOT_FOUND";
const TOKEN_VALID = "TOKEN_VALID";
export const validateAccessToken = () => {
  let const_manager_token = localStorage.getItem("const_manager_token");
  if (!const_manager_token) {
    console.log("const_manager_tokenが見つかりません")
    return TOKEN_NOT_FOUND;
  }

  const_manager_token = JSON.parse(const_manager_token);
  let exp = const_manager_token['exp'];
  let currentDatetime = new Date();
  currentDatetime.setMinutes(currentDatetime.getMinutes() + 5); //期限切れ5分以内は無効とする

  // console.log("exp=" + exp);
  // console.log("cur=" + dateformatYYYYMMDDHHMMSS(currentDatetime));
  // if (exp < dateformatYYYYMMDDHHMMSS(currentDatetime)) {
  //   // console.log("期限切れ");
  //   return TOKEN_EXPIRED;
  // }

  let access_token = const_manager_token['access_token'];
  if (!access_token) {
    return TOKEN_NOT_FOUND;
  }

  return TOKEN_VALID;
}

export const getAccessToken = () => {
  let const_manager_token = localStorage.getItem("const_manager_token");
  if (!const_manager_token) {
    return false;
  }

  const_manager_token = JSON.parse(const_manager_token);
  let access_token = const_manager_token['access_token'];
  if (!access_token) {
    return false;
  }

  return access_token;
}

// アクセストークン再取得
export const inactivateRefreshToken = async () => {

  let const_manager_token = localStorage.getItem("const_manager_token");
  const_manager_token = JSON.parse(const_manager_token);

  if (!const_manager_token) {
    //Refreshトークンなしの場合は終了
    updatingAccessToken = false;
    return;
  }

  let refresh_token = const_manager_token['refresh_token'];
  let data = {refresh_token: refresh_token};
  let auth_server = location.protocol + "//" + AUTHORIZATION_SERVER;

  let formData = new FormData();
  formData.append('refresh_token', refresh_token);

  await fetch(auth_server + "/inactivate_refresh_token/", {
    method: 'POST',
    mode: 'cors',
    body: formData
  });
}

function getAPiUrl(isSp) {
  if (!isSp) {
    return CON_API_URL;
  } else {
    return "/sp" + CON_API_URL;
  }
}

export const getSpAPI = async (url, data = []) => {
  return getAPI(url, data, true);
}

export const getAPI = async (url, data = [], isSp = false) => {

  let filteredData = {}
  Object.keys(data).map(function (key) {
    filteredData[key] = data[key];
  });

  let query = '';
  if (filteredData) {
    query = '?' + new URLSearchParams(filteredData);
  }

  let responseResult = "NG";
  let returnValue = null;
  let retryCount = 0;
  let status = 200;
  while ("OK" !== responseResult && retryCount < 3) {

    let validateTokenResult = validateAccessToken();
    if (TOKEN_NOT_FOUND === validateTokenResult) {
        let auth_server = location.protocol + "//" + AUTHORIZATION_SERVER + "?error=refresh_token_expired";
        location.href = auth_server;
    }

    let accessToken = getAccessToken();
    const res = await fetch(getAPiUrl(isSp) + url + query, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        "X-AUTH-TOKEN": accessToken,
      }
    });

    if (res.status === 200) {
      //成功時は終了
      returnValue = await res.json();
      responseResult = "OK";

    } else {
      //エラーの場合
      returnValue = await res.json();
      if(returnValue['ERROR_CODE'] === "TOKEN_EXPIRED"){
        if (updatingAccessToken) {
console.log("トークン更新中 ...");
          await sleepByPromise(2);
        } else {
console.log("トークン更新 ...");
          updatingAccessToken = true;
console.log("トークン更新開始");
          await updateAccessToken();
        }
        retryCount++;
        continue;
      }else{
        //その他エラーの場合
        // let auth_server = location.protocol + "//" + AUTHORIZATION_SERVER + "?" + res.status;
        // location.href = auth_server;
        break;
      }
    }

    if (status === 403) {
      // let auth_server = location.protocol + "//" + AUTHORIZATION_SERVER;
      // location.href = auth_server;
    }
  }

  return returnValue;
}

export const postSpAPI = async (url, data = []) => {
  return postAPI(url, data, true);
}

export const postAPI = async (url, data = [], isSp = false) => {

  let responseResult = "NG";
  let returnValue = null;
  let retryCount = 0;
  while ("OK" !== responseResult && retryCount < 3) {

    let validateTokenResult = validateAccessToken();
    if (TOKEN_NOT_FOUND === validateTokenResult) {
      console.log("トークンがありません");
      break;

    } else if (TOKEN_EXPIRED === validateTokenResult) {
      if (updatingAccessToken) {
        // console.log("トークン更新中.....");
        await sleepByPromise(2);
      } else {
        // console.log("トークン更新");
        await updateAccessToken();
      }
      retryCount++;
      continue;
    }

    let accessToken = getAccessToken();
    const res = await fetch(getAPiUrl(isSp) + url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "X-AUTH-TOKEN": accessToken
      },
      body: JSON.stringify(data)
    });

    if (res.status === 200) {
      //成功時は終了
      returnValue = res.json();
      responseResult = "OK";

    } else if (res.status === 400) {
      //フォームエラー
      const fieldErrors = await res.json();
      throw fieldErrors;

    } else {
      //それ以外は処理終了
      break;
    }

    retryCount++;
  }

  return returnValue;
}

export const postFileUploadSpAPI = async (url, data = []) => {
  return postFileUploadAPI(url, data, true);
}

export const postFileUploadAPI = async (url, data = [], isSp = false) => {

  let responseResult = "NG";
  let returnValue = null;
  let retryCount = 0;
  while ("OK" !== responseResult && retryCount < 3) {

    let validateTokenResult = validateAccessToken();
    if (TOKEN_NOT_FOUND === validateTokenResult) {
      console.log("トークンがありません");
      break;

    } else if (TOKEN_EXPIRED === validateTokenResult) {
      if (updatingAccessToken) {
        // console.log("トークン更新中.....");
        await sleepByPromise(2);
      } else {
        // console.log("トークン更新");
        await updateAccessToken();
      }
      retryCount++;
      continue;
    }

    let accessToken = getAccessToken();
    const res = await fetch(getAPiUrl(isSp) + url, {
      method: 'POST',
      headers: {
        "X-AUTH-TOKEN": accessToken,
      },
      body: data
    });

    if (res.status === 200) {
      //成功時は終了
      returnValue = res.json();
      responseResult = "OK";

    } else {
      //それ以外は処理終了
      break;
    }
    retryCount++;
  }

  return returnValue;
}

export const putSpAPI = async (url, data = []) => {
  return putAPI(url, data, true);
}

export const putAPI = async (url, data = [], isSp = false) => {

  let responseResult = "NG";
  let returnValue = null;
  let retryCount = 0;
  while ("OK" !== responseResult && retryCount < 3) {

    let validateTokenResult = validateAccessToken();
    if (TOKEN_NOT_FOUND === validateTokenResult) {
      console.log("トークンがありません");
      break;

    } else if (TOKEN_EXPIRED === validateTokenResult) {
      if (updatingAccessToken) {
        // console.log("トークン更新中.....");
        await sleepByPromise(2);
      } else {
        // console.log("トークン更新");
        await updateAccessToken();
      }
      retryCount++;
      continue;
    }

    let accessToken = getAccessToken();
    const res = await fetch(getAPiUrl(isSp) + url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        "X-AUTH-TOKEN": accessToken,
      },
      body: JSON.stringify(data)
    });

    if (res.status === 200) {
      //成功時は終了
      returnValue = res.json();
      responseResult = "OK";

    } else if (res.status === 400) {
      //フォームエラー
      const fieldErrors = await res.json();
      throw fieldErrors;

    } else {
      //それ以外は処理終了
      break;
    }
    retryCount++;
  }

  return returnValue;
}

export const patchSpAPI = async (url, data = []) => {
  return patchAPI(url, data, true);
}

export const patchAPI = async (url, data = [], isSp = false) => {

  let responseResult = "NG";
  let returnValue = null;
  let retryCount = 0;
  while ("OK" !== responseResult && retryCount < 3) {

    let validateTokenResult = validateAccessToken();
    if (TOKEN_NOT_FOUND === validateTokenResult) {
      console.log("トークンがありません");
      break;

    } else if (TOKEN_EXPIRED === validateTokenResult) {
      if (updatingAccessToken) {
        // console.log("トークン更新中.....");
        await sleepByPromise(2);
      } else {
        // console.log("トークン更新");
        await updateAccessToken();
      }
      retryCount++;
      continue;
    }

    let accessToken = getAccessToken();
    const res = await fetch(getAPiUrl(isSp) + url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        "X-AUTH-TOKEN": accessToken,
      },
      body: JSON.stringify(data)
    });

    if (res.status === 200) {
      //成功時は終了
      returnValue = res.json();
      responseResult = "OK";

    } else {
      //それ以外は処理終了
      break;
    }
    retryCount++;
  }

  return returnValue;
}

export const deleteSpAPI = async (url, data = []) => {
  return deleteAPI(url, data, true);
}

export const deleteAPI = async (url, data = [], isSp = false) => {

  let responseResult = "NG";
  let returnValue = null;
  let retryCount = 0;
  while ("OK" !== responseResult && retryCount < 3) {

    let validateTokenResult = validateAccessToken();
    if (TOKEN_NOT_FOUND === validateTokenResult) {
      console.log("トークンがありません");
      break;

    } else if (TOKEN_EXPIRED === validateTokenResult) {
      if (updatingAccessToken) {
        // console.log("トークン更新中.....");
        await sleepByPromise(2);
      } else {
        // console.log("トークン更新");
        await updateAccessToken();
      }
      retryCount++;
      continue;
    }

    let accessToken = getAccessToken();
    const res = await fetch(getAPiUrl(isSp) + url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        "X-AUTH-TOKEN": accessToken,
      },
      body: JSON.stringify(data)
    });

    if (res.status === 200 || res.status === 204) {
      //成功時は終了
      returnValue = res.ok;
      responseResult = "OK";

    } else {
      //それ以外は処理終了
      break;
    }
    retryCount++;
  }

  return returnValue;
}

export const downloadSpAPI = async (url, data = []) => {
  return downloadAPI(url, data, true);
}

export const downloadAPI = async (url, data = [], isSp = false) => {

  let filteredData = {}
  Object.keys(data).map(function (key) {
    if (data[key]) {
      filteredData[key] = data[key];
    }
  });

  let query = '';
  if (filteredData) {
    query = '?' + new URLSearchParams(filteredData);
  }

  let responseResult = "NG";
  let returnValue = null;
  let retryCount = 0;
  let contentDisposition = null;
  while ("OK" !== responseResult && retryCount < 3) {

    let validateTokenResult = validateAccessToken();
    if (TOKEN_NOT_FOUND === validateTokenResult) {
      console.log("トークンがありません");
      break;

    } else if (TOKEN_EXPIRED === validateTokenResult) {
      if (updatingAccessToken) {
        // console.log("トークン更新中.....");
        await sleepByPromise(2);
      } else {
        // console.log("トークン更新");
        await updateAccessToken();
      }
      retryCount++;
      continue;
    }

    let accessToken = getAccessToken();
    const res = await fetch(getAPiUrl(isSp) + url + query, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        "X-AUTH-TOKEN": accessToken,
        'responseType': 'blob',
      }
    });

    if (res.status === 200) {
      //成功時は終了
      returnValue = await res;
      responseResult = "OK";
      contentDisposition = res.headers.get("content-disposition");

    } else {
      //それ以外は処理終了
      break;
    }
    retryCount++;
  }

  const blob = await returnValue.blob()
  const res_url = (window.URL || window.webkitURL).createObjectURL(blob)
  const a = document.createElement('a')
  a.href = res_url;
  let fileName = getDownloadFileName(contentDisposition);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

}

export const downloadAPIByPost = async (url, data = [], isSp = false) => {

  let responseResult = "NG";
  let returnValue = null;
  let retryCount = 0;
  let contentDisposition = null;
  while ("OK" !== responseResult && retryCount < 3) {

    let validateTokenResult = validateAccessToken();
    if (TOKEN_NOT_FOUND === validateTokenResult) {
      console.log("トークンがありません");
      break;

    } else if (TOKEN_EXPIRED === validateTokenResult) {
      if (updatingAccessToken) {
        // console.log("トークン更新中.....");
        await sleepByPromise(2);
      } else {
        // console.log("トークン更新");
        await updateAccessToken();
      }
      retryCount++;
      continue;
    }

    let accessToken = getAccessToken();
    const res = await fetch(getAPiUrl(isSp) + url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        "X-AUTH-TOKEN": accessToken,
        'Content-Type': 'application/json',
        'responseType': 'blob',
      },
      body:JSON.stringify(data)
    });

    if (res.status === 200) {
      //成功時は終了
      returnValue = await res;
      responseResult = "OK";
      contentDisposition = res.headers.get("content-disposition");

    } else {
      //それ以外は処理終了
      break;
    }
    retryCount++;
  }

console.log(returnValue);
  const blob = await returnValue.blob();
console.log(blob);
  const res_url = (window.URL || window.webkitURL).createObjectURL(blob)
  const a = document.createElement('a')
  a.href = res_url;
  let fileName = getDownloadFileName(contentDisposition);
console.log(fileName);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

}

export const downloadImageSpAPI = async (url, data = []) => {
  return downloadImageAPI(url, data, true);
}

export const downloadImageAPI = async (url, isSp = false) => {

  let responseResult = "NG";
  let returnValue = null;
  let retryCount = 0;
  while ("OK" !== responseResult && retryCount < 3) {

    let validateTokenResult = validateAccessToken();
    if (TOKEN_NOT_FOUND === validateTokenResult) {
      console.log("トークンがありません");
      break;

    } else if (TOKEN_EXPIRED === validateTokenResult) {
      if (updatingAccessToken) {
        // console.log("トークン更新中.....");
        await sleepByPromise(2);
      } else {
        // console.log("トークン更新");
        await updateAccessToken();
      }
      retryCount++;
      continue;
    }

    let accessToken = getAccessToken();

    const res = await fetch(getAPiUrl(isSp) + url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        "X-AUTH-TOKEN": accessToken,
        'responseType': 'blob',
      }
    });

    if (res.status === 200) {
      //成功時は終了
      returnValue = await res.blob();
      responseResult = "OK";

    } else {
      //それ以外は処理終了
      break;
    }
    retryCount++;
  }

  return returnValue;
}

import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {Card, Col, Container, Form, FormGroup, InputGroup, Nav, Row} from "react-bootstrap";
import {
  deleteAPI,
  getAPI,
  getAccessToken,
  postAPI,
  postFileUploadAPI,
  putAPI
} from "../APIUtil";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import BookNav from "./BookNav";
import MdDatatable, {sortCaret} from "../MdDataTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faPen, faQrcode} from "@fortawesome/free-solid-svg-icons";
import AsyncSelect from "react-select/async";
import {
  amountFormatter,
  finishLoadingBtn,
  nvl,
  qtyFormatetr,
  startLoadingBtn
} from "../CMUtil";
import {Document, Page, pdfjs} from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
export const QuotDtl = ({match: {params: {p_book_id, p_ver}}}) => {

  const [numPages, setNumPages] = useState([]);
  const [loading, setLoading] = useState(true);

  function onDocumentLoadSuccess(pdf) {
    let locNumPages = [];
    for (let i = 1; i < pdf.numPages; i++) {
      locNumPages.push(i);
    }
    setNumPages(locNumPages);
  }

  const hist = useHistory();

  const iniVer = useRef(true);

  const [updateType, setUpdateType] = useState();
  const [verList, setVerList] = useState([]);

  const [count, setCount] = useState(0);

  const [quotId, setQuotId] = useState();
  const [ver, setVer] = useState();

  const [verSelected, setVerSelected] = useState();

  const [bookId, setBookId] = useState("");
  const [bookNo, setBookNo] = useState("");
  const [bookName, setBookName] = useState("");
  const [constName, setConstName] = useState("");
  const [amountTotal, setAmountTotal] = useState(0);
  const [amountNet, setAmountNet] = useState(0);

  const [mode, setMode] = useState("normal");
  const [tmpNumPages, setTmpNumPages] = useState([]);
  const [tmpUploadFile, setTmpUploadFile] = useState("");
  const [tmpUploadFileNamePdf, setTmpUploadFileNamePdf] = useState("");

  const [quotPage, setQuotPage] = useState(null);
  const [quotSizePerPage, setQuotSizePerPage] = useState(null);
  const [quotTotalSize, setQuotTotalSize] = useState(null);
  const [quotSortField, setQuotSortField] = useState(null);
  const [quotSortOrder, setQuotSortOrder] = useState(null);
  const [quotList, setQuotList] = useState({count: 0});
  const [quotAdditionList, setQuotAdditionList] = useState([]);

  // 項目定義
  const quotColumns = [
    {
      dataField: 'no',
      text: '',
      headerStyle: {width: '45px'},
    },
    {
      dataField: 'opn_detail',
      text: '施工箇所及び施工内容',
      classes: 'tbl-col-break-spaces',
    },
    {
      dataField: 'qty',
      text: '数量',
      classes: 'tbl-col',
      align: 'right',
      headerStyle: {width: '100px', textAlign: "center"},
      formatter: (cell, row) => {
        return qtyFormatetr(cell, row, 1);
      }
    },
    {
      dataField: 'unit',
      text: '単位',
      classes: 'tbl-col',
      align: 'center',
      headerStyle: {width: '100px', textAlign: "center"},
    },
    {
      dataField: 'price',
      text: '単価',
      classes: 'tbl-col',
      align: 'right',
      headerStyle: {width: '100px', textAlign: "center"},
      formatter: amountFormatter,
    },
    {
      dataField: 'amount',
      text: '金額',
      classes: 'tbl-col',
      align: 'right',
      headerStyle: {width: '100px', textAlign: "center"},
      formatter: amountFormatter,
    },
  ];

  const quotAdditionColumns = [
    {
      dataField: 'no',
      text: '',
      headerStyle: {width: '45px'},
      formatter: (cell, row) => {
        return (
          <>
          </>
        );
      }
    },
    {
      dataField: 'opn_detail',
      text: '',
      classes: 'tbl-col-break-spaces',
    },
    {
      dataField: 'qty',
      text: '',
      classes: 'tbl-col',
      align: 'right',
      headerStyle: {width: '100px', textAlign: "center"},
      formatter: (cell, row) => {
        return qtyFormatetr(cell, row, 1);
      }
    },
    {
      dataField: 'unit',
      text: '',
      classes: 'tbl-col',
      align: 'center',
      headerStyle: {width: '100px', textAlign: "center"},
    },
    {
      dataField: 'price',
      text: '',
      classes: 'tbl-col',
      align: 'right',
      headerStyle: {width: '100px', textAlign: "center"},
      formatter: amountFormatter,
    },
    {
      dataField: 'amount',
      text: '',
      classes: 'tbl-col',
      align: 'right',
      headerStyle: {width: '100px', textAlign: "center"},
      formatter: amountFormatter,
    },
  ];

  //初期化
  useEffect(() => {
    const update = async () => {

      let res = null;
      let searchCondition = null;

      searchCondition = {
        book_id: p_book_id
      }
      res = await getAPI("tr_quot_search_view", searchCondition);

      let locVerList = [];
      for (let i = 1; i <= 10; i++) {
        let disp = "";
        res.results.map((quot) => {
          if (quot.ver === i) {
            disp = " 登録済";
          }
        });
        locVerList.push({"value": i, "disp": "Ver. " + i + disp});
      }

      setBookId(p_book_id);
      setVerList(locVerList);
      setVer(p_ver ? p_ver : 1);
      setTmpNumPages([]);
      setTmpUploadFile("");
      setTmpUploadFileNamePdf("");
    }
    update();

  }, []);

  useEffect(() => {

    if (iniVer.current) {
      iniVer.current = false;
      return;
    }

    const update = async () => {

      let obj = await getAPI("tr_book/" + p_book_id);
      if (obj) {
        setBookName(nvl(obj.book_name));
        setBookNo(nvl(obj.book_no));
        setConstName(obj.const ? obj.const.name : "");
      }

      let searchCondition = {
        book_id: bookId,
        ver: ver
      }
      let resQuot = await getAPI("tr_quot_search_view", searchCondition);

      let locQuotId = "";
      let locAmountTotal = 0;
      let locAmountNet = 0;
      let locQuotList = [];
      let locQuotAdditionList = [];
      let locNumPages = [];
      if (resQuot.count === 1) {
        let obj = resQuot.results[0];
        locQuotId = obj.quot_id;
        locAmountTotal = obj.total_total;
        locAmountNet = obj.amount_net;

        let lastRowNo = 1;
        for (let i = 1; i <= 50; i++) {
          let valueExists = false;
          if (obj["opn_detail_" + i]) {
            valueExists = true;
          }
          if (obj["qty_" + i]) {
            valueExists = true;
          }
          if (obj["unit_" + i]) {
            valueExists = true;
          }
          if (obj["price_" + i]) {
            valueExists = true;
          }
          if (obj["amount_" + i]) {
            valueExists = true;
          }
          if (valueExists) {
            lastRowNo = i;
          }
          let row = {
            "no": obj["no_" + i],
            "opn_detail": obj["opn_detail_" + i],
            "qty": obj["qty_" + i],
            "unit": obj["unit_" + i],
            "price": obj["price_" + i],
            "amount": obj["amount_" + i],
          }

          locQuotList.push(row);
        }
        locQuotList.splice(lastRowNo, locQuotList.length - lastRowNo);

        if ("010" === obj.upload_format_type) {
          // 諸経費
          if (obj.expense) {
            locQuotAdditionList.push({
              "no": 0,
              "opn_detail": obj.expense_title ? obj.expense_title : "諸経費",
              "index" : obj.expense_index ? obj.expense_index : 99,
              "qty": 1,
              "unit": "式",
              "price": null,
              "amount": obj.expense,
            });
          }
          // 交通費宿泊費
          if (obj.transport) {
            locQuotAdditionList.push({
              "no": 1,
              "opn_detail": obj.transport_title ? obj.transport_title : "交通費宿泊費",
              "index" : obj.transport_index ? obj.transport_index : 99,
              "qty": 1,
              "unit": "式",
              "price": null,
              "amount": obj.transport,
            });
          }
          // 値引き
          if (obj.discount) {
            locQuotAdditionList.push({
              "no": 2,
              "opn_detail": obj.discount_title ? obj.discount_title : "値引き",
              "index" : obj.discount_index ? obj.discount_index : 99,
              "qty": null,
              "unit": null,
              "price": null,
              "amount": obj.discount,
            })
          }
          // 小　計
          if (obj.sub_total) {
            locQuotAdditionList.push({
              "no": 3,
              "opn_detail": obj.sub_total_title ? obj.sub_total_title : "小　計",
              "index" : obj.sub_total_index ? obj.sub_total_index : 99,
              "qty": null,
              "unit": null,
              "price": null,
              "amount": obj.sub_total,
            });
          }
          // 法定福利費
          if (obj.benefits) {
            locQuotAdditionList.push({
              "no": 4,
              "opn_detail": obj.benefits_title ? obj.benefits_title : "法定福利費",
              "index" : obj.benefits_index ? obj.benefits_index : 99,
              "qty": 1,
              "unit": "式",
              "price": null,
              "amount": obj.benefits,
            });
          }
          // 合計
          locQuotAdditionList.push({
            "no": 5,
            "opn_detail": obj.total_total_title ? obj.total_total_title : "合　計",
              "index" : obj.total_total_index ? obj.total_total_index : 99,
            "qty": null,
            "unit": null,
            "price": null,
            "amount": obj.total_total,
          })

        } else {
          // 諸経費
          if (obj.expense) {
            locQuotAdditionList.push({
              "no": 0,
              "opn_detail": obj.expense_title ? obj.expense_title : "諸経費",
              "index" : obj.expense_index ? obj.expense_index : 99,
              "qty": 1,
              "unit": "式",
              "price": null,
              "amount": obj.expense,
            });
          }
          // 交通費宿泊費
          if (obj.transport) {
            locQuotAdditionList.push({
              "no": 1,
              "opn_detail": obj.transport_title ? obj.transport_title : "交通費宿泊費",
              "index" : obj.transport_index ? obj.transport_index : 99,
              "qty": 1,
              "unit": "式",
              "price": null,
              "amount": obj.transport,
            });
          }
          // 法定福利費
          if (obj.benefits) {
            locQuotAdditionList.push({
              "no": 2,
              "opn_detail": obj.benefits_title ? obj.benefits_title : "法定福利費",
              "index" : obj.benefits_index ? obj.benefits_index : 99,
              "qty": 1,
              "unit": "式",
              "price": null,
              "amount": obj.benefits,
            });
          }
          // 値引き
          if (obj.discount) {
            locQuotAdditionList.push({
              "no": 3,
              "opn_detail": obj.discount_title ? obj.discount_title : "値引き",
              "index" : obj.discount_index ? obj.discount_index : 99,
              "qty": null,
              "unit": null,
              "price": null,
              "amount": obj.discount,
            })
          }
          // 合計
          locQuotAdditionList.push({
            "no": 4,
            "opn_detail": obj.total_total_title ? obj.total_total_title : "合　計",
            "index" : obj.total_total_index ? obj.total_total_index : 99,
            "qty": null,
            "unit": null,
            "price": null,
            "amount": obj.total_total,
          })
        }

        let hasIndex = true;
        for(let locAddition of locQuotAdditionList){
          if(!locAddition.index){
            hasIndex = false;
          }
        }
        if(hasIndex){
          locQuotAdditionList.sort((a,b) => a.index - b.index);
        }

        let res = await getAPI("download_pdf_num_pages/" + bookId + "/" + ver)
        for (let i = 1; i <= res.num_pages; i++) {
          locNumPages.push(i);
        }
      }
      setQuotId(locQuotId);
      setVerSelected({name: ver + "版" + (resQuot.count === 1 ? " （登録済み）" : ""), id: ver});
      setAmountTotal(locAmountTotal);
      setAmountNet(locAmountNet);
      setQuotList({count: locQuotList.length, results: locQuotList});
      setQuotAdditionList({count: locQuotAdditionList.length, results: locQuotAdditionList});
      setNumPages(locNumPages);
      hist.push("/quot/" + obj.book_id + "/" + ver);

      setLoading(false);
    }
    update();
  }, [ver, count]);

  const onSearchVer = async (query) => {
    let searchCondition = {
      book_id: p_book_id
    }
    let res = await getAPI("tr_quot_search_view", searchCondition);
    let locOptions = [];
    for (let i = 1; i <= 5; i++) {
      let suffix = "";
      if (res.results.filter(quot => quot.ver === i).length !== 0) {
        suffix = " （登録済み）";
      }
      locOptions.push({name: i + "版" + suffix, id: i});
    }
    return locOptions;
  }

  const handleChangeVer = (value) => {
    setVerSelected(value);
    setVer(value.id);
  }

  const onClickInsert = async () => {
    startLoadingBtn("btn-insert");

    let obj = {
      book_id: bookId,
      ver: ver,
      tmp_upload_file: tmpUploadFile
    }
    obj = await postAPI("quot_update/", obj);
    setQuotId(obj.quot_id);
    setMode("normal");
    setCount(count + 1);

    finishLoadingBtn("btn-insert");
    success("登録完了");
  }

  const onClickUpdate = async () => {
    startLoadingBtn("btn-update");

    let obj = {
      quot_id: quotId,
      tmp_upload_file: tmpUploadFile
    }
    obj = await putAPI("quot_update/" + quotId + "/", obj);
    setMode("normal");
    setCount(count + 1);

    finishLoadingBtn("btn-update");
    success("登録完了");
  }

  const onUploadQuot = async () => {
    startLoadingBtn("btn-upload-quot");

    const formData = new FormData();
    formData.append(
      "upload_file",
      event.target.files[0],
      event.target.files[0].name
    );
    formData.append("book_id", bookId);

    let res = await postFileUploadAPI("quot_upload/", formData);
    let locTmpUploadFile = res.upload_file;
    let locTmpUploadFileNamePdf = res.upload_file_pdf;
    let locQuotList = res.list;
    let locQuotAdditionList = res.addition_list;
    let uploadFormatType = res.upload_format_type;
    let locNumPages = [];
    res = await getAPI("download_pdf_num_pages_tmp/" + locTmpUploadFileNamePdf);
    for (let i = 1; i <= res.num_pages; i++) {
      locNumPages.push(i);
    }

    setTmpUploadFile(locTmpUploadFile);
    setTmpUploadFileNamePdf(locTmpUploadFileNamePdf);
    setTmpNumPages(locNumPages);
    setQuotList({count: locQuotList.length, results: locQuotList});
    let locQuotAdditionNoSubTotalList = [];
    for (let i = 0; i < locQuotAdditionList.length; i++) {
      if (locQuotAdditionList[i]['opn_detail'] === "小　計" && uploadFormatType !== "010") {
        continue;
      }
      locQuotAdditionNoSubTotalList.push(locQuotAdditionList[i]);
    }
    setQuotAdditionList({count: locQuotAdditionNoSubTotalList.length, results: locQuotAdditionNoSubTotalList});
    setMode("preview");

    // 合計金額計算
    let locAmountTotal = 0;
    // locQuotList.forEach((quot) => {
    //   if (!isNaN(quot.amount)) {
    //     locAmountTotal += quot.amount;
    //   }
    // });
    setAmountTotal(locAmountTotal);
    setAmountNet(Math.ceil(locAmountTotal * 0.9 * 0.0001) * 10000); //千の位切り上げ

    $("#uploadFileChooser").val("");

    finishLoadingBtn("btn-upload-quot");
  }

  const onClickDelete = async () => {
    let res = await deleteAPI("tr_quot/" + quotId + "/", {});
    if (res) {
      success("削除完了");
      hist.push("/book/" + bookId + "/1");
    }
  }

  return (
    <>
      <div className="body-dtl">
        <AppNav/>
        <BookNav bookId={bookId} ver={ver} quotId={quotId}/>

        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                お見積り
              </h3>
            </Col>
            <Col>
              <div className="form-inline float-right">
                <AsyncSelect
                  className="async-select async-select-ver"
                  cacheOptions
                  defaultOptions
                  value={verSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchVer}
                  onChange={handleChangeVer}
                  placeholder=""
                />&nbsp;
                {!quotId && <Button id="btn-insert" variant="primary" onClick={onClickInsert} disabled={!tmpUploadFile}><span><FontAwesomeIcon
                  icon={faPen} size="lg"/>登録</span></Button>}
                {quotId && <Button id="btn-update" variant="primary" onClick={onClickUpdate}
                                   disabled={!tmpUploadFile}><span><FontAwesomeIcon icon={faPen}
                                                                                    size="lg"/>登録</span></Button>}
              </div>
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">

            <Form.Group as={Row}>
              <Form.Label column sm="1">工事番号</Form.Label>
              <Col sm="4">
                <Form.Control plaintext readOnly value={bookNo}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">現場名</Form.Label>
              <Col sm="4">
                <Form.Control plaintext readOnly value={bookName}/>
              </Col>
              {/*{amountTotal > 0 &&*/}
              {/*<>*/}
              {/*  <Form.Label column sm="1">合計金額</Form.Label>*/}
              {/*  <Col sm="2" className="input-group">*/}
              {/*    <InputGroup.Text className="input-group-text-cm" style={{fontWeight: "700"}}>￥</InputGroup.Text>*/}
              {/*    <Form.Control plaintext readOnly className="text-right" style={{maxWidth: '100px'}} value={formatNumber(amountTotal)}/>*/}
              {/*  </Col>*/}
              {/*</>*/}
              {/*}*/}
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">施工業者</Form.Label>
              <Col sm="4">
                <Form.Control plaintext readOnly value={constName}/>
              </Col>
              {/*{amountNet > 0 &&*/}
              {/*<>*/}
              {/*  <Form.Label column sm="1">ＮＥＴ金額</Form.Label>*/}
              {/*  <Col sm="2" className="input-group">*/}
              {/*    <InputGroup.Text className="input-group-text-cm" style={{fontWeight: "700"}}>￥</InputGroup.Text>*/}
              {/*    <Form.Control plaintext readOnly className="text-right" style={{maxWidth: '100px'}} value={formatNumber(amountNet)}/>*/}
              {/*  </Col>*/}
              {/*</>*/}
              {/*}*/}
            </Form.Group>
            {/* *** 一覧 *** */}
            <Container className="container-main-list" style={{display: quotList.count ? "block" : "none"}}>
              <MdDatatable
                keyField="no"
                listData={quotList}
                columns={quotColumns}
                loading={loading}
                page={quotPage}
                sizePerPage={quotSizePerPage}
                totalSize={quotTotalSize}
                sortField={quotSortField}
                sortOrder={quotSortOrder}
              />
              <MdDatatable
                keyField="no"
                listData={quotAdditionList}
                columns={quotAdditionColumns}
                loading={loading}
                page={quotPage}
                sizePerPage={quotSizePerPage}
                totalSize={quotTotalSize}
                sortField={quotSortField}
                sortOrder={quotSortOrder}
              />
            </Container>

            <Container className="container-main-list">
              <Row>
                <Col>
                  {quotId && <Button variant="danger" onClick={onClickDelete}>削除</Button>}
                </Col>
                <Col className="text-right">
                  <Form id="uploadForm" action="">
                    <label className="upload-label">
                      <div id="btn-upload-quot" className="btn btn-primary" style={{width: "200px"}}>
                        <span>お見積りアップロード</span>
                      </div>
                      <Form.File
                        id="uploadFileChooser"
                        className="custom-file-input"
                        accept=".xlsx"
                        style={{display: "none"}}
                        onChange={onUploadQuot}/>
                    </label>
                  </Form>
                </Col>
              </Row>
            </Container>
          </Card>

          <div>
            <Card className="card-thirdly mx-auto">
              {/* 通常モード */}
              {mode === "normal" &&
                <div>
                  {quotId &&
                    <div className="pdf-viewer">
                      <Document file={{
                        url: '/api/download/' + bookId + '/' + ver,
                        httpHeaders: {'X-AUTH-TOKEN': getAccessToken()}
                      }}>
                        {
                          numPages.map((page, idx) => {
                            return <Page key={page} pageNumber={page}/>
                          })
                        }
                      </Document>
                    </div>
                  }
                </div>
              }
              {/* プレビューモード */}
              {mode === "preview" &&
                <div>
                  {tmpUploadFileNamePdf &&
                    <div className="pdf-viewer">
                      <div
                        style={{textAlign: "center", color: "blue", fontSize: "2.5rem", paddingTop: "1.5rem"}}>プレビュー
                      </div>
                      <div style={{textAlign: "center", color: "blue", fontSize: "1.2rem"}}>まだ登録完了していません</div>
                      <Document file={{
                        url: '/api/download/tmp_pdf/' + tmpUploadFileNamePdf,
                        httpHeaders: {'X-AUTH-TOKEN': getAccessToken()}
                      }}>
                        {
                          tmpNumPages.map((page, idx) => {
                            return <Page key={page} pageNumber={page}/>
                          })
                        }
                      </Document>
                    </div>
                  }

                </div>
              }
            </Card>
          </div>

        </Container>
      </div>
      <Toast/>
    </>
  );
}

export default withRouter(QuotDtl);